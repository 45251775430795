/**

Template Name: GURU Able Admin Template
Author: #
Email: #
File: style.css
**/
/* =============
  == Table of Contents==

	- Custom Css
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Icons, Accordion, Button, Redial, floating actions, FAB, tabs, typography, buttons, box shadow, slider page, error page, Breadcrumb, pagination, Basic card, Login card
	- General Elements
		-	Modal, Nestable, Lable & Badges, List, Portlates, Preloader, Calender, To-Do, Materialize general elements, Lables
	- Advance Form Elements
	- Pages
		-	Loader page, Invoice, Tasklist, Task detail, Range-slider, Counter, Maintenance, Color, FAQ, Progress, Panels & wells, Contact card, Auth pages, Flag
		-	Gallery, Products pages, Widget, Email, Social timeline, Datatable, Chat, Blog, Wizard, CRM dashboard, Task board, Issue list, Chartlist, X-editable
		-	Home chat, Dashboard 1-2-3-4
	- Responsive media class

============= */
/**  =====================
      Custom css start
==========================  **/
body {
  background-color: #f3f3f3;
  font-size: 0.875em;
  overflow-x: hidden;
  color: #455a64;
  font-family: "Roboto", sans-serif;
  background-attachment: fixed;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2) px, rgba(255, 255, 255, 0.9) 18.6%); 
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0; }

*:focus {
  outline: none; }

a {
  font-size: 13px;
  color: #37474f;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media only screen and (min-width: 1400px) {
    a {
      font-size: 14px; } }
  a:focus, a:hover {
    text-decoration: none;
    color: #448aff; }

h4 {
  font-size: 1.375rem; }

h6 {
  font-size: 14px; }

p {
  font-size: 13px; }

b, strong {
  font-weight: 500; }

.media-left {
  padding-right: 20px; }

/**====== Custom css end ======**/
/**  =====================
      Main-body css start
==========================  **/
.main-body .page-wrapper {
  padding: 1.8rem;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }

/**====== Main-body css end ======**/
/**  =====================
      Card-Layout css start
==========================  **/
/*====== Ecommerce Dashboard Css Start ======*/
.author-details {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 10px 0;
  margin-bottom: 10px; }
  .author-details img ~ .dot1,
  .author-details img ~ .dot2 {
    height: 14px;
    width: 14px;
    border-radius: 100%;
    top: 60%;
    position: absolute;
    padding: 5px; }
  .author-details img ~ .dot1 {
    left: 40px; }
  .author-details img ~ .dot2 {
    left: 50px; }
  .author-details .recent-contain h6 {
    font-size: 1rem;
    margin-bottom: 5px; }
  .author-details .header-right {
    text-align: right;
    margin-top: 6px; }
    .author-details .header-right ul {
      position: relative; }
    .author-details .header-right h4 {
      color: #919aa3;
      font-size: 0.875rem;
      margin-top: 5px; }
    .author-details .header-right span {
      font-size: 1rem; }
    .author-details .header-right li {
      display: inline-block;
      margin-right: 60px;
      text-align: left; }
      .author-details .header-right li:last-child {
        margin-right: 0;
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 20px; }

/*====== Ecommerce Dashboard Css End ======*/
.login .container-fluid {
  width: auto;
  margin-top: 80px; }

.filter-bar .navbar .navbar-nav .dropdown-menu {
  position: absolute; }

/*====== Ready to use Css Start ======*/
.wrapper {
  padding: 0; }

/*====== Ready to use Css End ======*/
.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
          box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .card .card-footer {
    border-top: none;
    padding: 15px 20px;
    border-radius: 0 0 5px 5px; }
  .card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0px 1px 20px 0px rgba(69, 90, 100, 0.08);
            box-shadow: 0px 1px 20px 0px rgba(69, 90, 100, 0.08); }
    .card .card-header .card-header-left {
      display: inline-block; }
    .card .card-header .card-header-right {
      right: 10px;
      top: 13px;
      display: inline-block;
      float: right;
      padding: 7px 0;
      position: absolute; }
      @media only screen and (max-width: 575px) {
        .card .card-header .card-header-right {
          display: none; } }
      .card .card-header .card-header-right i {
        margin: 0 7px;
        cursor: pointer;
        font-size: 12px;
        color: #8c8c8c;
        line-height: 2; }
        .card .card-header .card-header-right i.icofont.icofont-spinner-alt-5 {
          display: none; }
      .card .card-header .card-header-right .card-option {
        width: 35px;
        height: 20px;
        overflow: hidden;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out; }
        .card .card-header .card-header-right .card-option li {
          display: inline-block; }
    .card .card-header span {
      display: block;
      font-size: 13px;
      margin-top: 5px; }
    .card .card-header h5 {
      margin-bottom: 0;
      color: #37474f;
      font-size: 15px;
      font-weight: 500;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.4;
      position: relative; }
      .card .card-header h5:after {
        content: "";
        background-color: #448aff;
        position: absolute;
        left: -20px;
        top: 0;
        width: 4px;
        height: 20px; }
  .card .card-block {
    padding: 20px; }
    .card .card-block table tr {
      padding-bottom: 20px; }
    .card .card-block .sub-title {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px; }
    .card .card-block code {
      background-color: #eee;
      margin: 5px;
      display: inline-block; }
    .card .card-block .dropdown-menu {
      top: 38px; }
    .card .card-block p {
      line-height: 1.4; }
    .card .card-block a.dropdown-item {
      margin-bottom: 0;
      font-size: 14px;
      -webkit-transition: 0.25s;
      transition: 0.25s; }
      .card .card-block a.dropdown-item:active,
      .card .card-block a.dropdown-item .active {
        background-color: #448aff; }
    .card .card-block.remove-label i {
      margin: 0;
      padding: 0; }
    .card .card-block.button-list span.badge {
      margin-left: 5px; }
    .card .card-block .dropdown-menu {
      background-color: #fff;
      padding: 0; }
      .card .card-block .dropdown-menu .dropdown-divider {
        background-color: #ddd;
        margin: 3px 0; }
    .card .card-block .dropdown-menu > a {
      padding: 10px 16px;
      line-height: 1.429; }
    .card .card-block .dropdown-menu > li > a:focus,
    .card .card-block .dropdown-menu > li > a:hover {
      background-color: rgba(202, 206, 209, 0.5); }
    .card .card-block .dropdown-menu > li:first-child > a:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px; }
    .card .card-block .badge-box {
      padding: 10px;
      margin: 12px 0; }
  .card .card-block-big {
    padding: 35px; }
  .card .card-block-small {
    padding: 15px; }

.card-footer {
  background: transparent; }

.pcoded .card.full-card {
  position: fixed;
  top: calc(56px + 15px);
  z-index: 99999;
  -webkit-box-shadow: 0 0 35px 0px rgba(0, 0, 0, 0.44);
          box-shadow: 0 0 35px 0px rgba(0, 0, 0, 0.44);
  left: 15px;
  border-radius: 0;
  border: 1px solid #ddd;
  width: calc(100vw - 50px);
  height: calc(100vh - 86px); }
  .pcoded .card.full-card.card-load {
    position: fixed; }
.pcoded .card.card-load {
  position: relative;
  overflow: hidden; }
  .pcoded .card.card-load .card-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999; }
    .pcoded .card.card-load .card-loader i {
      margin: 0 auto;
      color: #ab7967;
      font-size: 20px; }

.card-header-text {
  margin-bottom: 0;
  font-size: 1rem;
  color: rgba(51, 51, 51, 0.85);
  font-weight: 500;
  display: inline-block;
  vertical-align: middle; }

.icofont-rounded-down {
  -webkit-transition: all ease-in 0.3s;
  display: inline-block;
  transition: all ease-in 0.3s; }

.icon-up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block; }

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.page-header {
  background-size: cover;
  position: relative;
  border-radius: 0;
  color: #fff; }
  .page-header:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .page-header .page-block {
    padding: 35px 40px; }
    .page-header .page-block .breadcrumb-title {
      float: right; }
      .page-header .page-block .breadcrumb-title a {
        font-size: 14px;
        color: #fff; }
      .page-header .page-block .breadcrumb-title .breadcrumb-item + .breadcrumb-item::before {
        content: "\f105";
        font-family: FontAwesome;
        padding-right: 5px;
        font-size: 12px;
        color: #fff; }
  @media only screen and (max-width: 768px) {
    .page-header .page-block .breadcrumb-title {
      float: left;
      margin-top: 10px; } }

.sub-title {
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #2c3e50; }

/**======  Card-Layout css end ======**/
/*======= Typography css start ========*/
.blockquote {
  border-left: 0.25rem solid #eceeef;
  padding: 0.5rem 1rem; }
  .blockquote.blockquote-reverse {
    text-align: right;
    padding-right: 1rem;
    border-right: 0.25rem solid #eceeef;
    border-left: none; }

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  margin: 0 0 20px; }
.typography small {
  margin-left: 10px;
  font-weight: 500;
  color: #777; }

.card-block.list-tag ul li {
  display: block;
  float: none;
  margin-bottom: 5px; }
.card-block.list-tag ol li {
  margin-bottom: 5px; }

.inline-order-list {
  margin-top: 50px; }
  .inline-order-list h4,
  .inline-order-list p {
    margin-bottom: 0; }

.card-block ul li.list-inline-item {
  display: inline-block;
  float: left; }

/*======= Typography css end ========*/
/**  =====================
      Modal css start
==========================  **/
.modal {
  z-index: 99999999999; }

.bd-example .modal {
  display: block;
  position: inherit;
  background-color: #2C3E50;
  margin-bottom: 20px; }

.card .overflow-container h5 {
  margin-bottom: 5px; }

.button-page .card-block a.nav-link {
  margin-bottom: 0; }

.sweet-alert button.confirm {
  background-color: #448aff !important; }

.sweet-alert .sa-input-error {
  top: 23px; }

/**====== Modal css end ======**/
/**  =====================
      Notification css start
==========================  **/
.location-selector {
  width: 100%;
  height: 250px;
  background-color: #fff;
  border: 2px dashed #e5e9ec;
  position: relative;
  margin-bottom: 20px; }
  .location-selector .bit {
    background-color: #e5e9ec;
    cursor: pointer;
    position: absolute; }
    .location-selector .bit:hover {
      background-color: #ddd; }
    .location-selector .bit.bottom, .location-selector .bit.top {
      height: 25%;
      width: 40%;
      margin: 0 30%; }
    .location-selector .bit.top {
      top: 0; }
    .location-selector .bit.bottom {
      bottom: 0; }
    .location-selector .bit.left, .location-selector .bit.right {
      height: 20%;
      width: 20%;
      margin-left: 0;
      margin-right: 0; }
    .location-selector .bit.right {
      right: 0; }
    .location-selector .bit.left {
      left: 0; }

button.close {
  margin-top: 7px;
  margin-bottom: 0; }

/**====== Notification css end ======**/
/**  =====================
      Tooltip css start
==========================  **/
.mytooltip {
  display: inline;
  position: relative;
  z-index: 999; }
  .mytooltip .tooltip-item {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    padding: 0 10px; }
  .mytooltip .tooltip-content {
    position: absolute;
    z-index: 9999;
    width: 360px;
    left: 50%;
    margin: 0 0 20px -180px;
    bottom: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 30px;
    -webkit-box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
            box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
    background: #2b2b2b;
    opacity: 0;
    cursor: default;
    pointer-events: none; }
    .mytooltip .tooltip-content::after {
      content: '';
      top: 100%;
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: #2a3035 transparent transparent;
      border-width: 10px;
      margin-left: -10px; }
    .mytooltip .tooltip-content img {
      position: relative;
      height: 140px;
      display: block;
      float: left;
      margin-right: 1em; }
  .mytooltip .tooltip-item::after {
    content: '';
    position: absolute;
    width: 360px;
    height: 20px;
    bottom: 100%;
    left: 50%;
    pointer-events: none;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .mytooltip:hover .tooltip-item::after {
    pointer-events: auto; }
  .mytooltip:hover .tooltip-content {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
            transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg); }
  .mytooltip:hover .tooltip-content2 {
    opacity: 1;
    font-size: 18px; }
    .mytooltip:hover .tooltip-content2 i {
      opacity: 1;
      font-size: 18px; }
  .mytooltip:hover .tooltip-content2 {
    opacity: 1;
    font-size: 18px;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1); }
    .mytooltip:hover .tooltip-content2 i {
      opacity: 1;
      font-size: 18px;
      pointer-events: auto;
      -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1); }
  .mytooltip:hover .tooltip-item2 {
    color: #fff;
    -webkit-transform: translate3d(0, -0.9em, 0);
    transform: translate3d(0, -0.9em, 0); }
  .mytooltip:hover .tooltip-text3 {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  .mytooltip:hover .tooltip-content3 {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  .mytooltip:hover .tooltip-content4 {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .mytooltip:hover .tooltip-text2 {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .mytooltip:hover .tooltip-inner2 {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .mytooltip:hover .tooltip-content5 {
    opacity: 1;
    pointer-events: auto;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mytooltip .tooltip-text {
    font-size: 14px;
    line-height: 24px;
    display: block;
    padding: 1.31em 1.21em 1.21em 0;
    color: #fff; }
  .mytooltip .tooltip-item2 {
    color: #448aff;
    cursor: pointer;
    z-index: 100;
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    -webkit-transition: background-color 0.3s, color 0.3s, -webkit-transform 0.3s;
    transition: background-color 0.3s, color 0.3s, -webkit-transform 0.3s;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    transition: background-color 0.3s, color 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip.tooltip-effect-2:hover .tooltip-content {
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg); }

.tooltip-effect-5 .tooltip-text {
  padding: 1.4em; }

.tooltip-effect-1 .tooltip-content {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  color: #fff; }

.tooltip-effect-2 .tooltip-content {
  -webkit-transform-origin: 50% calc(110%);
  transform-origin: 50% calc(110%);
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s; }

.tooltip-effect-3 .tooltip-content {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-effect-4 .tooltip-content {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scale3d(0.7, 0.3, 1);
  transform: scale3d(0.7, 0.3, 1);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s; }

.tooltip-effect-5 .tooltip-content {
  width: 180px;
  margin-left: -90px;
  -webkit-transform-origin: 50% calc(106%);
  transform-origin: 50% calc(106%);
  -webkit-transform: rotate3d(0, 0, 1, 15deg);
  transform: rotate3d(0, 0, 1, 15deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39); }

.tooltip-effect-6 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }
  .tooltip-effect-6 .tooltip-content2 i {
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-effect-7 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }
  .tooltip-effect-7 .tooltip-content2 i {
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-effect-8 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }
  .tooltip-effect-8 .tooltip-content2 i {
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-effect-9 .tooltip-content2 {
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }
  .tooltip-effect-9 .tooltip-content2 i {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-effect-6:hover .tooltip-content2 i {
  -webkit-transform: rotate3d(1, 1, 1, 0deg);
          transform: rotate3d(1, 1, 1, 0deg); }

.tooltip-content2 {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  padding-top: 25px;
  left: 50%;
  margin-left: -40px;
  bottom: 100%;
  border-radius: 50%;
  text-align: center;
  background: #448aff;
  color: #fff;
  opacity: 0;
  margin-bottom: 20px;
  cursor: default;
  pointer-events: none; }
  .tooltip-content2 i {
    opacity: 0; }
  .tooltip-content2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin: -7px 0 0 -15px;
    width: 30px;
    height: 20px;
    background:  center center no-repeat;
    background-size: 100%; }

.tooltip-content3 {
  position: absolute;
  background: center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 9999;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  padding: 50px 30px;
  text-align: center;
  color: #fff;
  opacity: 0;
  cursor: default;
  font-size: 14px;
  line-height: 27px;
  pointer-events: none;
  -webkit-transform: scale3d(0.1, 0.2, 1);
  transform: scale3d(0.1, 0.2, 1);
  -webkit-transform-origin: 50% 120%;
  transform-origin: 50% 120%;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  transition: opacity 0.4s, transform 0.4s, -webkit-transform 0.4s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
  transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1); }
  .tooltip-content3::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    margin-left: -8px;
    top: 100%;
    background: #00AEEF;
    -webkit-transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg); }

.tooltip-content4 {
  position: absolute;
  z-index: 99;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  bottom: -5px;
  text-align: left;
  background: #448aff;
  opacity: 0;
  font-size: 14px;
  line-height: 27px;
  padding: 1.5em;
  color: #fff;
  border-bottom: 55px solid #0053dd;
  cursor: default;
  pointer-events: none;
  border-radius: 5px;
  -webkit-transform: translate3d(0, -0.5em, 0);
  transform: translate3d(0, -0.5em, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }
  .tooltip-content4 a {
    color: #2b2b2b; }
  .tooltip-content4 .tooltip-text2 {
    opacity: 0;
    -webkit-transform: translate3d(0, 1.5em, 0);
    transform: translate3d(0, 1.5em, 0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.tooltip-content5 {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  color: #fff;
  background: 0 0;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s; }
  .tooltip-content5 span {
    display: block; }
  .tooltip-content5::after {
    content: '';
    bottom: -20px;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #448aff transparent transparent;
    border-width: 10px;
    margin-left: -10px; }
  .tooltip-content5 .tooltip-text3 {
    border-bottom: 10px solid #448aff;
    overflow: hidden;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s; }
  .tooltip-content5 .tooltip-inner2 {
    background: #2b2b2b;
    padding: 40px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    webkit-transition: -webkit-transform 0.3s;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }

a.mytooltip {
  font-weight: 700;
  color: #448aff;
  z-index: 9; }

.tooltip-link a {
  margin-left: 10px;
  color: #448aff; }

.tooltip-icon button i {
  margin-right: 0; }

/**====== Tooltip css end ======**/
/**  =====================
      Slider css start
==========================  **/
/*========= Owl-carousel css start ============*/
.owl-theme .owl-nav [class*='owl-']:hover {
  background: #448aff; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #448aff; }

/*========= Owl-carousel css end ============*/
/**  =====================
      Animation css start
==========================  **/
.animation-image img {
  margin: 10px auto 20px;
  cursor: pointer; }

/**====== Animation css end ======**/
/**  =====================
      Tree-view css start
==========================  **/
.card-block.tree-view ul {
  display: inherit; }
  .card-block.tree-view ul li {
    display: inherit;
    float: none; }

/* .jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("../pages/treeview/throbber.gif") center center no-repeat; } */

/**====== Tree-view css end ======**/
/**  =====================
      File Upload css start
==========================  **/
.jFiler-theme-default .jFiler-input-button {
  background-color: #448aff;
  background-image: none;
  color: #fff; }
.jFiler-theme-default .jFiler-input {
  width: 100%; }

.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa; }

.jFiler-items-grid .jFiler-item .jFiler-item-container .jFiler-item-info {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 6px 10px;
  z-index: 9;
  opacity: 0;
  -webkit-transition: all 0.12s;
  transition: all 0.12s; }

.jFiler-input-choose-btn {
  border: 1px solid #448aff;
  padding: 10px 25px;
  background: #448aff;
  color: #fff !important; }
  .jFiler-input-choose-btn:hover {
    background: #fff;
    border: 1px solid #448aff;
    color: #fff !important; }

.card .jFiler-item-assets ul li {
  margin-right: 0; }

/**====== File Upload css end ======**/
/**  =====================
      Cropper css start
==========================  **/
.img-container,
.img-preview {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center; }

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px; }

@media (min-width: 768px) {
  .img-container {
    min-height: 516px; } }
.img-container > img {
  max-width: 100%; }

.docs-preview {
  margin-right: -15px; }

.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden; }
  .img-preview > img {
    max-width: 100%; }

.preview-lg {
  width: 100% !important; }

.preview-md {
  width: 139px;
  height: 78px; }

.preview-sm {
  width: 69px;
  height: 39px; }

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0; }

.docs-data > .input-group {
  margin-bottom: 10px; }
  .docs-data > .input-group > label {
    min-width: 80px; }
  .docs-data > .input-group > span {
    min-width: 50px; }

.docs-buttons > .btn,
.docs-buttons > .form-control,
.docs-buttons > .btn-group {
  margin-right: 5px; }

.docs-toggles > .btn,
.docs-toggles > .dropdown,
.docs-toggles > .btn-group {
  margin-bottom: 10px; }

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px; }
  .docs-tooltip > .icon {
    margin: 0 -3px;
    vertical-align: top; }

.tooltip-inner {
  white-space: normal; }

.btn-upload .tooltip-inner {
  white-space: nowrap; }

@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important; }
    .btn-group-crop > .btn {
      padding-left: 5px;
      padding-right: 5px; }
    .btn-group-crop .docs-tooltip {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 5px; } }
.docs-options .dropdown-menu {
  width: 100%; }
  .docs-options .dropdown-menu > li {
    padding: 3px 20px;
    font-size: 14px; }
    .docs-options .dropdown-menu > li:hover {
      background-color: #f7f7f7; }
    .docs-options .dropdown-menu > li > label {
      display: block; }

.docs-cropped .modal-body {
  text-align: center; }
  .docs-cropped .modal-body > img,
  .docs-cropped .modal-body > canvas {
    max-width: 100%; }

.card-block .docs-options .dropdown-menu {
  top: inherit; }

label.btn-upload {
  height: 40px; }

/**====== Cropper css end ======**/
/**  =====================
      Accordion css start
==========================  **/
.accordion-msg {
  display: block;
  color: #222222;
  padding: 14px 20px;
  border-top: 1px solid #ddd;
  font-weight: 500;
  cursor: pointer; }

.accordion-msg:focus,
.accordion-msg:hover {
  text-decoration: none;
  outline: none; }

.faq-accordion .accordion-desc {
  padding: 20px; }

.accordion-desc {
  color: #222222;
  padding: 0 20px 20px; }

#color-accordion .accordion-desc {
  margin-top: 14px; }

.ui-accordion-header-icon {
  float: right;
  font-size: 20px; }

.accordion-title {
  margin-bottom: 0; }

.accordion-block {
  padding: 0; }
  .accordion-block p {
    margin-bottom: 0; }

.color-accordion-block a.ui-state-active, .color-accordion-block a:focus, .color-accordion-block a:hover {
  color: #fff;
  background: #4680ff; }

a.bg-default:focus,
a.bg-default:hover {
  background-color: #f0f0f0 !important;
  color: #fff; }

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #77aaff !important;
  color: #fff; }

a.bg-success:focus,
a.bg-success:hover {
  background-color: #1aeb72 !important;
  color: #fff; }

a.bg-info:focus,
a.bg-info:hover {
  background-color: #08e3ff !important;
  color: #fff; }

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #ffe733 !important;
  color: #fff; }

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #ff8585 !important;
  color: #fff; }

/**====== Accordion css end ======**/
/**  =====================
      Tabs css start
==========================  **/
.tab-list p {
  padding: 10px; }

.tab-with-img i {
  position: absolute;
  padding: 5px; }

.tab-icon {
  margin-bottom: 30px; }
  .tab-icon i {
    padding-right: 10px; }

.tab-below {
  border-top: 1px solid #ddd;
  border-bottom: none; }
  .tab-below.nav-tabs .nav-link.active {
    border-color: transparent #ddd #ddd #ddd; }
  .tab-below .nav-item {
    margin-top: -2px; }
  .tab-below.nav-tabs .nav-link {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0; }

.card-header ~ .tab-icon .tab-with-img .sub-title i {
  right: 10px; }

.tab-with-img .nav-link {
  position: relative; }

.tabs-left,
.tabs-right {
  min-width: 120px;
  vertical-align: top;
  width: 150px; }

.tabs-left,
.tabs-left-content,
.tabs-right,
.tabs-right-content {
  display: table-cell; }

.nav-tabs.tabs-left .slide {
  height: 35px;
  width: 4px;
  bottom: 15px; }

.nav-tabs.tabs-right .slide {
  height: 35px;
  width: 4px;
  bottom: 15px;
  right: 0; }

.product-edit .md-tabs .nav-item a {
  padding: 0 0 10px !important;
  color: #37474f; }
  .product-edit .md-tabs .nav-item a .f-20 {
    display: inline-block;
    margin-right: 10px; }

.md-tabs.tabs-left .nav-item,
.md-tabs.tabs-right .nav-item,
.tabs-left .nav-item,
.tabs-right .nav-item {
  width: 100%;
  position: relative; }

.md-tabs {
  position: relative; }
  .md-tabs .nav-item + .nav-item {
    margin: 0; }
  .md-tabs .nav-link {
    border: none;
    color: #37474f; }
  .md-tabs .nav-item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: center;
    position: relative; }
  .md-tabs .nav-link:focus,
  .md-tabs .nav-link:hover {
    border: none; }
  .md-tabs .nav-item .nav-link.active ~ .slide {
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .md-tabs .nav-item .nav-link ~ .slide {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .md-tabs .nav-item.open .nav-link,
  .md-tabs .nav-item.open .nav-link:focus,
  .md-tabs .nav-item.open .nav-link:hover,
  .md-tabs .nav-link.active,
  .md-tabs .nav-link.active:focus,
  .md-tabs .nav-link.active:hover {
    color: #448aff;
    border: none;
    background-color: transparent;
    border-radius: 0; }
  .md-tabs .nav-item a {
    padding: 20px 0 !important;
    color: #37474f; }

.nav-tabs .slide {
  background: #448aff;
  width: 100%;
  height: 4px;
  position: absolute;
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
  bottom: 0; }
  .nav-tabs .slide .nav-item.show .nav-link,
  .nav-tabs .slide .nav-link {
    color: #448aff; }

.img-tabs img {
  width: 100px;
  margin: 0 auto; }
.img-tabs a {
  opacity: 0.5;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s; }
  .img-tabs a span i {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    bottom: 10px;
    right: 70px; }
  .img-tabs a img {
    border: 3px solid; }
  .img-tabs a.active {
    opacity: 1;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s; }
.img-tabs .nav-item:first-child {
  border-bottom: none; }

#pc-left-panel-menu {
  margin-bottom: 20px; }

.h-active a {
  color: #1b8bf9 !important;
  font-weight: 500; }

.img-circle {
  border-radius: 50%; }

.b-none {
  border: none !important; }

/**====== Tabs css end ======**/
/**  =====================
      Table css start
==========================  **/
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #4680FE; }

.table-responsive {
  display: inline-block;
  width: 100%;
  overflow-x: auto; }

.table.table-xl td,
.table.table-xl th {
  padding: 1.25rem 2rem; }

.table.table-lg td,
.table.table-lg th {
  padding: 0.9rem 2rem; }

.table.table-de td,
.table.table-de th {
  padding: 0.75rem 2rem; }

.table.table-sm td,
.table.table-sm th {
  padding: 0.6rem 2rem; }

.table.table-xs td,
.table.table-xs th {
  padding: 0.4rem 2rem; }

.table-columned > tbody > tr > td:first-child,
.table-columned > tbody > tr > th:first-child {
  border-left: 0; }
.table-columned > tfoot > tr > td:first-child,
.table-columned > tfoot > tr > th:first-child {
  border-left: 0; }
.table-columned > tbody > tr > td,
.table-columned > tbody > tr > th {
  border: 0;
  border-left: 1px solid #ddd; }
.table-columned > tfoot > tr > td,
.table-columned > tfoot > tr > th {
  border: 0;
  border-left: 1px solid #ddd; }

.table-border-style {
  padding: 0; }
  .table-border-style .table {
    margin-bottom: 0; }

.table > thead > tr > th {
  border-bottom-color: #ccc; }

.table-borderless tbody tr td,
.table-borderless tbody tr th {
  border: 0; }

.table-bordered > thead > tr.border-solid > td,
.table-bordered > thead > tr.border-solid > th {
  border-bottom-width: 2px; }
.table-bordered > thead > tr.border-solid:first-child > td,
.table-bordered > thead > tr.border-solid:first-child th {
  border-bottom-width: 2px; }

.table-bordered > thead > tr.border-double > td,
.table-bordered > thead > tr.border-double > th {
  border-bottom-width: 3px;
  border-bottom-style: double; }
.table-bordered > thead > tr.border-double:first-child > td,
.table-bordered > thead > tr.border-double:first-child th {
  border-bottom-width: 3px;
  border-bottom-style: double; }

/**====== Table css end ======**/
/**  =====================
      User-Profile css start
==========================  **/
.rounded-card img,
.user-img img {
  margin: 0 auto;
  display: block;
  width: 100%; }

.user-img img {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.user-about ul li {
  border-top: 1px solid rgba(204, 204, 204, 0.28);
  padding: 10px 0 10px 10px;
  text-align: left; }
  .user-about ul li a {
    font-size: 16px;
    color: #666; }
  .user-about ul li.active a {
    color: #fff; }
  .user-about ul li.active {
    font-weight: 500;
    background: #1b8bf9; }

.card-title,
h5 {
  margin: 0; }

.card-header.followers a {
  font-weight: 500;
  color: rgba(51, 51, 51, 0.85); }

.user-profile {
  padding: 20px 0; }

.follow-btn button:first-child,
.user-about i {
  margin-right: 10px; }

.btn-inline i {
  color: #fff; }
.btn-inline .fb-btn {
  background-color: #3b5998; }
.btn-inline .twitter-btn {
  background-color: #55acee; }
.btn-inline .pinterest-btn {
  background-color: #cb2027; }
.btn-inline .linkedin-btn {
  background-color: #007bb5; }
.btn-inline .dribbble-btn {
  background-color: #ea4c89; }

.user-post {
  font-style: italic; }

.connection-list img,
.contact-user h4,
.contact-user img,
.earn-heading,
.list-inline,
.list-inline li,
.service-header {
  display: inline-block; }

.connection-list img {
  width: 55px;
  height: 55px;
  margin: 5px; }

.border-post {
  border: 1px solid #ccc; }

.earn-sub-header {
  font-size: 15px;
  color: #ccc; }

.btn-inline {
  margin-top: 20px; }

.order-summary .progress {
  margin-bottom: 32px; }

.services .service-btn::after {
  top: 20px;
  right: 15px; }

.services-list {
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 46px; }

.contact-menu i,
.fa-cog,
.services-list i {
  margin-right: 10px; }

.media-heading {
  cursor: pointer; }

h6.media-heading {
  font-weight: 500; }

.contact-details table .fa-star,
.contact-details table .fa-star-o,
.review-star i {
  color: #448aff; }

.review-star i:last-child {
  color: #ccc; }

.card-block.user-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding: 1.25rem; }

.profile-bg-img {
  width: 100%; }

.user-title {
  position: relative;
  bottom: 20px; }
  .user-title h2 {
    color: #fff;
    text-shadow: 1px 1px 4px #373a3c;
    font-size: 20px; }

.profile-image img {
  border: 4px solid #fff; }

.user-info .media-body,
.user-info .media-left {
  display: table-cell;
  vertical-align: middle; }

.cover-btn {
  bottom: 38px;
  right: 35px;
  position: absolute; }

.cover-profile .profile-bg-img {
  margin-bottom: 25px; }

.groups-contact span {
  float: right; }
  .groups-contact span h4 {
    font-size: 18px;
    margin-bottom: 20px; }

.contact-menu {
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 15%;
  right: 10%; }
  .contact-menu .dropdown-item {
    padding: 8px 20px; }

.tab-header {
  margin-bottom: 20px; }

/*====== User-Profile End ======*/
/**  =====================
      User-card css start
==========================  **/
.card.user-card {
  border-top: none;
  -webkit-box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear; }
  .card.user-card:hover {
    -webkit-box-shadow: 0 0 25px -5px #9e9c9e;
            box-shadow: 0 0 25px -5px #9e9c9e; }

.card-header-img ~ .btn-group i {
  margin-right: 0; }

.card.business-info {
  border-top: none;
  border-left-width: 2px !important;
  -webkit-box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear; }
  .card.business-info:hover {
    -webkit-box-shadow: 0 0 25px -5px #9e9c9e;
            box-shadow: 0 0 25px -5px #9e9c9e; }

.top-cap-text p {
  padding: 10px 0;
  margin-bottom: 0; }

.user-content {
  text-align: center;
  margin-top: 20px; }
  .user-content h4 {
    font-size: 16px;
    font-weight: 500; }
  .user-content h5 {
    font-size: 14px; }

.img-overlay {
  bottom: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  -webkit-transform: scale(0);
          transform: scale(0);
  margin: 0 auto; }
  .img-overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .img-overlay span .btn {
      display: inline-block; }
      .img-overlay span .btn i {
        margin-right: 0; }

.img-hover-main {
  padding: 0 40px; }

.img-hover {
  position: relative;
  margin: 0 auto; }
  .img-hover:hover .img-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }

.card-header-img img {
  margin: 0 auto;
  display: block; }
.card-header-img h4 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 500;
  text-align: center; }
.card-header-img h5,
.card-header-img h6 {
  margin-top: 15px;
  font-size: 15px;
  color: #222;
  font-weight: 500;
  text-align: center; }

.simple-cards .btn-group {
  margin: 20px auto 0; }
  .simple-cards .btn-group button {
    margin: 0; }
.simple-cards .user-card {
  padding: 20px 0;
  text-align: center; }
  .simple-cards .user-card .label-icon {
    margin-top: 15px; }
    .simple-cards .user-card .label-icon i {
      font-size: 20px; }
    .simple-cards .user-card .label-icon .badge-top-right {
      margin-left: 3px;
      top: -8px; }

.card-icon {
  display: block;
  margin-bottom: 5px; }

.btn-outline-primary {
  border-color: #448aff;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 15px; }
  .btn-outline-primary:hover {
    background-color: #448aff;
    border-color: #448aff; }

.simple-cards p {
  margin: 20px;
  font-size: 15px; }

.user-profile #edit-btn,
.user-profile #edit-info-btn {
  margin-bottom: 0; }

.card-block ul.list-contacts,
.card-block.groups-contact ul {
  display: block;
  float: none; }
  .card-block ul.list-contacts li,
  .card-block.groups-contact ul li {
    display: block;
    float: none; }

ul.list-contacts .list-group-item a {
  color: #292b2c; }
ul.list-contacts .list-group-item.active a {
  color: #fff; }

.pagination li {
  display: inline-block; }

.card-block.groups-contact {
  margin-bottom: 0; }
.card-block .connection-list {
  margin-bottom: 20px; }

.table button {
  margin-bottom: 0; }

#crm-contact .img-circle,
img.comment-img {
  width: 40px;
  height: 40px; }

.page-link {
  color: #448aff; }

.page-item.active .page-link {
  background-color: #448aff;
  border-color: #448aff; }

#main {
  margin-bottom: 20px; }

/*====== User-card End ======*/
/**  =====================
      Offline css start
==========================  **/
.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd; }

/*==== Offline page css end ====*/
/**  =====================
      Blog css start
==========================  **/
.blog-page {
  border-top: none;
  -webkit-box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05); }
  .blog-page .blog-box {
    -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden; }
    .blog-page .blog-box h5 {
      border-bottom: 1px solid #ccc;
      color: #448aff;
      margin-top: 0;
      padding-bottom: 10px;
      margin-bottom: 15px;
      font-size: 18px;
      display: block; }
    .blog-page .blog-box .option-font {
      background-color: #448aff;
      border-radius: 50%;
      bottom: 320px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      line-height: 35px;
      text-align: center;
      width: 35px; }
    .blog-page .blog-box .blog-detail {
      padding: 10px; }
  .blog-page .blog-img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    margin-bottom: -20px; }
    .blog-page .blog-img:hover {
      opacity: 0.8;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .blog-page .blog-img .sharing {
      position: relative;
      bottom: 50px;
      left: 15px; }
    .blog-page .blog-img .share {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      bottom: 3px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      left: 50px;
      padding: 3px 5px;
      position: absolute; }
    .blog-page .blog-img .share::before {
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid rgba(0, 0, 0, 0.5);
      border-top: 6px solid rgba(0, 0, 0, 0.5);
      bottom: 8px;
      content: "";
      height: 42%;
      left: -6px;
      position: absolute;
      -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg);
      width: 12px; }
    .blog-page .blog-img .share i {
      color: #fff;
      font-size: 15px;
      border-right: 1px solid #fff;
      padding: 0 5px; }
      .blog-page .blog-img .share i:last-child {
        border: none; }
      .blog-page .blog-img .share i a {
        padding: 0 5px;
        color: #fff; }

.author {
  display: inline-block;
  float: right; }
  .author a {
    font-size: 13px; }

.blog_post_img h5 {
  display: block; }

.blog-date {
  font-size: 18px;
  padding-bottom: 10px;
  display: block; }
  .blog-date i {
    margin-left: 5px;
    font-size: 14px;
    color: #448aff; }
  .blog-date .icon-calendar {
    font-size: 16px;
    margin-top: 5px; }

.day {
  color: #bbb;
  padding-left: 10px;
  font-size: 14px; }

.btn-blog a {
  color: #fff !important; }

.blog-single h4 {
  margin-top: 20px;
  color: #448aff;
  font-weight: 400; }
.blog-single p {
  margin: 20px 0; }
  .blog-single p:last-child {
    color: #777; }
.blog-single .qutoe-text {
  font-size: 15px;
  color: #448aff;
  border-left: 3px solid #448aff;
  padding-left: 25px;
  font-weight: 400; }
.blog-single img:first-child {
  width: 100%; }

.blog-article .articles img {
  width: 100%; }

.blog-big-user h6,
.blog-big-user p {
  color: #757575; }

.blog-s-reply h6 {
  color: #448aff; }

.shares-like li:first-child,
.shares-like li i {
  color: #777; }

.blog-tag li {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #ccc;
  margin: 5px 15px 5px 0; }
  .blog-tag li a,
  .blog-tag li i {
    color: #777; }
  .blog-tag li:first-child {
    border: none;
    padding: 5px 0; }
  .blog-tag li i {
    font-size: 20px;
    vertical-align: middle; }
  .blog-tag li:hover {
    background-color: #448aff;
    border-color: #448aff; }
    .blog-tag li:hover a,
    .blog-tag li:hover i {
      color: #fff; }

.shares-like li {
  display: inline-block;
  margin: 5px 15px 5px 0;
  font-size: 20px; }
  .shares-like li:first-child {
    font-size: 17px; }
  .shares-like li i {
    color: #fff; }
.shares-like .btn-facebook,
.shares-like .btn-google-plus,
.shares-like .btn-linkedin,
.shares-like .btn-pinterest,
.shares-like .btn-twitter {
  color: #fff;
  padding: 10px 15px;
  display: inline-block; }

.btn-dribbble i,
.btn-dropbox i,
.btn-facebook i,
.btn-flickr i,
.btn-github i,
.btn-google-plus i,
.btn-instagram i,
.btn-linkedin i,
.btn-pinterest i,
.btn-skype i,
.btn-tumblr i,
.btn-twitter i,
.btn-youtube i {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 3px 0 0 3px;
  margin: -7px 0 -7px -13px; }

.blog-article .articles h6 {
  padding-top: 20px;
  font-weight: 400; }
.blog-article .articles a {
  font-weight: 400;
  font-size: 15px;
  color: #448aff;
  margin: 20px 0;
  display: block; }

.blog-s-reply h6 span {
  font-size: 12px;
  color: #777;
  margin-left: 5px; }

.blog-u-comment span {
  font-size: 14px; }
.blog-u-comment .blog-edit a,
.blog-u-comment .blog-reply a {
  margin-right: 10px;
  font-size: 12px; }

.system_font_color {
  font-size: 18px;
  color: #448aff; }
  .system_font_color a {
    color: #448aff;
    margin-left: 5px; }

.port_detail_next_search h5 {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  display: block;
  font-weight: lighter; }
.port_detail_next_search a {
  color: #333;
  -webkit-transition: all 0.3s 0s;
  transition: all 0.3s 0s; }
  .port_detail_next_search a i {
    float: right;
    margin-top: 6px; }

.blog_detail_social_icon span {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: inline-block;
  margin-bottom: 10px; }
  .blog_detail_social_icon span:hover {
    background-color: #448aff;
    color: #fff; }

.latest_blog h5 a {
  color: #333;
  font-weight: 500; }

/*====== Blog css ends =======*/
/**  =====================
      Gallery css start
==========================  **/
.gallery-page .card-block {
  margin-bottom: -20px; }

.thumbnail {
  margin-bottom: 20px; }
  .thumbnail .thumb {
    position: relative;
    display: block; }

.card.gallery-desc {
  overflow: hidden; }

.masonry-media {
  overflow: hidden; }
  .masonry-media img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .masonry-media img:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.masonry-image .masonry-media {
  margin-bottom: 20px; }

figure.effect-goliath {
  background: #448aff; }

/**====== Gallery css end ======**/
/**  =====================
      List-chart css start
==========================  **/
.barchart canvas,
.tristate canvas {
  width: 100% !important; }

.ct-series-a .ct-line {
  stroke: #1ce3bb; }

.ct-series-b .ct-line {
  stroke: rgba(255, 157, 136, 0.62); }

.ct-series-c .ct-line {
  stroke: rgba(79, 84, 103, 0.45); }

.ct-series-d .ct-line {
  stroke: rgba(129, 142, 219, 0.61); }

.ct-series-a .ct-point,
.ct-series-b .ct-point,
.ct-series-c .ct-point,
.ct-series-d .ct-point {
  stroke: rgba(52, 54, 70, 0.47);
  stroke-width: 8px;
  stroke-linecap: round; }

.ct-series-a .ct-slice-donut {
  stroke: #01C0C8; }

.ct-series-b .ct-slice-donut {
  stroke: #83D6DE; }

.ct-series-c .ct-slice-donut {
  stroke: #1ABC9C; }

.ct-series-d .ct-slice-donut {
  stroke: #4F5467; }

/*===== List-chart Css End ====*/
/**  =====================
      Task-list css start
==========================  **/
.task-list select {
  width: 92%; }
  .task-list select .task-list img {
    margin-right: 5px;
    display: inline-block; }
.task-list input {
  width: 80%; }

.task-list-table img {
  width: 40px; }
  .task-list-table img i {
    color: #333;
    margin-right: 5px; }

.task-page td:last-child {
  position: relative; }
.task-page a {
  cursor: pointer; }
.task-page tr td:last-child i {
  margin-right: 10px; }

/*===== Task-list css end ====*/
/**  =====================
      Task-detiails css start
==========================  **/
.thumb-img {
  position: relative;
  display: block; }
  .thumb-img:hover .caption-hover {
    background-color: rgba(0, 0, 0, 0.7);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    position: absolute;
    width: 100%;
    height: 100%; }

.caption-hover {
  top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out; }
  .caption-hover > span {
    top: 38%;
    width: 100%;
    position: absolute;
    text-align: center; }

.media .b-2-primary {
  border: 2px solid #448aff; }

.thumb-block {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 3px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out; }
  .thumb-block a {
    font-size: 12px; }
  .thumb-block .btn i {
    margin-right: 0; }

.comment-block .btn i {
  margin-right: 0; }

.dropdown-item {
  color: #666;
  padding: 6px 20px; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #448aff; }

.task-detail-right .counter {
  text-align: center;
  color: #777; }

.task-details .table.table-xs td,
.task-details .table.table-xs th {
  padding: 1rem 0.3rem; }

.assign-user .media-left {
  position: relative; }
  .assign-user .media-left img {
    margin-bottom: 0; }

.photo-table img {
  display: inline-block;
  width: 40px;
  margin-bottom: 5px; }

.v-middle {
  vertical-align: middle; }

.revision-block .form-group {
  margin-bottom: 0; }

.revision-block .btn i {
  margin-right: 0; }

.task-setting .switchery {
  display: block !important;
  float: right; }
.task-setting .form-group {
  margin-bottom: 0; }

.task-attachment i {
  cursor: pointer; }

/**====== Task-details css end ======**/
/**  =====================
      Task-board css start
==========================  **/
.filter-bar .nav,
.filter-bar .nav-item {
  display: inline-block; }

.filter-bar > .navbar {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  padding: 0.5rem 1rem; }

.navbar-nav .nav-item {
  float: left;
  line-height: 26px; }

.nav-item button i {
  margin-right: 0; }

.filter-bar .navbar-light .navbar-nav .nav-link {
  margin-right: 10px; }

.card-footer .task-list-table,
.card-footer .task-list-table a img {
  display: inline-block; }

.task-board {
  margin-top: 10px;
  float: right; }

.task-board .dropdown {
  display: inline-block; }

p.task-detail {
  margin-bottom: 5px; }
p.task-due {
  margin-bottom: 0; }

.task-right-header-revision,
.task-right-header-status,
.task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc; }

.taskboard-right-progress,
.taskboard-right-revision,
.taskboard-right-users {
  margin-top: 10px; }

.task-right h6 {
  font-size: 12px; }

.task-right .icofont {
  margin-top: 5px;
  margin-right: 0; }

.taskboard-right-revision .media .media-body .chat-header {
  font-size: 13px; }

.media-left i {
  margin-right: 0; }

.nav-item.nav-grid {
  float: right; }

.faq-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 10px; }

.faq-progress .progress .faq-text1,
.faq-progress .progress .faq-text2,
.faq-progress .progress .faq-text3,
.faq-progress .progress .faq-text4,
.faq-progress .progress .faq-text5 {
  font-weight: 500;
  margin-right: -37px; }

.faq-progress .progress .faq-bar1,
.faq-progress .progress .faq-bar2,
.faq-progress .progress .faq-bar3,
.faq-progress .progress .faq-bar4,
.faq-progress .progress .faq-bar5 {
  background: #29aecc;
  height: 10px;
  border-radius: 0;
  position: absolute;
  top: 0; }

.faq-progress .progress .faq-bar1 {
  background-color: #ffe100; }

.faq-progress .progress .faq-text1 {
  color: #2196F3; }

.faq-progress .progress .faq-bar2,
.faq-progress .progress .faq-bar5 {
  background-color: #11c15b; }

.faq-progress .progress .faq-text2,
.faq-progress .progress .faq-text5 {
  color: #4CAF50; }

.faq-progress .progress .faq-bar3 {
  background-color: #ff5252; }

.faq-progress .progress .faq-text3 {
  color: #ff5252; }

.faq-progress .progress .faq-bar4 {
  background-color: #448aff; }

.faq-progress .progress .faq-text4 {
  color: #f57c00; }

.card-faq h4 {
  color: #2196F3; }

.faq-progress .progress {
  margin-bottom: 10px; }

/**====== Tsak-board css end ======**/
/**  =====================
      Issue-list css start
==========================  **/
.issue-list-progress {
  border-bottom: 1px solid #ccc; }

.issue-list-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 9px;
  width: 100%;
  margin: 20px 0;
  overflow: visible; }

.issue-progress .progress .issue-text1 {
  font-weight: 500;
  position: absolute; }

.issue-progress .progress .issue-bar1 {
  background: #448aff;
  height: 10px;
  border-radius: 0;
  position: absolute;
  top: 0; }

.matrics-issue .sub-title {
  padding-top: 20px;
  padding-bottom: 10px;
  display: block; }

.bg-white {
  background-color: #fff !important; }

.matrics-issue div h6 {
  padding-top: 10px;
  color: #777; }

.dd-w,
.sp-container {
  z-index: 99999 !important; }

table.matrics-table tr:first-child td {
  border-top: none !important; }

#issue-list-table > thead > tr > th {
  border-bottom: none; }

.note-card .notes-list {
  margin-bottom: 20px; }

/**====== Issue-list css end ======**/
/**  =====================
      Product css start
==========================  **/
.prod-img {
  position: relative;
  overflow: hidden; }
  .prod-img .btn i {
    margin-right: 0; }

.prod-item .prod-img .option-hover {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  left: 0; }
  .prod-item .prod-img .option-hover .btn-icon {
    border-radius: 5px; }

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .hvr-shrink:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.prod-img .p-new a {
  position: absolute;
  top: 15px;
  right: 0;
  padding: 8px 13px;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px 0 0 2px;
  background: #00bcd4;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500; }

.prod-info .br-wrapper {
  margin: 0 auto 20px; }
.prod-info .br-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0; }
.prod-info h4 {
  font-size: 18px;
  margin-bottom: 10px; }
.prod-info .prod-price {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px; }
  .prod-info .prod-price .old-price {
    color: #919aa3;
    text-decoration: line-through;
    font-size: 50%; }

.prod-view:hover .option-hover {
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 1; }

.prod-item .prod-info {
  background: #fff;
  padding: 30px 0 20px; }

.prod-item .br-widget {
  min-height: inherit; }

.prod-img .p-sale {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: #ff5252;
  color: #fff;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 11px 4px; }

.prod-info .br-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0; }

.option-icon i {
  height: 25px;
  width: 25px;
  font-size: 25px;
  line-height: 36px; }

/**====== Product css end ======**/
/**  =====================
      Product-details css start
==========================  **/
.product-detail-page {
  border-top: none; }

.product-detail .br-wrapper {
  margin: 10px 0; }
.product-detail .product-detail .btn i {
  margin-right: 0; }
.product-detail .br-widget {
  min-height: 35px;
  margin-top: 0;
  display: block; }
.product-detail .btn-number {
  background-color: #e0e0e0;
  border-color: #d4d4d4;
  border-radius: 0;
  color: #000; }
.product-detail .product-price {
  display: inline-block;
  margin-right: 50px;
  font-size: 24px; }
.product-detail .pro-desc {
  margin-top: 15px;
  margin-bottom: 15px; }
.product-detail .done-task {
  text-decoration: line-through; }
.product-detail hr {
  margin-top: 15px;
  margin-bottom: 15px; }

#small_banner .slick-slide {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5; }
#small_banner img {
  cursor: pointer; }
#small_banner .slick-center {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1; }
#small_banner:hover .slick-prev {
  left: 0;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
#small_banner:hover .slick-next {
  right: 0;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }

.port_details_all_img {
  overflow: hidden; }

.page-link:hover {
  color: #448aff; }

.slick-prev {
  left: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .slick-prev:focus, .slick-prev:hover {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, 0.8); }

.brighttheme-icon-sticker:after {
  top: -5px;
  content: "\002016"; }

.slick-next {
  right: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .slick-next:focus, .slick-next:hover {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, 0.8); }

.counter-input .input-group {
  width: 20%; }

.pro-det-tab .tab-content {
  border: 1px solid #ccc;
  border-top: 0; }

.big_banner .port_big_img {
  margin-bottom: 15px; }

.cd-price .cd-price-month {
  font-size: 64px; }

.product-edit .br-wrapper {
  margin: 10px 0 30px; }

.addcontact .md-content > div ul {
  padding-left: 0; }

/**====== Product-details css end ======**/
/* ======================
    Panels and wells css start
========================= */
.panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body p {
  overflow: hidden;
  margin-bottom: 0; }

.panels-wells .panel {
  margin-bottom: 20px; }
.panels-wells .panel .panel-body {
  padding: 15px; }

.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel-footer {
  padding: 10px 15px;
  background-color: #fafafa;
  border-top: 1px solid #eeeeee;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

.well-sm {
  padding: 9px;
  border-radius: 3px; }

.well-lg {
  padding: 24px;
  border-radius: 6px; }

/**====== Panels and wells css end ======**/
/**  =====================
      Search css start
==========================  **/
.search-result .card {
  border-top: none;
  -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15); }
  .search-result .card .card-block h5 {
    font-weight: 500; }
  .search-result .card .card-block p {
    margin-bottom: 0;
    margin-top: 10px;
    line-height: 1.4; }

.seacrh-header {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.search-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #ccc; }
  .search-content img {
    width: 120px; }
  .search-content:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }

.search-result i {
  float: right; }

.search2 .card-block:first-child {
  padding-top: 0; }

/**====== Search css end ======**/
/**  =====================
      Wizard css start
==========================  **/
.cart-page .wizard > .content > .body {
  width: 100%;
  padding: 0; }

.wizard > .content > .body {
  width: 100%; }

.card .wizard > .steps .current a {
  outline: none;
  border-radius: 5px; }

.payment-card {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 15px;
  padding: 20px; }
  .payment-card table {
    margin-bottom: 0; }

.confirmation {
  text-align: center;
  font-size: 80px; }

.confirmation-icon {
  color: #ff5252; }

.width-100 {
  width: 100%; }

.post-input {
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  resize: none; }

.file-upload-lbl {
  max-width: 15px;
  padding: 5px 0 0; }

/**====== Wizard css end ======**/
/**====== Timeline css start ======**/
.post-timelines .media {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

a[data-lightbox="example-set"] .img-fluid,
a[data-lightbox="roadtrip"] .img-fluid,
a[data-toggle="lightbox"] .img-fluid {
  margin: 10px 0; }

/**====== Timeline css end ======**/
/**  =====================
      Wall css start
==========================  **/
.post-new-footer {
  border-top: 1px solid #ccc; }
  .post-new-footer i {
    font-size: 18px;
    margin-right: 15px;
    cursor: pointer; }

.inline-editable .btn {
  padding: 10px; }
.inline-editable .input-group .form-control {
  width: 100%; }

.input-group span {
  cursor: pointer; }
.input-group a {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px; }

.msg-send {
  background-color: #f3f3f3; }
  .msg-send:focus {
    background-color: #f3f3f3; }

.wall-dropdown:after {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer; }

.wall-img-preview {
  display: inline-block;
  text-align: center; }
  .wall-img-preview .wall-item {
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .wall-img-preview .wall-item:first-child:nth-last-child(2),
  .wall-img-preview .wall-item:first-child:nth-last-child(2) ~ div {
    width: 50%; }

.wall-img-preview .wall-item > img {
  display: none; }

.wall-img-preview .wall-item:first-child:nth-last-child(2),
.wall-img-preview .wall-item:first-child:nth-last-child(2) ~ div {
  width: 50%; }

.wall-img-preview .wall-item:first-child:nth-last-child(10),
.wall-img-preview .wall-item:first-child:nth-last-child(10) ~ div:not(:last-child),
.wall-img-preview .wall-item:first-child:nth-last-child(11) ~ div:nth-last-of-type(-n+3),
.wall-img-preview .wall-item:first-child:nth-last-child(3),
.wall-img-preview .wall-item:first-child:nth-last-child(3) ~ div,
.wall-img-preview .wall-item:first-child:nth-last-child(4),
.wall-img-preview .wall-item:first-child:nth-last-child(4) ~ div:not(:last-child),
.wall-img-preview .wall-item:first-child:nth-last-child(5),
.wall-img-preview .wall-item:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)),
.wall-img-preview .wall-item:first-child:nth-last-child(6),
.wall-img-preview .wall-item:first-child:nth-last-child(6) ~ div,
.wall-img-preview .wall-item:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3),
.wall-img-preview .wall-item:first-child:nth-last-child(9),
.wall-img-preview .wall-item:first-child:nth-last-child(9) ~ div {
  width: 33.333333%; }

.wall-img-preview .wall-item:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
  width: 50%; }

.wall-img-preview .wall-item:first-child:nth-last-child(11),
.wall-img-preview .wall-item:first-child:nth-last-child(11) ~ div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wall-item:first-child:nth-last-child(12),
.wall-img-preview .wall-item:first-child:nth-last-child(12) ~ div,
.wall-img-preview .wall-item:first-child:nth-last-child(7),
.wall-img-preview .wall-item:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wall-item:first-child:nth-last-child(8),
.wall-img-preview .wall-item:first-child:nth-last-child(8) ~ div {
  width: 25%; }

.wall-img-preview .wall-item:first-child:nth-last-child(10) ~ div:nth-child(10),
.wall-img-preview .wall-item:first-child:nth-last-child(4) ~ div:nth-child(4),
.wall-img-preview .wall-item:only-child {
  width: 100%; }

.fb-timeliner h2 {
  background: #448aff;
  color: #fff;
  margin-top: 0;
  padding: 15px;
  font-size: 16px;
  border-radius: 2px;
  -webkit-border-radius: 4px;
  font-weight: 300; }
.fb-timeliner ul {
  margin-left: 15px;
  margin-bottom: 20px; }
  .fb-timeliner ul li {
    margin-bottom: 3px; }
    .fb-timeliner ul li a {
      color: #999797;
      border-left: 4px solid #d3d7dd;
      padding-left: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
      display: block; }
      .fb-timeliner ul li a:hover {
        color: #999797;
        border-left: 4px solid #b1b1b1;
        padding-left: 10px; }
    .fb-timeliner ul li.active a {
      color: #7a7a7a;
      border-left: 4px solid #7a7a7a;
      padding-left: 10px; }

/*====== Wall css End ======*/
/**  =====================
      X-editable css start
==========================  **/
.dotted-line-theme .no_edit {
  width: 100% !important; }

.dotted-line-theme .no_edit .i_text {
  font-size: 13px; }

.dotted-line-theme .no_edit {
  padding: 15px 2px; }

.dotted-line-theme .just_edit input[type="radio"] {
  opacity: 0; }

.dotted-line-theme .ibtn_container {
  padding-left: 0;
  margin-top: 2px;
  position: absolute;
  top: 6px;
  z-index: 999;
  width: 120px; }
  .dotted-line-theme .ibtn_container i {
    color: #fff;
    margin-right: 0; }

.dotted-line-theme .i_edit,
.dotted-line-theme .ibtn_container,
.dotted-line-theme .just_edit,
.dotted-line-theme .just_edit input,
.dotted-line-theme .just_edit textarea,
.dotted-line-theme .no_edit,
.dotted-line-theme .no_edit .i_text {
  font-size: 13px; }

.wizard > .content > .body label.error {
  margin-left: 0; }

#msform #progressbar li.active {
  color: #448aff; }

#msform #progressbar li.active:before,
#progressbar li.active:after {
  background: #448aff; }

#msform a {
  color: #448aff;
  font-weight: 500; }

/*====== X-editable css End ======*/
/**  =====================
      Invoice css start
==========================  **/
.invoice-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.invoice-table {
  border-left: 1px solid #fff;
  padding-left: 20px; }

.invoice-table td,
.invoice-table th {
  border-top: none;
  padding: 3px 0; }

.invoice-table > tbody > tr:last-child > td,
.invoice-table > tfoot > tr:last-child > td {
  padding-bottom: 0; }

.invoice-box h1 {
  font-size: 7rem; }

.invoice-order {
  padding-left: 0; }
  .invoice-order th:first-child {
    padding-left: 0;
    width: 80px; }

.invoice-detail-table th:first-child {
  width: 450px;
  text-align: left; }

.invoice-detail-table thead th {
  text-align: center; }
.invoice-detail-table td {
  vertical-align: middle;
  text-align: center; }
  .invoice-detail-table td:first-child {
    text-align: left; }

.invoice-total {
  background: #f3f3f3;
  padding: 30px 0; }

.invoice-total td,
.invoice-total th {
  text-align: right; }

.invoice-total td {
  padding-left: 30px; }

.invoive-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px; }
  .invoive-info h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: #757575; }

.invoice-total tbody {
  padding-right: 20px;
  float: right; }

.dropdown-menu i {
  margin-right: 5px; }

/**====== Invoice css end ======**/
.grid-layout span {
  display: block;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 5px 0; }

.vertical-alignment .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid #ddd; }

.inte-benifits {
  border: 1px solid #ddd; }
  .inte-benifits ul {
    list-style: square;
    padding-left: 20px; }

/**  =====================
      Version css start
==========================  **/
.version .card-block {
  padding: 0; }

.card.version .card-header .card-header-right {
  background-color: #fff;
  top: 8px; }

.version .nav {
  display: block; }

.version .nav li {
  display: block;
  padding: 10px 20px;
  cursor: pointer; }
  .version .nav li:hover {
    background-color: #f6f7f7; }
  .version .nav li a {
    color: #666; }
  .version .nav li span {
    position: absolute;
    right: 20px; }

.version .navigation-header a {
  color: #999; }

.support-btn {
  padding: 0 20px 20px; }

.introjs-helperNumberLayer {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff6c6c), to(#ff5252));
  background: linear-gradient(to bottom, #ff6c6c 0%, #ff5252 100%); }

.dd-handle,
.dd3-content {
  font-weight: 500; }

.img-radius {
  border-radius: 50%; }

.version .nav li:first-child {
  border-top: 1px solid #ddd;
  color: #666; }

/**====== Version css end ======**/
/**====== Date-dropper css ======**/
.dd-w,
.sp-container {
  z-index: 99; }

/*====== Color css ends ======= */
/**  =====================
      Session Timeout css start
==========================  **/
#sessionTimeout-dialog .close {
  display: none; }

/*====== Session Timeout css ends ======= */
.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.333333%; }

.pull-2 {
  right: 16.666667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.333333%; }

.pull-5 {
  right: 41.666667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.333333%; }

.pull-8 {
  right: 66.666667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.333333%; }

.pull-11 {
  right: 91.666667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.333333%; }

.push-2 {
  left: 16.666667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.333333%; }

.push-5 {
  left: 41.666667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.333333%; }

.push-8 {
  left: 66.666667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.333333%; }

.push-11 {
  left: 91.666667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }

  .pull-sm-0 {
    right: auto; }

  .pull-sm-1 {
    right: 8.333333%; }

  .pull-sm-2 {
    right: 16.666667%; }

  .pull-sm-3 {
    right: 25%; }

  .pull-sm-4 {
    right: 33.333333%; }

  .pull-sm-5 {
    right: 41.666667%; }

  .pull-sm-6 {
    right: 50%; }

  .pull-sm-7 {
    right: 58.333333%; }

  .pull-sm-8 {
    right: 66.666667%; }

  .pull-sm-9 {
    right: 75%; }

  .pull-sm-10 {
    right: 83.333333%; }

  .pull-sm-11 {
    right: 91.666667%; }

  .pull-sm-12 {
    right: 100%; }

  .push-sm-0 {
    left: auto; }

  .push-sm-1 {
    left: 8.333333%; }

  .push-sm-2 {
    left: 16.666667%; }

  .push-sm-3 {
    left: 25%; }

  .push-sm-4 {
    left: 33.333333%; }

  .push-sm-5 {
    left: 41.666667%; }

  .push-sm-6 {
    left: 50%; }

  .push-sm-7 {
    left: 58.333333%; }

  .push-sm-8 {
    left: 66.666667%; }

  .push-sm-9 {
    left: 75%; }

  .push-sm-10 {
    left: 83.333333%; }

  .push-sm-11 {
    left: 91.666667%; }

  .push-sm-12 {
    left: 100%; }

  .offset-sm-0 {
    margin-left: 0; }

  .offset-sm-1 {
    margin-left: 8.333333%; }

  .offset-sm-2 {
    margin-left: 16.666667%; }

  .offset-sm-3 {
    margin-left: 25%; }

  .offset-sm-4 {
    margin-left: 33.333333%; }

  .offset-sm-5 {
    margin-left: 41.666667%; }

  .offset-sm-6 {
    margin-left: 50%; }

  .offset-sm-7 {
    margin-left: 58.333333%; }

  .offset-sm-8 {
    margin-left: 66.666667%; }

  .offset-sm-9 {
    margin-left: 75%; }

  .offset-sm-10 {
    margin-left: 83.333333%; }

  .offset-sm-11 {
    margin-left: 91.666667%; } }
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }

  .pull-md-0 {
    right: auto; }

  .pull-md-1 {
    right: 8.333333%; }

  .pull-md-2 {
    right: 16.666667%; }

  .pull-md-3 {
    right: 25%; }

  .pull-md-4 {
    right: 33.333333%; }

  .pull-md-5 {
    right: 41.666667%; }

  .pull-md-6 {
    right: 50%; }

  .pull-md-7 {
    right: 58.333333%; }

  .pull-md-8 {
    right: 66.666667%; }

  .pull-md-9 {
    right: 75%; }

  .pull-md-10 {
    right: 83.333333%; }

  .pull-md-11 {
    right: 91.666667%; }

  .pull-md-12 {
    right: 100%; }

  .push-md-0 {
    left: auto; }

  .push-md-1 {
    left: 8.333333%; }

  .push-md-2 {
    left: 16.666667%; }

  .push-md-3 {
    left: 25%; }

  .push-md-4 {
    left: 33.333333%; }

  .push-md-5 {
    left: 41.666667%; }

  .push-md-6 {
    left: 50%; }

  .push-md-7 {
    left: 58.333333%; }

  .push-md-8 {
    left: 66.666667%; }

  .push-md-9 {
    left: 75%; }

  .push-md-10 {
    left: 83.333333%; }

  .push-md-11 {
    left: 91.666667%; }

  .push-md-12 {
    left: 100%; }

  .offset-md-0 {
    margin-left: 0; }

  .offset-md-1 {
    margin-left: 8.333333%; }

  .offset-md-2 {
    margin-left: 16.666667%; }

  .offset-md-3 {
    margin-left: 25%; }

  .offset-md-4 {
    margin-left: 33.333333%; }

  .offset-md-5 {
    margin-left: 41.666667%; }

  .offset-md-6 {
    margin-left: 50%; }

  .offset-md-7 {
    margin-left: 58.333333%; }

  .offset-md-8 {
    margin-left: 66.666667%; }

  .offset-md-9 {
    margin-left: 75%; }

  .offset-md-10 {
    margin-left: 83.333333%; }

  .offset-md-11 {
    margin-left: 91.666667%; } }
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }

  .pull-lg-0 {
    right: auto; }

  .pull-lg-1 {
    right: 8.333333%; }

  .pull-lg-2 {
    right: 16.666667%; }

  .pull-lg-3 {
    right: 25%; }

  .pull-lg-4 {
    right: 33.333333%; }

  .pull-lg-5 {
    right: 41.666667%; }

  .pull-lg-6 {
    right: 50%; }

  .pull-lg-7 {
    right: 58.333333%; }

  .pull-lg-8 {
    right: 66.666667%; }

  .pull-lg-9 {
    right: 75%; }

  .pull-lg-10 {
    right: 83.333333%; }

  .pull-lg-11 {
    right: 91.666667%; }

  .pull-lg-12 {
    right: 100%; }

  .push-lg-0 {
    left: auto; }

  .push-lg-1 {
    left: 8.333333%; }

  .push-lg-2 {
    left: 16.666667%; }

  .push-lg-3 {
    left: 25%; }

  .push-lg-4 {
    left: 33.333333%; }

  .push-lg-5 {
    left: 41.666667%; }

  .push-lg-6 {
    left: 50%; }

  .push-lg-7 {
    left: 58.333333%; }

  .push-lg-8 {
    left: 66.666667%; }

  .push-lg-9 {
    left: 75%; }

  .push-lg-10 {
    left: 83.333333%; }

  .push-lg-11 {
    left: 91.666667%; }

  .push-lg-12 {
    left: 100%; }

  .offset-lg-0 {
    margin-left: 0; }

  .offset-lg-1 {
    margin-left: 8.333333%; }

  .offset-lg-2 {
    margin-left: 16.666667%; }

  .offset-lg-3 {
    margin-left: 25%; }

  .offset-lg-4 {
    margin-left: 33.333333%; }

  .offset-lg-5 {
    margin-left: 41.666667%; }

  .offset-lg-6 {
    margin-left: 50%; }

  .offset-lg-7 {
    margin-left: 58.333333%; }

  .offset-lg-8 {
    margin-left: 66.666667%; }

  .offset-lg-9 {
    margin-left: 75%; }

  .offset-lg-10 {
    margin-left: 83.333333%; }

  .offset-lg-11 {
    margin-left: 91.666667%; } }
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }

  .pull-xl-0 {
    right: auto; }

  .pull-xl-1 {
    right: 8.333333%; }

  .pull-xl-2 {
    right: 16.666667%; }

  .pull-xl-3 {
    right: 25%; }

  .pull-xl-4 {
    right: 33.333333%; }

  .pull-xl-5 {
    right: 41.666667%; }

  .pull-xl-6 {
    right: 50%; }

  .pull-xl-7 {
    right: 58.333333%; }

  .pull-xl-8 {
    right: 66.666667%; }

  .pull-xl-9 {
    right: 75%; }

  .pull-xl-10 {
    right: 83.333333%; }

  .pull-xl-11 {
    right: 91.666667%; }

  .pull-xl-12 {
    right: 100%; }

  .push-xl-0 {
    left: auto; }

  .push-xl-1 {
    left: 8.333333%; }

  .push-xl-2 {
    left: 16.666667%; }

  .push-xl-3 {
    left: 25%; }

  .push-xl-4 {
    left: 33.333333%; }

  .push-xl-5 {
    left: 41.666667%; }

  .push-xl-6 {
    left: 50%; }

  .push-xl-7 {
    left: 58.333333%; }

  .push-xl-8 {
    left: 66.666667%; }

  .push-xl-9 {
    left: 75%; }

  .push-xl-10 {
    left: 83.333333%; }

  .push-xl-11 {
    left: 91.666667%; }

  .push-xl-12 {
    left: 100%; }

  .offset-xl-0 {
    margin-left: 0; }

  .offset-xl-1 {
    margin-left: 8.333333%; }

  .offset-xl-2 {
    margin-left: 16.666667%; }

  .offset-xl-3 {
    margin-left: 25%; }

  .offset-xl-4 {
    margin-left: 33.333333%; }

  .offset-xl-5 {
    margin-left: 41.666667%; }

  .offset-xl-6 {
    margin-left: 50%; }

  .offset-xl-7 {
    margin-left: 58.333333%; }

  .offset-xl-8 {
    margin-left: 66.666667%; }

  .offset-xl-9 {
    margin-left: 75%; }

  .offset-xl-10 {
    margin-left: 83.333333%; }

  .offset-xl-11 {
    margin-left: 91.666667%; } }
/*====== Warning css starts ======= */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0; }

.ie-warning p {
  font-size: 17px; }

.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0; }

.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px; }

.ie-warning .iew-download > li {
  float: left;
  vertical-align: top; }

.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0; }

.ie-warning .iew-download > li > a > div {
  margin-top: 10px; }

.ie-warning .iew-download > li > a:hover {
  background-color: #eee; }

/*====== Warning css ends ======= */
.image-cropper-container {
  margin-top: 10px; }

/*====== Json form css start =======*/
.alpaca-field img {
  width: 250px; }

/*====== Json form css ends =======*/
/*====== Sticky css start =======*/
.arrow_box {
  z-index: 0; }

@media only screen and (max-width: 575px) {
  .sticky-card {
    margin-bottom: 250px; } }
/*====== Sticky css ends =======*/
/*====== Ready to use css start ======*/
.j-pro .j-label {
  font-weight: 500; }

/*====== Ready to use css ends ======*/
.ms-container {
  width: 100%; }

.progress {
  height: 1rem; }
  .progress .progress-bar {
    height: 100% !important; }

.pcoded .mCSB_container_wrapper {
  margin-right: 0; 
}
.pcoded .mCSB_container_wrapper > .mCSB_container {
    padding-right: 0; 
}
.pcoded .mCSB_scrollTools {
  width: 10px; }
  .pcoded .mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
    width: 10px; }
  .pcoded .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: rgba(55, 71, 79, 0); }
  .pcoded .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
  .pcoded .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: rgba(55, 71, 79, 0.5); }
.pcoded #styleSelector:hover .mCSB_dragger_bar,
.pcoded .main-menu:hover .mCSB_dragger_bar {
  background-color: rgba(55, 71, 79, 0.2); }

.scroll-container {
  overflow-x: hidden; }

#main-chat .page-error i,
.full-calender .page-error i {
  font-size: 35px;
  border-radius: 6px;
  padding: 6px 9px; }
@media only screen and (min-width: 788px) {
  #main-chat .page-error,
  .full-calender .page-error {
    display: none; } }
@media only screen and (max-width: 786px) {
  #main-chat .page-body,
  .full-calender .page-body {
    display: none; } }

@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right.job-compney-btn {
    position: relative;
    float: left;
    right: 0; }

  .clndr .event-listing {
    display: none; }
  .clndr .clndr-grid {
    width: 100%; }
    .clndr .clndr-grid .day-number {
      padding: 3px 4px !important; } }
.dotted-line-theme .no_edit {
  border-bottom: 1px dotted #448aff; }

.handsontable td,
.handsontable th {
  color: #37474f; }

#map,
.radial-bar:after {
  z-index: 99; }

.content-group {
  padding: 10px; }

.card-sub .card-title {
  margin-bottom: 10px;
  font-weight: 500; }
.card-sub .card-text {
  color: #737373; }

.long-press-popup {
  position: fixed;
  bottom: 15px;
  left: 275px;
  right: 35px;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  margin: 0;
  font-size: 50px; }
  .long-press-popup li {
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px; }

/**  =====================
      Theme-preloader css start
==========================  **/
.theme-loader {
  height: 100%;
  width: 100%;
  background: #fff;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0; }
  .theme-loader .loader-track {
    left: 50%;
    top: 50%;
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px; }

.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  -webkit-animation: container-rotate 1568ms linear infinite;
          animation: container-rotate 1568ms linear infinite; }

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #11c15b; }
  .spinner-layer.spinner-blue {
    border-color: #448aff;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .spinner-layer.spinner-red {
    border-color: #ff5252;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .spinner-layer.spinner-yellow {
    border-color: #ffe100;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .spinner-layer.spinner-green {
    border-color: #11c15b;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .spinner-layer, .spinner-layer.spinner-blue-only, .spinner-layer.spinner-red-only, .spinner-layer.spinner-yellow-only, .spinner-layer.spinner-green-only {
    opacity: 1;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }
  .gap-patch .circle {
    width: 1000%;
    left: -450%; }

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }
  .circle-clipper .circle {
    width: 200%;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; }
  .circle-clipper.left {
    float: left; }
    .circle-clipper.left .circle {
      left: 0;
      border-right-color: transparent !important;
      -webkit-transform: rotate(129deg);
              transform: rotate(129deg);
      -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
              animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }
  .circle-clipper.right {
    float: right; }
    .circle-clipper.right .circle {
      left: -100%;
      border-left-color: transparent !important;
      -webkit-transform: rotate(-129deg);
              transform: rotate(-129deg);
      -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
              animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

#spinnerContainer.cooldown {
  -webkit-animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
          animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1); }

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); } }

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); } }
@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }
@keyframes blue-fade-in-out {
  from {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }
@-webkit-keyframes red-fade-in-out {
  from {
    opacity: 0; }
  15% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 1; }
  51% {
    opacity: 0; } }
@keyframes red-fade-in-out {
  from {
    opacity: 0; }
  15% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 1; }
  51% {
    opacity: 0; } }
@-webkit-keyframes yellow-fade-in-out {
  from {
    opacity: 0; }
  40% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 1; }
  76% {
    opacity: 0; } }
@keyframes yellow-fade-in-out {
  from {
    opacity: 0; }
  40% {
    opacity: 0; }
  50% {
    opacity: 1; }
  75% {
    opacity: 1; }
  76% {
    opacity: 0; } }
@-webkit-keyframes green-fade-in-out {
  from {
    opacity: 0; }
  65% {
    opacity: 0; }
  75% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes green-fade-in-out {
  from {
    opacity: 0; }
  65% {
    opacity: 0; }
  75% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); } }
@keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); } }
@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); } }
@keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); } }
@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
select.form-control:not([size]):not([multiple]) {
  height: 43px; }

.jFiler.jFiler-theme-default .jFiler-input {
  border: none;
  border-bottom: 2px solid #ccc;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  height: 40px; }
.jFiler.jFiler-theme-default .jFiler-input-button {
  border-radius: 4px;
  height: auto;
  padding: 7px 19px; }

#main-chat .page-error i,
.full-calender .page-error i {
  font-size: 35px;
  border-radius: 6px;
  padding: 6px 9px; }
@media only screen and (min-width: 788px) {
  #main-chat .page-error,
  .full-calender .page-error {
    display: none; } }
@media only screen and (max-width: 786px) {
  #main-chat .page-body,
  .full-calender .page-body {
    display: none; } }

.wizard > .content {
  min-height: 36em;
  overflow-y: auto; }
  .wizard > .content > .body input.form-control {
    border-top: none;
    border-right: none;
    border-left: none; }

.tabledit-input:disabled {
  display: none; }

/**  =====================
      Generic-class css start
========================== **/
.generic-card-block code {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px; }

.generic-image-body li {
  padding: 0 20px;
  display: inline-block; }
.generic-image-body code {
  display: block; }
.generic-image-body img {
  display: block;
  margin: 10px auto 20px; }

/*====== Padding css starts ======*/
.p-0 {
  padding: 0px; }

.p-5 {
  padding: 5px; }

.p-10 {
  padding: 10px; }

.p-15 {
  padding: 15px; }

.p-20 {
  padding: 20px; }

.p-25 {
  padding: 25px; }

.p-30 {
  padding: 30px; }

.p-35 {
  padding: 35px; }

.p-40 {
  padding: 40px; }

.p-45 {
  padding: 45px; }

.p-50 {
  padding: 50px; }

/*====== Padding css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-t-50 {
  padding-top: 50px !important; }

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

/*====== Padding-bottom css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px; }

.p-l-5 {
  padding-left: 5px; }

.p-l-10 {
  padding-left: 10px; }

.p-l-15 {
  padding-left: 15px; }

.p-l-20 {
  padding-left: 20px; }

.p-l-25 {
  padding-left: 25px; }

.p-l-30 {
  padding-left: 30px; }

.p-l-35 {
  padding-left: 35px; }

.p-l-40 {
  padding-left: 40px; }

.p-l-45 {
  padding-left: 45px; }

.p-l-50 {
  padding-left: 50px; }

/*====== Padding-left css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px; }

.p-r-5 {
  padding-right: 5px; }

.p-r-10 {
  padding-right: 10px; }

.p-r-15 {
  padding-right: 15px; }

.p-r-20 {
  padding-right: 20px; }

.p-r-25 {
  padding-right: 25px; }

.p-r-30 {
  padding-right: 30px; }

.p-r-35 {
  padding-right: 35px; }

.p-r-40 {
  padding-right: 40px; }

.p-r-45 {
  padding-right: 45px; }

.p-r-50 {
  padding-right: 50px; }

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px; }

.m-5 {
  margin: 5px; }

.m-10 {
  margin: 10px; }

.m-15 {
  margin: 15px; }

.m-20 {
  margin: 20px; }

.m-25 {
  margin: 25px; }

.m-30 {
  margin: 30px; }

.m-35 {
  margin: 35px; }

.m-40 {
  margin: 40px; }

.m-45 {
  margin: 45px; }

.m-50 {
  margin: 50px; }

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px; }

.m-t-5 {
  margin-top: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-30 {
  margin-top: 30px; }

.m-t-35 {
  margin-top: 35px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-45 {
  margin-top: 45px; }

.m-t-50 {
  margin-top: 50px; }

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-b-50 {
  margin-bottom: 50px; }

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px; }

.m-l-5 {
  margin-left: 5px; }

.m-l-10 {
  margin-left: 10px; }

.m-l-15 {
  margin-left: 15px; }

.m-l-20 {
  margin-left: 20px; }

.m-l-25 {
  margin-left: 25px; }

.m-l-30 {
  margin-left: 30px; }

.m-l-35 {
  margin-left: 35px; }

.m-l-40 {
  margin-left: 40px; }

.m-l-45 {
  margin-left: 45px; }

.m-l-50 {
  margin-left: 50px; }

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px; }

.m-r-5 {
  margin-right: 5px; }

.m-r-10 {
  margin-right: 10px; }

.m-r-15 {
  margin-right: 15px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-25 {
  margin-right: 25px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-35 {
  margin-right: 35px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-45 {
  margin-right: 45px; }

.m-r-50 {
  margin-right: 50px; }

/*====== Margin-right css ends ======*/
/*====== Display css starts ======*/
.d-none {
  display: none; }

.d-inline-block {
  display: inline-block; }

.d-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

/*====== display css ends ======*/
/*====== Border-radius css starts ======*/
.b-radius-0 {
  border-radius: 0px !important; }

.b-radius-5 {
  border-radius: 5px !important; }

.b-radius-10 {
  border-radius: 10px !important; }

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px; }

.f-12 {
  font-size: 12px; }

.f-14 {
  font-size: 14px; }

.f-16 {
  font-size: 16px; }

.f-18 {
  font-size: 18px; }

.f-20 {
  font-size: 20px; }

.f-22 {
  font-size: 22px; }

.f-24 {
  font-size: 24px; }

.f-26 {
  font-size: 26px; }

.f-28 {
  font-size: 28px; }

.f-30 {
  font-size: 30px; }

.f-32 {
  font-size: 32px; }

.f-34 {
  font-size: 34px; }

.f-36 {
  font-size: 36px; }

.f-38 {
  font-size: 38px; }

.f-40 {
  font-size: 40px; }

.f-42 {
  font-size: 42px; }

.f-44 {
  font-size: 44px; }

.f-46 {
  font-size: 46px; }

.f-48 {
  font-size: 48px; }

.f-50 {
  font-size: 50px; }

.f-52 {
  font-size: 52px; }

.f-54 {
  font-size: 54px; }

.f-56 {
  font-size: 56px; }

.f-58 {
  font-size: 58px; }

.f-60 {
  font-size: 60px; }

.f-62 {
  font-size: 62px; }

.f-64 {
  font-size: 64px; }

.f-66 {
  font-size: 66px; }

.f-68 {
  font-size: 68px; }

.f-70 {
  font-size: 70px; }

.f-72 {
  font-size: 72px; }

.f-74 {
  font-size: 74px; }

.f-76 {
  font-size: 76px; }

.f-78 {
  font-size: 78px; }

.f-80 {
  font-size: 80px; }

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100; }

.f-w-300 {
  font-weight: 300; }

.f-w-400 {
  font-weight: 400; }

.f-w-600 {
  font-weight: 600; }

.f-w-700 {
  font-weight: 700; }

.f-w-900 {
  font-weight: 600; }

.f-w-600 {
  font-weight: 500; }

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal; }

.f-s-italic {
  font-style: italic; }

.f-s-oblique {
  font-style: oblique; }

.f-s-initial {
  font-style: initial; }

.f-s-inherit {
  font-style: inherit; }

/*====== Font-style css ends ======*/
/*====== Text-align css starts ======*/
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/*====== Text-align css ends ======*/
/*====== Text-Transform css starts ======*/
.text-capitalize {
  text-transform: capitalize; }

.text-uppercase {
  text-transform: uppercase; }

.text-lowercase {
  text-transform: lowercase; }

/*====== Text-Transform css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline; }

.text-line-through {
  text-decoration: line-through; }

.text-underline {
  text-decoration: underline; }

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline; }

.sub {
  vertical-align: sub; }

.super {
  vertical-align: super; }

.top {
  vertical-align: top; }

.text-top {
  vertical-align: text-top; }

.middle {
  vertical-align: middle; }

.bottom {
  vertical-align: bottom; }

.text-bottom {
  vertical-align: text-bottom; }

.initial {
  vertical-align: initial; }

.inherit {
  vertical-align: inherit; }

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.pos-static {
  position: static; }

.pos-absolute {
  position: absolute; }

.pos-fixed {
  position: fixed; }

.pos-relative {
  position: relative; }

.pos-initial {
  position: initial; }

.pos-inherit {
  position: inherit; }

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left; }

.f-right {
  float: right; }

.f-none {
  float: none; }

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden; }

.o-visible {
  overflow: visible; }

.o-auto {
  overflow: auto; }

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-20 {
  width: 20px; }

.img-30 {
  width: 30px; }

.img-40 {
  width: 40px; }

.img-50 {
  width: 50px; }

.img-60 {
  width: 60px; }

.img-70 {
  width: 70px; }

.img-80 {
  width: 80px; }

.img-90 {
  width: 90px; }

.img-100 {
  width: 100px; }

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.text-primary {
  color: #448aff !important; }

.text-warning {
  color: #ffe100 !important; }

.text-default {
  color: #d6d6d6 !important; }

.text-danger {
  color: #ff5252 !important; }

.text-success {
  color: #11c15b !important; }

.text-inverse {
  color: #37474f !important; }

.text-info {
  color: #00bcd4 !important; }

.text-custom {
  color: #64b0f2 !important; }

.text-pink {
  color: #ff7aa3 !important; }

.text-dark {
  color: #2b3d51 !important; }

.text-purple {
  color: #9261c6 !important; }

.text-muted {
  color: #78909c !important; }

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize; }

.label-primary {
  background: #448aff; }

.label-warning {
  background: #ffe100; }

.label-default {
  background: #d6d6d6; }

.label-danger {
  background: #ff5252; }

.label-success {
  background: #11c15b; }

.label-inverse {
  background: #37474f; }

.label-info {
  background: #00bcd4; }

.label-warning {
  color: #fff; }

/*======= Label-color css starts  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background: #448aff; }

.badge-warning {
  background: #ffe100; }

.badge-default {
  background: #d6d6d6; }

.badge-danger {
  background: #ff5252; }

.badge-success {
  background: #11c15b; }

.badge-inverse {
  background: #37474f; }

.badge-info {
  background: #00bcd4; }

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-color-box {
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  -webkit-box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
          box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer; }

.bg-color-box span {
  color: #fff; }

.bg-primary {
  background-color: #448aff !important;
  color: #fff; }

.bg-warning {
  background-color: #ffe100 !important;
  color: #fff; }

.bg-default {
  background-color: #d6d6d6 !important;
  color: #fff; }

.bg-danger {
  background-color: #ff5252 !important;
  color: #fff; }

.bg-success {
  background-color: #11c15b !important;
  color: #fff; }

.bg-inverse {
  background-color: #37474f !important;
  color: #fff; }

.bg-info {
  background-color: #00bcd4 !important;
  color: #fff; }

/*======= Background-color css end  ======= */
/*======= Button-color css starts  ======= */
.color-primary {
  background-color: #448aff; }

.color-warning {
  background-color: #ffe100; }

.color-default {
  background-color: #d6d6d6; }

.color-danger {
  background-color: #ff5252; }

.color-success {
  background-color: #11c15b; }

.color-inverse {
  background-color: #37474f; }

.color-info {
  background-color: #00bcd4; }

/*======= Button-color css ends  ======= */
/*======= Loader-color css starts  ======= */
.loader-primary {
  background-color: #448aff !important; }

.loader-warning {
  background-color: #ffe100 !important; }

.loader-default {
  background-color: #d6d6d6 !important; }

.loader-danger {
  background-color: #ff5252 !important; }

.loader-success {
  background-color: #11c15b !important; }

.loader-inverse {
  background-color: #37474f !important; }

.loader-info {
  background-color: #00bcd4 !important; }

/*======= Loader-color css ends  ======= */
/*======= Nestable-color css starts  ======= */
.nestable-primary {
  background-color: #448aff !important;
  border-color: #448aff;
  color: #fff !important; }

.nestable-warning {
  background-color: #ffe100 !important;
  border-color: #ffe100;
  color: #fff !important; }

.nestable-default {
  background-color: #d6d6d6 !important;
  border-color: #d6d6d6;
  color: #fff !important; }

.nestable-danger {
  background-color: #ff5252 !important;
  border-color: #ff5252;
  color: #fff !important; }

.nestable-success {
  background-color: #11c15b !important;
  border-color: #11c15b;
  color: #fff !important; }

.nestable-inverse {
  background-color: #37474f !important;
  border-color: #37474f;
  color: #fff !important; }

.nestable-info {
  background-color: #00bcd4 !important;
  border-color: #00bcd4;
  color: #fff !important; }

/*======= Nestable-color css ends  ======= */
/*======= Table-Border-Bottom-color css starts  ======= */
table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #448aff; }

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #ffe100; }

table thead .border-bottom-default th,
table tbody .border-bottom-default th,
table tbody .border-bottom-default td {
  border-bottom: 1px solid #d6d6d6; }

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #ff5252; }

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #11c15b; }

table thead .border-bottom-inverse th,
table tbody .border-bottom-inverse th,
table tbody .border-bottom-inverse td {
  border-bottom: 1px solid #37474f; }

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #00bcd4; }

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #448aff;
  color: #fff;
  border: 3px solid #448aff; }
  .table-styling .table-primary thead,
  .table-styling.table-primary thead {
    background-color: #116aff;
    border: 3px solid #116aff; }

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #ffe100;
  color: #fff;
  border: 3px solid #ffe100; }
  .table-styling .table-warning thead,
  .table-styling.table-warning thead {
    background-color: #ccb400;
    border: 3px solid #ccb400; }

.table-styling .table-default,
.table-styling.table-default {
  background-color: #d6d6d6;
  color: #fff;
  border: 3px solid #d6d6d6; }
  .table-styling .table-default thead,
  .table-styling.table-default thead {
    background-color: #bdbdbd;
    border: 3px solid #bdbdbd; }

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #ff5252;
  color: #fff;
  border: 3px solid #ff5252; }
  .table-styling .table-danger thead,
  .table-styling.table-danger thead {
    background-color: #ff1f1f;
    border: 3px solid #ff1f1f; }

.table-styling .table-success,
.table-styling.table-success {
  background-color: #11c15b;
  color: #fff;
  border: 3px solid #11c15b; }
  .table-styling .table-success thead,
  .table-styling.table-success thead {
    background-color: #0d9245;
    border: 3px solid #0d9245; }

.table-styling .table-inverse,
.table-styling.table-inverse {
  background-color: #37474f;
  color: #fff;
  border: 3px solid #37474f; }
  .table-styling .table-inverse thead,
  .table-styling.table-inverse thead {
    background-color: #222c31;
    border: 3px solid #222c31; }

.table-styling .table-info,
.table-styling.table-info {
  background-color: #00bcd4;
  color: #fff;
  border: 3px solid #00bcd4; }
  .table-styling .table-info thead,
  .table-styling.table-info thead {
    background-color: #008fa1;
    border: 3px solid #008fa1; }

/*======= Table styling css ends  ======= */
/*======= Toolbar css starts  ======= */
.toolbar-primary .tool-item {
  background: #448aff !important; }

.toolbar-primary .tool-item.selected,
.toolbar-primary .tool-item:hover {
  background: #116aff !important; }

.toolbar-primary.tool-top .arrow {
  border-color: #448aff transparent transparent; }

.toolbar-primary.tool-bottom .arrow {
  border-color: transparent transparent #448aff; }

.toolbar-primary.tool-left .arrow {
  border-color: transparent transparent transparent #448aff; }

.toolbar-primary.tool-right .arrow {
  border-color: transparent #448aff transparent transparent; }

.btn-toolbar-primary.pressed {
  background-color: #448aff; }

.toolbar-warning .tool-item {
  background: #ffe100 !important; }

.toolbar-warning .tool-item.selected,
.toolbar-warning .tool-item:hover {
  background: #ccb400 !important; }

.toolbar-warning.tool-top .arrow {
  border-color: #ffe100 transparent transparent; }

.toolbar-warning.tool-bottom .arrow {
  border-color: transparent transparent #ffe100; }

.toolbar-warning.tool-left .arrow {
  border-color: transparent transparent transparent #ffe100; }

.toolbar-warning.tool-right .arrow {
  border-color: transparent #ffe100 transparent transparent; }

.btn-toolbar-warning.pressed {
  background-color: #ffe100; }

.toolbar-light .tool-item {
  background: #d6d6d6 !important; }

.toolbar-light .tool-item.selected,
.toolbar-light .tool-item:hover {
  background: #bdbdbd !important; }

.toolbar-light.tool-top .arrow {
  border-color: #d6d6d6 transparent transparent; }

.toolbar-light.tool-bottom .arrow {
  border-color: transparent transparent #d6d6d6; }

.toolbar-light.tool-left .arrow {
  border-color: transparent transparent transparent #d6d6d6; }

.toolbar-light.tool-right .arrow {
  border-color: transparent #d6d6d6 transparent transparent; }

.btn-toolbar-light.pressed {
  background-color: #d6d6d6; }

.toolbar-danger .tool-item {
  background: #ff5252 !important; }

.toolbar-danger .tool-item.selected,
.toolbar-danger .tool-item:hover {
  background: #ff1f1f !important; }

.toolbar-danger.tool-top .arrow {
  border-color: #ff5252 transparent transparent; }

.toolbar-danger.tool-bottom .arrow {
  border-color: transparent transparent #ff5252; }

.toolbar-danger.tool-left .arrow {
  border-color: transparent transparent transparent #ff5252; }

.toolbar-danger.tool-right .arrow {
  border-color: transparent #ff5252 transparent transparent; }

.btn-toolbar-danger.pressed {
  background-color: #ff5252; }

.toolbar-success .tool-item {
  background: #11c15b !important; }

.toolbar-success .tool-item.selected,
.toolbar-success .tool-item:hover {
  background: #0d9245 !important; }

.toolbar-success.tool-top .arrow {
  border-color: #11c15b transparent transparent; }

.toolbar-success.tool-bottom .arrow {
  border-color: transparent transparent #11c15b; }

.toolbar-success.tool-left .arrow {
  border-color: transparent transparent transparent #11c15b; }

.toolbar-success.tool-right .arrow {
  border-color: transparent #11c15b transparent transparent; }

.btn-toolbar-success.pressed {
  background-color: #11c15b; }

.toolbar-dark .tool-item {
  background: #37474f !important; }

.toolbar-dark .tool-item.selected,
.toolbar-dark .tool-item:hover {
  background: #222c31 !important; }

.toolbar-dark.tool-top .arrow {
  border-color: #37474f transparent transparent; }

.toolbar-dark.tool-bottom .arrow {
  border-color: transparent transparent #37474f; }

.toolbar-dark.tool-left .arrow {
  border-color: transparent transparent transparent #37474f; }

.toolbar-dark.tool-right .arrow {
  border-color: transparent #37474f transparent transparent; }

.btn-toolbar-dark.pressed {
  background-color: #37474f; }

.toolbar-info .tool-item {
  background: #00bcd4 !important; }

.toolbar-info .tool-item.selected,
.toolbar-info .tool-item:hover {
  background: #008fa1 !important; }

.toolbar-info.tool-top .arrow {
  border-color: #00bcd4 transparent transparent; }

.toolbar-info.tool-bottom .arrow {
  border-color: transparent transparent #00bcd4; }

.toolbar-info.tool-left .arrow {
  border-color: transparent transparent transparent #00bcd4; }

.toolbar-info.tool-right .arrow {
  border-color: transparent #00bcd4 transparent transparent; }

.btn-toolbar-info.pressed {
  background-color: #00bcd4; }

/*======= Toolbar css ends  ======= */
/*======= Card-Border-Top-color css starts  ======= */
.card-border-primary {
  border-top: 4px solid #448aff; }

.card-border-warning {
  border-top: 4px solid #ffe100; }

.card-border-default {
  border-top: 4px solid #d6d6d6; }

.card-border-danger {
  border-top: 4px solid #ff5252; }

.card-border-success {
  border-top: 4px solid #11c15b; }

.card-border-inverse {
  border-top: 4px solid #37474f; }

.card-border-info {
  border-top: 4px solid #00bcd4; }

/*======= Card-Border-Top-color css ends  ======= */
/*======= Panels-wells-color css starts  ======= */
.panels-wells .panel-primary {
  border-color: #448aff; }

.panels-wells .panel-warning {
  border-color: #ffe100; }

.panels-wells .panel-default {
  border-color: #d6d6d6; }

.panels-wells .panel-danger {
  border-color: #ff5252; }

.panels-wells .panel-success {
  border-color: #11c15b; }

.panels-wells .panel-inverse {
  border-color: #37474f; }

.panels-wells .panel-info {
  border-color: #00bcd4; }

/*======= Panels-wells-color css ends  ======= */
/*======= All-Borders-color css starts  ======= */
.b-t-primary {
  border-top: 1px solid #448aff; }

.b-b-primary {
  border-bottom: 1px solid #448aff; }

.b-l-primary {
  border-left: 1px solid #448aff; }

.b-r-primary {
  border-right: 1px solid #448aff; }

.b-primary {
  border: 1px solid #448aff; }

.b-t-warning {
  border-top: 1px solid #ffe100; }

.b-b-warning {
  border-bottom: 1px solid #ffe100; }

.b-l-warning {
  border-left: 1px solid #ffe100; }

.b-r-warning {
  border-right: 1px solid #ffe100; }

.b-warning {
  border: 1px solid #ffe100; }

.b-t-default {
  border-top: 1px solid #d6d6d6; }

.b-b-default {
  border-bottom: 1px solid #d6d6d6; }

.b-l-default {
  border-left: 1px solid #d6d6d6; }

.b-r-default {
  border-right: 1px solid #d6d6d6; }

.b-default {
  border: 1px solid #d6d6d6; }

.b-t-danger {
  border-top: 1px solid #ff5252; }

.b-b-danger {
  border-bottom: 1px solid #ff5252; }

.b-l-danger {
  border-left: 1px solid #ff5252; }

.b-r-danger {
  border-right: 1px solid #ff5252; }

.b-danger {
  border: 1px solid #ff5252; }

.b-t-success {
  border-top: 1px solid #11c15b; }

.b-b-success {
  border-bottom: 1px solid #11c15b; }

.b-l-success {
  border-left: 1px solid #11c15b; }

.b-r-success {
  border-right: 1px solid #11c15b; }

.b-success {
  border: 1px solid #11c15b; }

.b-t-inverse {
  border-top: 1px solid #37474f; }

.b-b-inverse {
  border-bottom: 1px solid #37474f; }

.b-l-inverse {
  border-left: 1px solid #37474f; }

.b-r-inverse {
  border-right: 1px solid #37474f; }

.b-inverse {
  border: 1px solid #37474f; }

.b-t-info {
  border-top: 1px solid #00bcd4; }

.b-b-info {
  border-bottom: 1px solid #00bcd4; }

.b-l-info {
  border-left: 1px solid #00bcd4; }

.b-r-info {
  border-right: 1px solid #00bcd4; }

.b-info {
  border: 1px solid #00bcd4; }

.b-t-theme {
  border-top: 1px solid #d6d6d6; }

.b-b-theme {
  border-bottom: 1px solid #d6d6d6; }

.b-l-theme {
  border-left: 1px solid #d6d6d6; }

.b-r-theme {
  border-right: 1px solid #d6d6d6; }

.b-theme {
  border: 1px solid #d6d6d6; }

/*======= All-Borders-color css ends  ======= */
/*======= All-color css start  ======= */
.bg-facebook {
  background: #3B5997; }

.text-facebook {
  color: #3B5997; }

.bg-twitter {
  background: #42C0FB; }

.text-twitter {
  color: #42C0FB; }

.bg-dribbble {
  background: #EC4A89; }

.text-dribbble {
  color: #EC4A89; }

.bg-pinterest {
  background: #BF2131; }

.text-pinterest {
  color: #BF2131; }

.bg-youtube {
  background: #E0291D; }

.text-youtube {
  color: #E0291D; }

.bg-googleplus {
  background: #C73E2E; }

.text-googleplus {
  color: #C73E2E; }

.bg-instagram {
  background: #AA7C62; }

.text-instagram {
  color: #AA7C62; }

.bg-viber {
  background: #7B519D; }

.text-viber {
  color: #7B519D; }

.bg-amazon {
  background: #000; }

.text-amazon {
  color: #000; }

.bg-behance {
  background: #0057ff; }

.text-behance {
  color: #0057ff; }

.bg-dropbox {
  background: #3380FF; }

.text-dropbox {
  color: #3380FF; }

.bg-c-blue {
  background: #448aff; }

.text-c-blue {
  color: #448aff; }

.bg-c-red {
  background: #ff5252; }

.text-c-red {
  color: #ff5252; }

.bg-c-green {
  background: #11c15b; }

.text-c-green {
  color: #11c15b; }

.bg-c-yellow {
  background: #ffe100; }

.text-c-yellow {
  color: #ffe100; }

.bg-c-orenge {
  background: #FE8A7D; }

.text-c-orenge {
  color: #FE8A7D; }

.bg-c-lite-green {
  background: #69CEC6; }

.text-c-lite-green {
  color: #69CEC6; }

.bg-c-purple {
  background: #536dfe; }

.text-c-purple {
  color: #536dfe; }

/*======= All-color css ends  ======= */
/**====== Generic-class css end ======**/
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(1) > a > .pcoded-micon {
  color: #448aff; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(2) > a > .pcoded-micon {
  color: #b71c1c; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(3) > a > .pcoded-micon {
  color: #4CAF50; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(4) > a > .pcoded-micon {
  color: #d84315; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(5) > a > .pcoded-micon {
  color: #4527a0; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(6) > a > .pcoded-micon {
  color: #37474f; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(7) > a > .pcoded-micon {
  color: #7C4DFF; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(8) > a > .pcoded-micon {
  color: #FF5370; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(9) > a > .pcoded-micon {
  color: #448aff; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(10) > a > .pcoded-micon {
  color: #b71c1c; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(11) > a > .pcoded-micon {
  color: #4CAF50; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(12) > a > .pcoded-micon {
  color: #d84315; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(13) > a > .pcoded-micon {
  color: #4527a0; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(14) > a > .pcoded-micon {
  color: #37474f; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(15) > a > .pcoded-micon {
  color: #7C4DFF; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(16) > a > .pcoded-micon {
  color: #FF5370; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(17) > a > .pcoded-micon {
  color: #448aff; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(18) > a > .pcoded-micon {
  color: #b71c1c; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(19) > a > .pcoded-micon {
  color: #4CAF50; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(20) > a > .pcoded-micon {
  color: #d84315; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(21) > a > .pcoded-micon {
  color: #4527a0; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(22) > a > .pcoded-micon {
  color: #37474f; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(23) > a > .pcoded-micon {
  color: #7C4DFF; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li:nth-child(24) > a > .pcoded-micon {
  color: #FF5370; }
.pcoded[nav-type="st2"] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon {
  color: #37474f; }
.pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon, .pcoded[nav-type="st2"] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon {
  color: #37474f; }
  .pcoded[nav-type="st1"] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon b, .pcoded[nav-type="st2"] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon b {
    display: none; }

/**  =====================
      Left-side-menu css start
==========================  **/
.main-menu {
  float: left;
  width: 235px;
  z-index: 99;
  height: 100% !important; }
  @media only screen and (max-width: 992px) {
    .main-menu {
      height: 100% !important; } }
  .main-menu .main-menu-header {
    padding: 15px 0;
    background-size: cover;
    text-align: center;
    position: relative; }
    .main-menu .main-menu-header img {
      position: relative;
      width: 60px; }
    .main-menu .main-menu-header .user-details {
      display: block;
      margin-top: 10px;
      position: relative; }
      .main-menu .main-menu-header .user-details #more-details {
        cursor: pointer; }
      .main-menu .main-menu-header .user-details span {
        color: #fff;
        display: block;
        font-size: 14px;
        text-transform: capitalize; }
        .main-menu .main-menu-header .user-details span i {
          font-size: 10px;
          margin-left: 10px; }
    .main-menu .main-menu-header:before {
      content: "";
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
  .main-menu .main-menu-content .more-details {
    display: none; }
    .main-menu .main-menu-content .more-details a {
      padding: 10px 25px;
      display: block;
      color: #000;
      -webkit-transition: all ease-in 0.3s;
      transition: all ease-in 0.3s; }
      .main-menu .main-menu-content .more-details a:hover {
        color: #448aff; }
      .main-menu .main-menu-content .more-details a i {
        margin-right: 10px; }
  .main-menu .main-menu-content .menu-search {
    padding: 20px 20px 10px; }
  .main-menu .main-menu-content li {
    position: relative; }

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .main-menu-content,
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .main-menu-header {
  display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .main-menu {
  width: 70px; }

.sidebar_toggle a {
  margin: -5px;
  line-height: 1; }

/**  =====================
      Main-header css start
==========================  **/
.header-navbar {
  min-height: 56px;
  padding: 0; }
  .header-navbar .navbar-wrapper .navbar-logo {
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: left;
    height: 56px;
    text-align: center;
    text-transform: uppercase;
    width: 235px;
    padding: 10px; }
    @media only screen and (max-width: 992px) {
      .header-navbar .navbar-wrapper .navbar-logo {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
    .header-navbar .navbar-wrapper .navbar-logo h5 {
      margin-bottom: 0; }
    .header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
      position: absolute;
      right: 0;
      font-size: 16px;
      line-height: 3.5;
      width: 40px; }
    @media only screen and (max-width: 768px) {
      .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
        display: none !important; } }
    .header-navbar .navbar-wrapper .navbar-logo .mobile-options,
    .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
      display: none; }
  .header-navbar .navbar-wrapper .navbar-container .nav-left, .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: left;
    margin-bottom: 0; }
    .header-navbar .navbar-wrapper .navbar-container .nav-left li, .header-navbar .navbar-wrapper .navbar-container .nav-right li {
      float: left;
      line-height: 3.5;
      padding: 0 10px;
      position: relative; }
      @media only screen and (max-width: 575px) {
        .header-navbar .navbar-wrapper .navbar-container .nav-left li, .header-navbar .navbar-wrapper .navbar-container .nav-right li {
          padding: 0 5px; } }
      .header-navbar .navbar-wrapper .navbar-container .nav-left li .sidebar_toggle a, .header-navbar .navbar-wrapper .navbar-container .nav-right li .sidebar_toggle a {
        display: none; }
      .header-navbar .navbar-wrapper .navbar-container .nav-left li > a, .header-navbar .navbar-wrapper .navbar-container .nav-right li > a {
        font-size: 16px;
        display: block; }
        .header-navbar .navbar-wrapper .navbar-container .nav-left li > a > i.flag-icon, .header-navbar .navbar-wrapper .navbar-container .nav-right li > a > i.flag-icon {
          border-radius: 50%;
          width: 20px;
          height: 20px; }
    .header-navbar .navbar-wrapper .navbar-container .nav-left a, .header-navbar .navbar-wrapper .navbar-container .nav-right a {
      padding: 0 0.6rem; }
    .header-navbar .navbar-wrapper .navbar-container .nav-left .mega-menu-top i, .header-navbar .navbar-wrapper .navbar-container .nav-right .mega-menu-top i {
      font-size: 10px;
      margin-left: 10px; }
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: right; }
    .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile img {
      margin-right: 10px;
      width: 40px; }
    .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
      font-size: 10px;
      margin-left: 10px; }
    .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .show-notification li:first-child:hover, .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .profile-notification li:first-child:hover {
      background-color: #fff; }
  .header-navbar .navbar-wrapper .navbar-container .header-notification {
    -webkit-perspective: 1000px;
            perspective: 1000px;
    z-index: 99;
    position: relative; }
    .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
      background: #fff;
      -webkit-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
              box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      opacity: 1;
      position: absolute;
      right: 0;
      visibility: visible;
      width: 24rem;
      display: none;
      -webkit-transform: translateY(20px);
              transform: translateY(20px); }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
        color: #455a64; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li {
        border-top: 1px solid #448aff, #ffe100, #d6d6d6, #ff5252, #11c15b, #37474f, #00bcd4;
        line-height: initial;
        padding: 1em 20px;
        width: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child {
          padding: 10px 20px; }
          .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child h6, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child h6 {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0; }
          .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child label, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child label {
            float: right; }
        .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li img, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li img {
          width: 40px;
          margin-right: 10px;
          -ms-flex-item-align: start !important;
              align-self: flex-start !important; }
        .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover {
          background-color: #f1f1f1; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover, .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover {
        background-color: transparent; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-user, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-user {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-msg, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-msg {
        margin-bottom: 5px; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-time, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-time {
        font-size: 12px;
        color: #919aa3; }
    .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
      width: 17em; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
        font-size: 15px;
        padding-left: 0; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification i {
        font-size: 15px;
        margin-left: 0;
        margin-right: 5px; }
      .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li {
        border-top: none;
        padding: 0.7em 20px; }
        .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child {
          padding: 0.7em 20px; }
    .header-navbar .navbar-wrapper .navbar-container .header-notification.active:before {
      content: "";
      border: 10px solid transparent;
      border-left-color: #fff;
      border-top-color: #fff;
      position: absolute;
      border-radius: 5px;
      bottom: -32px;
      right: 20px;
      -webkit-box-shadow: -11px -11px 35px 0 rgba(0, 0, 0, 0.25);
              box-shadow: -11px -11px 35px 0 rgba(0, 0, 0, 0.25);
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      z-index: 9; }
  .header-navbar .navbar-wrapper .navbar-container .badge {
    border-radius: 100px;
    right: 10px;
    position: absolute;
    top: 14px;
    padding: 4px; }
    .header-navbar .navbar-wrapper .navbar-container .badge:after, .header-navbar .navbar-wrapper .navbar-container .badge:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4);
              box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4); }
    .header-navbar .navbar-wrapper .navbar-container .badge:after {
      -webkit-animation: not-blink 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: not-blink 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .header-navbar .navbar-wrapper .navbar-container .badge:before {
      -webkit-animation: not-blink 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: not-blink 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s; }
  .header-navbar .navbar-wrapper .header-search {
    line-height: 0.8; }
    .header-navbar .navbar-wrapper .header-search .main-search {
      padding: 13px 0;
      display: block; }
      .header-navbar .navbar-wrapper .header-search .main-search .input-group {
        margin-bottom: 0;
        background-color: transparent;
        border-radius: 20px; }
        .header-navbar .navbar-wrapper .header-search .main-search .input-group .form-control {
          padding: 0.3rem 0.75rem;
          width: 0;
          border: none;
          background-color: transparent; }
        .header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-addon {
          padding: 0.3rem 0.75rem 0.3rem 0;
          background-color: transparent;
          border: none; }
          .header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-addon.search-close {
            padding: 0.3rem 0 0.3rem 0.75rem;
            display: none; }
      .header-navbar .navbar-wrapper .header-search .main-search.open {
        color: #000; }
        .header-navbar .navbar-wrapper .header-search .main-search.open .input-group {
          background-color: #fff; }
          .header-navbar .navbar-wrapper .header-search .main-search.open .input-group .form-control {
            display: block; }
          .header-navbar .navbar-wrapper .header-search .main-search.open .input-group .input-group-addon {
            color: #000; }
            .header-navbar .navbar-wrapper .header-search .main-search.open .input-group .input-group-addon.search-close {
              display: block; }

@-webkit-keyframes not-blink {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0; } }

@keyframes not-blink {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0; } }
/*======= Main-header css end ======*/
.pcoded .pcoded-container {
  position: relative;
  background: #f3f3f3;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
          box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); }
.pcoded .pcoded-header {
  position: relative;
  display: block; }
.pcoded .pcoded-navbar {
  display: block;
  -webkit-box-shadow: 1px 0px 20px 0px rgba(69, 90, 100, 0.08);
          box-shadow: 1px 0px 20px 0px rgba(69, 90, 100, 0.08); }

.pcoded-main-container {
  display: block;
  position: relative;
  background: #eff5f7;
  min-height: calc(100vh - 56px); }

.pcoded .pcoded-content {
  position: relative;
  display: block; }

.pcoded-inner-navbar {
  display: block;
  position: relative; }

.pcoded-inner-content {
  padding: 10px; }

.pcoded .pcoded-navbar .pcoded-item {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0 0 20px 0;
  position: relative; }
  .pcoded .pcoded-navbar .pcoded-item:after {
    content: "";
    background-color: #e4e9eb;
    width: 80%;
    height: 1px;
    position: absolute;
    left: 10%;
    bottom: 10px; }
  .pcoded .pcoded-navbar .pcoded-item > li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative; }
    .pcoded .pcoded-navbar .pcoded-item > li > a {
      display: block;
      font-size: 14px;
      padding: 0 15px;
      text-decoration: none;
      position: relative;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .pcoded .pcoded-navbar .pcoded-item > li > a.disabled {
        opacity: 0.5;
        cursor: not-allowed !important; }
    .pcoded .pcoded-navbar .pcoded-item > li.active > a {
      font-weight: 500; }
  .pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    list-style: outside none none;
    margin: 0;
    padding: 0; }
    .pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
      display: block;
      list-style: outside none none;
      margin: 0;
      padding: 0;
      position: relative; }
      .pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
        display: block;
        font-size: 14px;
        font-weight: 100;
        padding: 0 15px;
        text-decoration: none;
        position: relative; }
        .pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext {
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
          .pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext:before {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out; }
      .pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
        font-size: 10px;
        padding-right: 5px; }
  .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    font-size: 14px;
    padding: 4px;
    margin-right: 10px;
    color: #fff;
    border-radius: 4px;
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center; }
    .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon b {
      margin: 0 2px;
      font-size: 12px;
      line-height: 1;
      font-weight: 500; }
.pcoded[vertical-placement="right"] .main-body .page-wrapper .page-header-title i {
  margin-right: 0;
  margin-left: 20px; }

.pcoded-inner-navbar {
  height: 100%; }

.pcoded[theme-layout="vertical"] .pcoded-header {
  width: 100%;
  -webkit-box-shadow: 0px 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
          box-shadow: 0px 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  z-index: 1029; }
  .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header {
    display: block;
    z-index: 1028; }
    .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header .pcoded-logo {
      opacity: 1;
      text-align: center;
      visibility: visible;
      height: 50px;
      white-space: nowrap; }
      .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header .pcoded-logo a {
        display: block;
        text-decoration: none;
        padding: 9px 0; }
      .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header .pcoded-logo .logo-icon {
        bottom: -21px;
        font-size: 60px;
        left: 8px;
        position: absolute;
        z-index: -1;
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-icon {
  font-size: 45px;
  bottom: -7px;
  left: 5px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header.nocollapsed .pcoded-left-header .pcoded-logo .logo-text {
  font-family: open sans;
  font-size: 16px;
  font-weight: 400;
  margin: 0 auto !important;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  position: relative;
  top: 5px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text {
  opacity: 0;
  position: absolute;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  visibility: hidden; }
.pcoded[theme-layout="vertical"][vertical-nav-type="offcanvas"] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo {
  opacity: 0;
  visibility: hidden;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg); }
.pcoded[theme-layout="vertical"][vertical-nav-type="offcanvas"] .pcoded-navbar {
  opacity: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-header .pcoded-left-header .pcoded-logo a .hide-in-smallsize {
  display: none; }
.pcoded[theme-layout="vertical"] .pcoded-header .sidebar_toggle a {
  border-radius: 4px;
  float: left;
  font-size: 18px;
  height: 35px;
  margin-right: 5px;
  position: relative;
  text-align: center;
  top: 7px;
  width: 40px;
  border-width: 0;
  border-style: solid; }
  .pcoded[theme-layout="vertical"] .pcoded-header .sidebar_toggle a i {
    position: relative;
    top: -7px; }
  .pcoded[theme-layout="vertical"] .pcoded-header .sidebar_toggle a:hover {
    text-decoration: none; }

.sidebar_toggle a:focus {
  text-decoration: none; }

.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header {
  line-height: 50px;
  padding: 0 15px; }
  .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    float: left; }
  .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rr-header {
    float: right; }
  .pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    padding-left: 20px; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header .pcoded-right-header .pcoded-rl-header {
  padding-left: 0; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

.pcoded-right-header .pcoded-rr-header ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header ul > li,
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rr-header ul > li {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0 5px;
  position: relative; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header ul > li > a,
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rr-header ul > li > a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header li.icons > a > i,
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rr-header li.icons > a > i {
  font-size: 18px;
  position: relative;
  top: 3px; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rl-header li.icons > a,
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-right-header .pcoded-rr-header li.icons > a {
  padding: 0 5px; }
.pcoded[theme-layout="vertical"] .pcoded-right-header .pcoded-badge {
  background-color: #777;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  min-height: 17px;
  min-width: 10px;
  opacity: 1;
  padding: 4px 7px;
  position: absolute;
  right: -4px;
  text-align: center;
  top: 9px;
  transition: opacity 0.3s linear 0s;
  -webkit-transition: opacity 0.3s linear 0s;
  -ms-transition: opacity 0.3s linear 0s;
  -moz-transition: opacity 0.3s linear 0s;
  -o-transition: opacity 0.3s linear 0s;
  vertical-align: middle;
  visibility: visible;
  white-space: nowrap; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .hide-small-device {
  display: none; }
.pcoded[theme-layout="vertical"] .pcoded-navbar {
  z-index: 1027;
  height: calc(100% - 56px); }
  .pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="absolute"] {
    height: 100%; }
  .pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
    top: 56px; }
.pcoded[theme-layout="vertical"] .pcoded-content {
  position: relative;
  display: block; }
.pcoded[theme-layout="vertical"] .pcoded-container {
  overflow: hidden;
  position: relative;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"].pcoded-wrapper,
.pcoded[theme-layout="vertical"] .pcoded-main-container {
  position: relative;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-search {
  padding: 20px 20px 5px;
  position: relative; }
.pcoded[theme-layout="vertical"][vnavigation-view="view2"] .pcoded-navbar .pcoded-search {
  border-radius: 4px 4px 0 0; }

.pcoded-search input[type="text"] {
  display: block;
  margin: 0;
  width: 100%;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  background: transparent;
  padding: 6px 22px 6px 10px;
  border: none;
  border: 1px solid #ddd; }
  .pcoded-search input[type="text"]:focus {
    border-bottom-width: 1px;
    border-bottom-style: solid; }

.style-4 input[type="text"].focus {
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.pcoded-search .search-icon {
  cursor: pointer;
  margin-top: -33px;
  padding: 6px;
  position: absolute;
  right: 25px; }

.pcoded[vertical-nav-type="collapsed"] .pcoded-search-box {
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  position: absolute; }
.pcoded[vertical-nav-type="collapsed"] .pcoded-search input[type="text"] {
  padding: 6px 22px 6px 10px; }

.searchbar-toggle:before {
  content: "\f002";
  cursor: pointer;
  float: right;
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 10px;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg); }

.pcoded[vertical-nav-type="collapsed"] .searchbar-toggle:before {
  left: 35px;
  top: 10px;
  visibility: visible;
  opacity: 1;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg); }
.pcoded[vertical-nav-type="collapsed"] .pcoded-search.open .searchbar-toggle:before {
  content: "\f05c"; }
.pcoded[vertical-nav-type="collapsed"] .pcoded-search.open .pcoded-search-box {
  margin-left: 60px;
  opacity: 1;
  position: absolute;
  top: 0;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  visibility: visible;
  width: 200px;
  padding-right: 10px; }
.pcoded[vertical-nav-type="collapsed"] .pcoded-search .search-icon {
  margin-top: -35px; }
.pcoded[vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-search.open .pcoded-search-box {
  margin-left: 0;
  margin-right: 70px; }

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li > a {
  text-align: left;
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 5px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  position: relative;
  width: 100%; }
  .pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    text-align: left;
    padding: 8.7px 10px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="expanded"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  padding-left: 15px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  width: 80%;
  list-style: outside none none;
  margin: 15px auto;
  opacity: 0;
  visibility: hidden;
  position: absolute; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
  -webkit-transform-origin: 0 0;
  transform-origin: 10% 10%;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-90deg);
  -webkit-transform: rotateX(-90deg); }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  position: relative;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg); }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dashed"] li > a {
  border-bottom-style: dashed; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dotted"] li > a {
  border-bottom-style: dotted; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="solid"] li > a {
  border-bottom-style: solid; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="none"] li > a {
  border: none; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="false"] > li > a {
  border-bottom-width: 0; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"] > li > a {
  border-bottom-width: 1px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[subitem-border="false"] .pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-width: 0; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-width: 1px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li:last-child > a {
  border-bottom-width: 0; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
  float: right;
  font-style: normal;
  margin-left: 3px;
  font-size: 10px;
  position: absolute;
  right: 15px;
  top: 3px;
  font-family: 'themify';
  padding-top: 12px;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
  top: 0; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon="style1"] > a:after {
  content: "\e649"; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style1"] > a:after {
  content: "\e64b"; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon="style2"] > a:after {
  content: "\e661"; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style2"] > a:after {
  content: "\e663"; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon="style3"] > a:after {
  content: '\e61a'; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style3"] > a:after {
  content: '\e622'; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header[pcoded-lheader-position="relative"] {
  position: relative; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header[pcoded-lheader-position="fixed"] {
  position: fixed; }
.pcoded[theme-layout="vertical"] .pcoded-header .pcoded-left-header[pcoded-lheader-position="absolute"] {
  position: absolute; }
.pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="relative"] {
  position: relative; }
.pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
  position: fixed; }
.pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="absolute"] {
  position: absolute; }
.pcoded[theme-layout="vertical"] .pcoded-header[pcoded-header-position="relative"] {
  position: relative; }
.pcoded[theme-layout="vertical"] .pcoded-header[pcoded-header-position="fixed"] {
  position: fixed;
  z-index: 1028; }
.pcoded[theme-layout="vertical"] .pcoded-header[pcoded-header-position="absolute"] {
  position: absolute; }
.pcoded[theme-layout="vertical"][vertical-layout="wide"] .pcoded-container {
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][vertical-layout="box"] #styleSelector,
.pcoded[theme-layout="vertical"][vertical-layout="box"] .p-chat-user,
.pcoded[theme-layout="vertical"][vertical-layout="box"] .showChat_inner {
  position: absolute; }
.pcoded[theme-layout="vertical"][vertical-layout="box"] #styleSelector {
  top: 0; }
.pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }
  .pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container .pcoded-header {
    max-width: 1200px; }
.pcoded[theme-layout="vertical"][pcoded-device-type="tablet"][vertical-layout="box"] .pcoded-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-layout="box"] .pcoded-container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][vertical-layout="widebox"] .pcoded-container {
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="widebox"] .pcoded-main-container,
.pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="widebox"] .pcoded-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][pcoded-device-type="tablet"][vertical-layout="widebox"] .pcoded-main-container,
.pcoded[theme-layout="vertical"][pcoded-device-type="tablet"][vertical-layout="widebox"] .pcoded-wrapper {
  max-width: 900px;
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-layout="widebox"] .pcoded-main-container,
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-layout="widebox"] .pcoded-wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto; }
.pcoded[theme-layout="vertical"][vertical-layout="widebox"] .pcoded-wrapper {
  overflow: hidden; }
.pcoded .pcoded-overlay-box {
  background: #000 none repeat scroll 0 0;
  bottom: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  overflow: hidden; }
.pcoded[vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-overlay-box, .pcoded[vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-overlay-box, .pcoded[vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-overlay-box, .pcoded[vertical-nav-type="fullsub-collapsed"][vertical-effect="overlay"] .pcoded-overlay-box, .pcoded[vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-overlay-box {
  visibility: visible;
  z-index: 1026;
  opacity: 0.3;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  overflow: hidden; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
  display: none;
  font-size: 10px;
  padding-right: 5px; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  font-style: normal;
  padding-right: 4px;
  font-size: 10px;
  position: relative;
  left: 0;
  top: 0;
  opacity: 1;
  visibility: visible; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style1"] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "\f105";
  font-family: FontAwesome;
  padding-right: 5px;
  font-size: 12px; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style2"] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "\f22d";
  font-family: FontAwesome;
  padding-right: 5px;
  font-size: 12px; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style3"] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "\e08d";
  font-family: simple-line-icons; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style4"] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "\f124";
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 12px; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style5"] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "\f0da";
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 12px; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style6"] .pcoded-submenu li > a .pcoded-mtext:before {
  content: '\e649';
  font-family: 'themify';
  padding-right: 10px;
  font-size: 8px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 0 solid transparent;
  border-right: 0 solid #fff;
  border-top: 0 solid transparent;
  display: block;
  float: right;
  height: 0;
  position: absolute;
  right: 0;
  top: calc(50% - 10px);
  width: 0; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li:hover > a:before {
  border-left-style: solid;
  border-left-width: 5px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-style: solid;
  border-left-width: 5px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li.active > a:before {
  border-left-style: solid;
  border-left-width: 5px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar {
  float: right;
  right: 0; }
  .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .main-menu-content,
  .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .main-menu-header {
    direction: rtl; }
  .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .main-menu-content ul {
    padding-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-content {
  float: unset;
  left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .breadcrumb-item {
  float: right; }
.pcoded[theme-layout="vertical"][vertical-placement="left"] .pcoded-navbar {
  float: left; }
.pcoded[theme-layout="vertical"][vertical-placement="left"] .pcoded-content {
  float: unset;
  right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-header .pcoded-left-header {
  float: right;
  right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-header .pcoded-right-header {
  float: unset;
  left: unset; }
.pcoded[theme-layout="vertical"][vertical-placement="left"] .pcoded-header .pcoded-left-header {
  float: left; }
.pcoded[theme-layout="vertical"][vertical-placement="left"] .pcoded-header .pcoded-right-header {
  float: unset;
  right: unset; }
.pcoded[theme-layout="vertical"][vertical-nav-type="expanded"] .pcoded-header .pcoded-left-header,
.pcoded[theme-layout="vertical"][vertical-nav-type="expanded"] .pcoded-navbar {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-content {
  margin-left: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-content {
  margin-right: 235px;
  margin-left: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-content {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-content {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-content {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-content {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-effect="overlay"] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent !important;
  border-bottom: transparent !important;
  border-right: transparent !important;
  border-top: 15px solid transparent; }
.pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 235px;
  z-index: 1024;
  top: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar {
  width: 70px; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a {
    padding: 10px 10px 4px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-content {
  margin-left: 70px;
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 56px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 45px;
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-content {
  margin-right: 70px;
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-content {
  margin-left: 70px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-content {
  margin-right: 70px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-content {
  margin-left: 70px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 56px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 56px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-content {
  margin-right: 70px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  opacity: 1;
  visibility: visible;
  position: absolute;
  top: 9px; }
.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
  opacity: 1;
  visibility: visible; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  opacity: 0;
  visibility: hidden;
  position: absolute; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-mtext {
  opacity: 1;
  visibility: visible;
  position: absolute;
  padding-left: 22px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
  opacity: 0;
  visibility: hidden; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger a:after {
  opacity: 1;
  visibility: visible; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger {
  display: block;
  position: relative;
  width: 290px;
  z-index: 1024;
  border-right: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 56px;
  position: absolute;
  width: 210px;
  z-index: 1024;
  border-left-style: solid;
  border-left-width: 1px; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    position: relative; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  position: absolute; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger.active > a > .pcoded-mcaret {
  display: none; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-navbar {
  width: 235px;
  margin-left: -250px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-content {
  margin-left: 0;
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-navbar {
  width: 235px;
  margin-right: -250px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-content {
  margin-right: 0;
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-navbar {
  width: 235px;
  margin-left: -250px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-content {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-navbar {
  width: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-content {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-navbar {
  width: 235px;
  margin-left: -250px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-content {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-navbar {
  width: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-content {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 200px;
  margin-right: -200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-left: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-navbar {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-content {
  margin-left: 200px;
  margin-right: -200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 200px;
  margin-left: -200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-right: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-navbar {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="push"] .pcoded-content {
  margin-right: 200px;
  margin-left: -200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-left: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-navbar {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-content {
  margin-left: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-right: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-navbar {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="shrink"] .pcoded-content {
  margin-right: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-left: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-navbar {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-content {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-right: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-navbar {
  width: 200px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-content {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .main-menu-content,
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .main-menu-header {
  display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li > a {
  line-height: 1;
  text-align: center;
  padding: 10px 20px 20px; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
    opacity: 1;
    position: relative;
    text-align: center;
    visibility: visible;
    top: 10px; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    display: block;
    font-size: 18px;
    padding: 5px;
    margin: 0 auto; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 0 solid transparent;
  border-right: 0 solid #e9e9e9;
  border-top: 0 solid transparent;
  top: 18px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li.active > a:before {
  height: 100%; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li:hover > a:before {
  height: 100%; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style1"] > a:after, .pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu[dropdown-icon="style1"] > a:after {
  top: 18px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="compact"][pcoded-device-type="desktop"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="compact"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="compact"][pcoded-device-type="tablet"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 210px;
  z-index: 1024;
  top: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"] .pcoded-header .pcoded-left-header,
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"] .pcoded-navbar {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="push"] .pcoded-content {
  margin-left: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="push"] .pcoded-content {
  margin-right: 235px;
  margin-left: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-content {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-navbar {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="shrink"] .pcoded-content {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-navbar {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-content {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="sub-expanded"][vertical-effect="overlay"] .pcoded-content {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-inner-navbar, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > li, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-inner-navbar, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li {
  position: unset; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0;
  bottom: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
  position: unset; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0;
  bottom: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-style: solid;
  border-left-width: 1px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-header.iscollapsed .pcoded-left-header,
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-navbar {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="push"] .pcoded-content {
  margin-left: 235px;
  margin-right: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="push"] .pcoded-content {
  margin-right: 235px;
  margin-left: -235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] .pcoded-content {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="shrink"] .pcoded-content {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-content {
  margin-left: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="ex-popover"][vertical-effect="overlay"] .pcoded-content {
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > li, .pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li {
  position: relative; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
  position: relative; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret, .pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 19px solid transparent;
  border-right: 12px solid;
  border-top: 19px solid transparent;
  display: block;
  float: right;
  height: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1025; }
.pcoded[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-style: solid;
  border-left-width: 1px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="fullpage"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="fullpage"] .pcoded-header .pcoded-right-header {
  margin-left: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="fullpage"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar {
  width: 100%; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-content {
  margin-left: 0;
  margin-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="fullpage"] .pcoded-header .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="fullpage"] .pcoded-header .pcoded-right-header {
  margin-right: 235px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="fullpage"] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 235px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  opacity: 1;
  visibility: visible;
  width: 100%; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li.active > a {
    background: transparent !important; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item[item-border="true"] > li > a {
    border-bottom-width: 0 !important; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[pcoded-navbar-position="absolute"] {
    position: absolute; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
    position: fixed; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-inner-navbar {
    margin-top: 40px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-main-container {
  display: block;
  position: unset; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li > a {
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 24px;
  font-weight: 500; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
    opacity: 1;
    position: relative;
    visibility: visible; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-navigation-label {
  display: none !important; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li.active > a:before,
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li a .pcoded-badge {
  display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li:hover > a {
  background: transparent !important; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li:hover > a:before {
    display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li > a {
  padding: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .sidebar_toggle {
  height: 30px; }

.pcoded-navbar .sidebar_toggle a {
  opacity: 1;
  visibility: visible;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  position: absolute; }

.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .sidebar_toggle a {
  color: #f1f1f1;
  float: right;
  font-size: 36px;
  opacity: 1;
  position: relative;
  right: 10px;
  text-decoration: none;
  top: 0;
  transition: opacity 0.8s linear 0s;
  -webkit-transition: opacity 0.8s linear 0s;
  -ms-transition: opacity 0.8s linear 0s;
  -moz-transition: opacity 0.8s linear 0s;
  -o-transition: opacity 0.8s linear 0s;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg); }

.pcoded-navbar .sidebar_toggle a:hover {
  color: #fff; }

.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after {
  display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a {
  background: transparent !important;
  border-bottom-color: transparent !important; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  background: transparent !important; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-width: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: center;
  font-size: 24px;
  font-weight: 500; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    display: none; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
  background: transparent !important; }
  .pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
    border-left-width: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar .pcoded-item .pcoded-submenu > li.active > a {
  background: transparent !important;
  color: #FFF !important; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header.nocollapsed .pcoded-left-header {
  width: 100% !important;
  padding-left: 235px; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-nav-type="collapsed"] .pcoded-header.nocollapsed .pcoded-left-header {
  padding-left: 45px; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-effect="overlay"] .pcoded-header.nocollapsed .pcoded-left-header, .pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-nav-type="fullpage"] .pcoded-header.nocollapsed .pcoded-left-header, .pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-nav-type="offcanvas"] .pcoded-header.nocollapsed .pcoded-left-header {
  padding-left: 0; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header.nocollapsed {
  height: 100px !important; }
  .pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header.nocollapsed .pcoded-right-header {
    padding: 50px 5px 0 !important; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-effect="overlay"] .pcoded-header.nocollapsed .pcoded-right-header, .pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-nav-type="offcanvas"] .pcoded-header.nocollapsed .pcoded-right-header {
  margin: 0 !important; }
.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header.nocollapsed 
.sidebar_toggle a {
  background: transparent none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  float: left;
  font-size: 18px;
  height: 35px;
  margin-right: 5px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 6px;
  width: 40px;
  z-index: 1028; }
.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-navbar {
  top: 0;
  z-index: 1030; }
.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-main-container {
  position: unset; }
.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] #styleSelector {
  top: 100px; }
.pcoded .pcoded-navbar .pcoded-navigation-label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  opacity: 1;
  padding: 10px 25px;
  text-transform: capitalize;
  visibility: visible;
  width: 100%;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme1"] {
    color: #448aff; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme2"] {
    color: #b71c1c; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme3"] {
    color: #4CAF50; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme4"] {
    color: #d84315; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme5"] {
    color: #4527a0; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme6"] {
    color: #37474f; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme7"] {
    color: #000; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme8"] {
    color: #999; }
  .pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme9"] {
    color: #fff; }
.pcoded .pcoded-navbar .pcoded-search + .pcoded-navigation-label {
  border-top: 0 solid transparent; }
.pcoded[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-navigation-label {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear; }
.pcoded[vertical-nav-type="compact"] .pcoded-navbar .pcoded-navigation-label {
  display: none; }
.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge {
  position: absolute;
  right: 30px;
  text-align: center;
  top: 10px;
  vertical-align: middle;
  text-transform: lowercase;
  white-space: nowrap;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear; }
.pcoded .pcoded-navbar .pcoded-item .pcoded-submenu > li a .pcoded-badge {
  top: 10px;
  right: 2px; }
.pcoded[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a .pcoded-badge {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear; }
.pcoded[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-badge {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .card .card-header h5:after {
  right: -30px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.active > a:before {
  left: auto;
  right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 0 solid transparent;
  border-left: 0 solid #e9e9e9;
  border-top: 0 solid transparent;
  left: 0;
  right: auto;
  top: 4px;
  border-right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after {
  left: 8px;
  right: auto;
  top: -1px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  left: auto;
  right: 0;
  padding-right: 30px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item li a .pcoded-badge {
  position: relative;
  right: 30px;
  top: -5px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li:hover > a:before {
  left: auto;
  right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  left: auto;
  right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: right; }
  .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    float: right;
    left: 6px;
    top: 3px;
    padding-right: 10px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item li .pcoded-submenu li > a .pcoded-badge {
  left: 8px;
  position: absolute;
  right: auto;
  top: 11px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-navigation-label {
  text-align: right;
  padding: 10px 15px 2px 20px; }
  .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-navigation-label:before {
    left: auto;
    right: 20px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon="style1"] > a:after {
  content: "\eb8a";
  font-family: 'icofont'; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style1"] > a:after {
  content: "\eb89";
  font-family: 'icofont'; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: auto;
  right: 100%; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu > a:after {
  left: 7px;
  right: auto;
  top: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger {
  left: auto;
  right: 210px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: auto; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item li a .pcoded-badge {
  position: absolute;
  right: auto;
  top: 10px; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-header .pcoded-right-header .pcoded-rl-header {
  float: right; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-header .pcoded-right-header .pcoded-rr-header {
  float: left; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-header .sidebar_toggle {
  float: right; }
.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-search.open .pcoded-search-box {
  right: 0; }
.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: right; }
  .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    padding-left: 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

body[themebg-pattern="theme1"] {
  background-color: #448aff; }
body[themebg-pattern="theme2"] {
  background-color: #b71c1c; }
body[themebg-pattern="theme3"] {
  background-color: #4CAF50; }
body[themebg-pattern="theme4"] {
  background-color: #d84315; }
body[themebg-pattern="theme5"] {
  background-color: #4527a0; }
body[themebg-pattern="theme6"] {
  background-color: #37474f; }

.pcoded .pcoded-navbar .pcoded-header[header-theme="theme1"] {
  color: #fff; }
  .pcoded .pcoded-navbar .pcoded-header[header-theme="theme1"] 
  
  .sidebar_toggle a {
    background: transparent;
    color: #fff;
    border-color: #fff; }
.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme="theme1"] {
  background: transparent; }
.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme="theme1"] {
  background: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .main-menu {
  background-color: #37474f; }
  .pcoded .pcoded-navbar[navbar-theme="theme1"] .main-menu .main-menu-header {
    background-color: #37474f; }
    .pcoded .pcoded-navbar[navbar-theme="theme1"] .main-menu .main-menu-header .user-details span {
      color: #fff; }
  .pcoded .pcoded-navbar[navbar-theme="theme1"] .main-menu .main-menu-content .more-details a {
    color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a {
  color: #fff; }
  .pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a .pcoded-micon {
    color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.active > a {
  color: #FFF;
  border-bottom-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a {
  border-bottom-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
  background: #37474f; }
  .pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-color: #4c626d;
    color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu > a:after,
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
  color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after,
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after {
  color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.active:hover > a, .pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li:hover > a {
  color: #FFF; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
  background-color: #37474f;
  color: #FFF; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-color: transparent; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a {
  color: #FFF;
  background-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:before {
  border-bottom-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:after {
  border-left-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  border-bottom-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:after {
  border-left-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li:hover > a:before {
  border-left-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu:hover > a:after {
  color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-trigger > a {
  border-bottom-color: #37474f; }
.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover > a:after {
  color: #fff; }
.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: transparent; }
.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret {
  border-right-color: #37474f !important; }
.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: transparent; }
.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] {
  background-color: #37474f; }
  .pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li:hover > a {
    color: #FFF; }
  .pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] .sidebar_toggle a {
    color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .main-menu {
  background-color: #fff; }
  .pcoded .pcoded-navbar[navbar-theme="themelight1"] .main-menu .main-menu-header {
    background-color: #fff; }
    .pcoded .pcoded-navbar[navbar-theme="themelight1"] .main-menu .main-menu-header .user-details span {
      color: #fff; }
  .pcoded .pcoded-navbar[navbar-theme="themelight1"] .main-menu .main-menu-content .more-details a {
    color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
  background: #fff; }
  .pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-color: #e6e6e6;
    color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-hasmenu > a:after,
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
  color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after,
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after {
  color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.active:hover > a, .pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li:hover > a {
  color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
  color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a {
  color: #666; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:before {
  border-bottom-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:after {
  border-left-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  border-bottom-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:after {
  border-left-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-trigger > a {
  border-bottom-color: #fff; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li > a {
  color: #666 !important; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.active > a {
  color: #FFF; }
.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li > a {
  border-bottom-color: #fff; }
.pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #448aff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a {
  color: #448aff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a .pcoded-micon {
    color: #448aff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a {
  background: #448aff;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme1"] .searchbar-toggle {
  color: #448aff; }
.pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #b71c1c !important; }
.pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a {
  color: #b71c1c !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a .pcoded-micon {
    color: #b71c1c !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a {
  background: #b71c1c;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme2"] .searchbar-toggle {
  color: #b71c1c; }
.pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #4CAF50 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a {
  color: #4CAF50 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a .pcoded-micon {
    color: #4CAF50 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a {
  background: #4CAF50;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme3"] .searchbar-toggle {
  color: #4CAF50; }
.pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #d84315 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a {
  color: #d84315 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a .pcoded-micon {
    color: #d84315 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a {
  background: #d84315;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme4"] .searchbar-toggle {
  color: #d84315; }
.pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #4527a0 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a {
  color: #4527a0 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a .pcoded-micon {
    color: #4527a0 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a {
  background: #4527a0;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme5"] 
  .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme5"] .searchbar-toggle {
  color: #4527a0; }
.pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #37474f !important; }
.pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a {
  color: #37474f !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a .pcoded-micon {
    color: #37474f !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a {
  background: #37474f;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme6"] .searchbar-toggle {
  color: #37474f; }
.pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #11c788 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a {
  color: #11c788 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a .pcoded-micon {
    color: #11c788 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a {
  background: #11c788;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme7"] .searchbar-toggle {
  color: #11c788; }
.pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #FF5252 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a {
  color: #FF5252 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a .pcoded-micon {
    color: #FF5252 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a {
  background: #FF5252;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme8"] .searchbar-toggle {
  color: #FF5252; }
.pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #ff784f !important; }
.pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a {
  color: #ff784f !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a .pcoded-micon {
    color: #ff784f !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a {
  background: #ff784f;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme9"] .searchbar-toggle {
  color: #ff784f; }
.pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #38a9f9 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a {
  color: #38a9f9 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a .pcoded-micon {
    color: #38a9f9 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a {
  background: #38a9f9;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme10"] .searchbar-toggle {
  color: #38a9f9; }
.pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #038f9e !important; }
.pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a {
  color: #038f9e !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a .pcoded-micon {
    color: #038f9e !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a {
  background: #038f9e;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme11"] .searchbar-toggle {
  color: #038f9e; }
.pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li .pcoded-submenu li.active > a,
.pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #39ADB5 !important; }
.pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 500; }
.pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a {
  color: #39ADB5 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a .pcoded-micon {
    color: #39ADB5 !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a:before {
    border-left-color: transparent; }
.pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a {
  background: #39ADB5;
  color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a .pcoded-micon {
    color: #fff !important; }
  .pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a:before {
    border-left-color: transparent; }
  .pcoded .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a:after {
    color: #fff !important; }
.pcoded .pcoded-navbar[active-item-theme="theme12"] .searchbar-toggle {
  color: #39ADB5; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li > a {
  border-bottom-color: #e6e6e6; }
.pcoded[theme-layout="vertical"][vertical-nav-type="compact"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a {
  border-bottom-color: #4c626d; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: #ededed;
  -webkit-box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
          box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13); }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: #465a64;
  -webkit-box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
          box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13); }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-trigger > a {
  border-bottom: 1px solid #4c626d;
  border-top: 1px solid #4c626d; }
.pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="desktop"] .pcoded-navbar .main-menu, .pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="tablet"] .pcoded-navbar .main-menu {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0; }
.pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="desktop"] .pcoded-navbar .profile-box, .pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="tablet"] .pcoded-navbar .profile-box {
  border-radius: 4px 4px 0 0; }
.pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="desktop"] .pcoded-header, .pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="desktop"] .pcoded-main-container, .pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="tablet"] .pcoded-header, .pcoded[theme-layout="vertical"][vnavigation-view="view2"][pcoded-device-type="tablet"] .pcoded-main-container {
  padding-left: 15px; }
.pcoded[theme-layout="vertical"][vnavigation-view="view2"][vertical-layout="widebox"][pcoded-device-type="desktop"] .pcoded-header .pcoded-wrapper, .pcoded[theme-layout="vertical"][vnavigation-view="view2"][vertical-layout="widebox"][pcoded-device-type="tablet"] .pcoded-header .pcoded-wrapper {
  padding-left: 5px; }
.pcoded[theme-layout="vertical"][vnavigation-view="view3"] .pcoded-navbar {
  top: 0; }
.pcoded[theme-layout="vertical"][vnavigation-view="view3"] .pcoded-main-container {
  position: unset; }
.pcoded[theme-layout="vertical"][vnavigation-view="view3"] .pcoded-header .pcoded-left-header {
  display: none; }
.pcoded #styleSelector {
  min-height: 300px;
  height: calc(100vh - 56px);
  border: 1px solid #e9e9e9;
  background: #FFF;
  position: fixed;
  margin: 0;
  padding: 20px;
  width: 400px;
  top: 56px;
  z-index: 100;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -o-transition: 0.5s; }
  .pcoded #styleSelector.open {
    background: #FFF none repeat scroll 0 0;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .pcoded #styleSelector a > i {
    padding: 0;
    margin: 0 5px 0 0; }
  .pcoded #styleSelector .soc-icon {
    width: 55px;
    height: 55px;
    padding: 15px;
    border-radius: 50%; }
    .pcoded #styleSelector .soc-icon i {
      font-size: 25px;
      margin-right: 0; }
  @media only screen and (max-width: 992px) {
    .pcoded #styleSelector {
      display: none; } }
.pcoded[theme-layout="horizontal"] #styleSelector, .pcoded[vertical-placement="left"] #styleSelector {
  right: -400px;
  border-left: 2px solid rgba(48, 53, 73, 0.28); }
  .pcoded[theme-layout="horizontal"] #styleSelector.open, .pcoded[vertical-placement="left"] #styleSelector.open {
    right: 0; }
.pcoded[vertical-placement="right"] #styleSelector {
  left: -400px;
  border-right: 2px solid rgba(48, 53, 73, 0.28); }
  .pcoded[vertical-placement="right"] #styleSelector.open {
    left: 0; }

.selector-toggle {
  position: relative; }

.pcoded .selector-toggle > a {
  position: absolute;
  top: 200px;
  width: 60px;
  height: 60px;
  display: block;
  cursor: pointer;
  text-align: center;
  background: #448aff;
  color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 14px 10px; }
.pcoded[theme-layout="horizontal"] .selector-toggle > a, .pcoded[vertical-placement="left"] .selector-toggle > a {
  left: -80px;
  border-right: 0;
  border-radius: 50% 0 0 50%; }
.pcoded[vertical-placement="right"] .selector-toggle > a {
  right: -80px;
  border-left: 0;
  border-radius: 0 50% 50% 0; }

.selector-toggle > a:before {
  font-family: 'themify';
  font-size: 20px;
  content: "\e60f";
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-animation: mymove 1.3s infinite linear;
          animation: mymove 1.3s infinite linear; }

.open .selector-toggle > a:before {
  font-family: 'themify';
  font-size: 20px;
  content: "\e646";
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-animation: mymove 0s infinite linear;
          animation: mymove 0s infinite linear; }

#styleSelector .nav-tabs {
  margin-bottom: 20px;
  border-bottom: 2px solid #448aff;
  padding-bottom: 5px; }
  #styleSelector .nav-tabs .nav-item {
    width: calc(100% / 2);
    margin: 0;
    padding: 0;
    float: left; }
    #styleSelector .nav-tabs .nav-item a {
      color: #37474f;
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      padding: 0.66rem 1rem;
      border-radius: 2px;
      border: none;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      #styleSelector .nav-tabs .nav-item a.active {
        color: #fff;
        background: #448aff; }
#styleSelector .style-cont {
  background-color: #F6F7FB;
  padding: 10px; }
#styleSelector .st-main-title {
  font-size: 18px; }
#styleSelector .btn-success {
  background-color: #11c15b; }
#styleSelector .checkbox-fade label {
  margin-bottom: 0.8rem; }
#styleSelector .sub-title {
  text-transform: capitalize; }
#styleSelector #menu-effect {
  padding-bottom: 15px; }
  #styleSelector #menu-effect .radio > label {
    padding-left: 0; }
  #styleSelector #menu-effect .radio .helper {
    bottom: -30px;
    left: 3px; }
    #styleSelector #menu-effect .radio .helper:after, #styleSelector #menu-effect .radio .helper:before {
      top: auto;
      bottom: 0; }
  #styleSelector #menu-effect .micon {
    font-size: 15px;
    padding: 4px;
    border-radius: 4px;
    width: 30px;
    display: block;
    height: 30px;
    text-align: center; }
    #styleSelector #menu-effect .micon.st1 {
      background-color: transparent; }
      #styleSelector #menu-effect .micon.st1 i {
        color: #448aff; }
    #styleSelector #menu-effect .micon.st2 {
      background-color: transparent; }
      #styleSelector #menu-effect .micon.st2 i {
        color: #37474f; }
#styleSelector #bg-pattern-visiblity {
  padding-left: 30px; }
#styleSelector .form-control {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #f1f1f1;
  margin-bottom: 10px; }
  #styleSelector .form-control:focus {
    border: none;
    background-color: #f1f1f1; }
#styleSelector ul {
  border: 0 none;
  margin: 0;
  padding: 0;
  width: 100%; }
  #styleSelector ul li {
    border-bottom: 0 solid #e9e9e9;
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%; }
    #styleSelector ul li p.selector-title,
    #styleSelector ul li span.selector-title {
      color: #444;
      font-size: 14px;
      margin: 0 0 15px;
      padding: 10px 0;
      text-align: left; }
    #styleSelector ul li p.selector-title {
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9; }
      #styleSelector ul li p.selector-title.main-title {
        font-weight: 500; }
    #styleSelector ul li p.sub-title.drp-title {
      border-bottom: 0 solid transparent;
      margin-bottom: 0;
      margin-top: 10px; }
#styleSelector > ul > li > .sub-title {
  color: #9c9c9c;
  display: block;
  font-size: 13px;
  margin: 0;
  padding: 5px 0;
  position: relative;
  text-align: left; }
#styleSelector li {
  padding: 5px; }

.theme-option select {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px 10px; }

.sparkline-chart {
  text-align: center; }

.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .spark-chart, .pcoded[theme-layout="vertical"][pcoded-device-type="tablet"] .spark-chart {
  display: none; }

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
  float: none;
  position: relative;
  z-index: 1051;
  left: 90%;
  top: 1px; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li > a .pcoded-micon i {
  left: 0; }
.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a .pcoded-micon i, .pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-trigger.active > a .pcoded-micon i {
  left: 94%; }
.pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > li, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout="vertical"][vertical-nav-type="sub-expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li {
  position: static; }
.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-main-container {
  position: static; }
.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-main-container, .pcoded[theme-layout="vertical"][vnavigation-view="view3"] .pcoded-main-container {
  position: static; }
.pcoded .pcoded-header[header-theme="theme1"] {
  background: #448aff; }
  .pcoded .pcoded-header[header-theme="theme1"] .input-group-addon,
  .pcoded .pcoded-header[header-theme="theme1"] a {
    color: #fff; }
.pcoded .pcoded-header[header-theme="theme2"] {
  background: #b71c1c; }
  .pcoded .pcoded-header[header-theme="theme2"] .input-group-addon,
  .pcoded .pcoded-header[header-theme="theme2"] a {
    color: #fff; }
.pcoded .pcoded-header[header-theme="theme3"] {
  background: #4CAF50; }
  .pcoded .pcoded-header[header-theme="theme3"] .input-group-addon,
  .pcoded .pcoded-header[header-theme="theme3"] a {
    color: #fff; }
.pcoded .pcoded-header[header-theme="theme4"] {
  background:#00a5a6; }
  .pcoded .pcoded-header[header-theme="theme4"] .input-group-addon,
  .pcoded .pcoded-header[header-theme="theme4"] a {
    color: #fff; }




.pcoded .pcoded-header[header-theme="theme5"] 
{
  background: #00a6a6;

}





  .pcoded .pcoded-header[header-theme="theme5"] .input-group-addon,
  .pcoded .pcoded-header[header-theme="theme5"] a {
    color: #fff; }
.pcoded .pcoded-header[header-theme="theme6"] {
  background: #37474f; }
  .pcoded .pcoded-header[header-theme="theme6"] .input-group-addon,
  .pcoded .pcoded-header[header-theme="theme6"] a {
    color: #fff; }
.pcoded[fream-type="theme1"] .page-header:before, .pcoded[fream-type="theme1"] .main-menu .main-menu-header:before {
  background: rgba(68, 138, 255, 0.5); }
.pcoded[fream-type="theme2"] .page-header:before, .pcoded[fream-type="theme2"] .main-menu .main-menu-header:before {
  background: rgba(183, 28, 28, 0.5); }
.pcoded[fream-type="theme3"] .page-header:before, .pcoded[fream-type="theme3"] .main-menu .main-menu-header:before {
  background: rgba(76, 175, 80, 0.5); }
.pcoded[fream-type="theme4"] .page-header:before, .pcoded[fream-type="theme4"] .main-menu .main-menu-header:before {
  background: rgba(216, 67, 21, 0.5); }
.pcoded[fream-type="theme5"] .page-header:before, .pcoded[fream-type="theme5"] .main-menu .main-menu-header:before {
  background: rgba(69, 39, 160, 0.5); }
.pcoded[fream-type="theme6"] .page-header:before, .pcoded[fream-type="theme6"] .main-menu .main-menu-header:before {
  background: rgba(55, 71, 79, 0.5); }

body .pcoded[pcoded-device-type="phone"][vertical-nav-type="expanded"] .pcoded-navbar, body .pcoded[pcoded-device-type="tablet"][vertical-nav-type="expanded"] .pcoded-navbar {
  position: absolute !important; }
  body .pcoded[pcoded-device-type="phone"][vertical-nav-type="expanded"] .pcoded-navbar .pcoded-item .pcoded-hasmenu > .pcoded-submenu, body .pcoded[pcoded-device-type="tablet"][vertical-nav-type="expanded"] .pcoded-navbar .pcoded-item .pcoded-hasmenu > .pcoded-submenu {
    -webkit-transition: all 0s ease-in !important;
    transition: all 0s ease-in !important; }
body .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
  left: 0; }
body .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-micon {
  left: calc(100% - 30px); }
body .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-item > li.pcoded-trigger > a > .pcoded-mtext {
  padding-right: 90px; }
body .pcoded[vertical-placement="right"] .showChat_inner,
body .pcoded[vertical-placement="right"] .users {
  right: auto;
  left: 0; }
body .pcoded[vertical-placement="right"] .main-body {
  direction: rtl; }
  body .pcoded[vertical-placement="right"] .main-body .page-wrapper .page-header-breadcrumb {
    float: left; }
  body .pcoded[vertical-placement="right"] .main-body .language-markup {
    direction: ltr; }
body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-logo {
  float: right !important; }
body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container {
  margin-left: 0;
  margin-right: 240px; }
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-left li, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right li,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right li {
    float: right !important; }
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-left, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: right; }
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: left; }
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .profile-notification,
  body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .profile-notification {
    right: auto;
    left: 0; }
body .pcoded[vertical-placement="right"][vertical-nav-type="collapsed"] .header-navbar .navbar-wrapper .navbar-container {
  margin-right: 0; }
body .pcoded[vertical-nav-type="collapsed"] .header-navbar .navbar-wrapper .navbar-container {
  margin-left: 0; }
body .pcoded[vertical-nav-type="collapsed"] 
.pcoded-navbar .mCSB_container,
body .pcoded[vertical-nav-type="collapsed"] .pcoded-navbar 
.mCSB_container_wrapper {
  overflow: hidden !important;
  background-color: aqua;
 }
body .pcoded[vertical-nav-type="compact"] 
.main-menu {
  width: 200px; 
}
  body .pcoded[vertical-nav-type="compact"] 
  .main-menu .pcoded-item > li > .pcoded-submenu {
    -webkit-box-shadow: 0 0 16px -6px rgba(0, 0, 0, 0.57);
            box-shadow: 0 0 16px -6px rgba(0, 0, 0, 0.57); }
  body .pcoded[vertical-nav-type="compact"] 
  .main-menu .pcoded-search {
    padding: 40px 20px 20px; 
  }
  body .pcoded[vertical-nav-type="compact"]
   .main-menu .pcoded-badge {
    display: none; 
  }
  body .pcoded[vertical-nav-type="compact"] 
  .main-menu 
  .mCSB_container {
    width: 100% !important; 
    height: 100px;
  }
body.menu-bottom 
.navbar-wrapper {
  z-index: 1050; 
}
body.menu-bottom .pcoded .pcoded-main-container {
  margin-top: 0 !important; 
}
body.menu-bottom .p-chat-user,
body.menu-bottom .showChat_inner {
  top: 0; 
}
body.menu-bottom .navbar-wrapper {
  bottom: 0;
  top: auto;
  z-index: 1050; 
}
  @media only screen and (max-width: 992px) {
    body.menu-bottom .navbar-wrapper .pcoded-navbar {
      top: -56px; 
    } 
  }
body.menu-bottom .header-navbar {
  min-height: auto;
  position: fixed !important;
  bottom: 0;
  z-index: 9999 !important;
  -webkit-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
 }
body.menu-bottom .mega-menu-top .show-notification, body.menu-bottom .mega-menu-top .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification body.menu-bottom .mega-menu-top .profile-notification {
  bottom: 0 !important; }
body.menu-bottom .header-notification .show-notification, body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container body.menu-bottom .header-notification .profile-notification {
  bottom: 65px; }
body.menu-bottom #collapse-menu {
  display: none; }




.mCSB_container,
.mCustomScrollBox {
  overflow: invisible !important;
 }

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] 
.mCSB_container_wrapper {
  overflow: visible !important;
 }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(55, 71, 79, 0.5) !important; }

/**  =====================
      Theme-Buttons css start
==========================  **/
.btn-default .badge {
  background-color: #fff;
  color: #e0e0e0; }

.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer; }
  @media only screen and (max-width: 480px) {
    .btn {
      padding: 10px 15px; } }

.close {
  cursor: pointer; }

.button-page .card-block {
  margin-bottom: -20px; }
  .button-page .card-block ul {
    margin-bottom: 0;
    display: inline-block; }
  .button-page .card-block a {
    margin-bottom: 20px; }
  .button-page .card-block ul li {
    display: inline-block;
    margin-right: 20px;
    float: left; }

.button-page .btn {
  margin-bottom: 20px;
  margin-right: 10px; }

.btn.btn-round {
  border-radius: 2rem; }
.btn.btn-square {
  border-radius: 0; }
.btn.btn-skew {
  -webkit-transform: skew(-15deg);
          transform: skew(-15deg); }
.btn i {
  margin-right: 5px; }
.btn.btn-icon {
  border-radius: 50%;
  width: 40px;
  line-height: 30px;
  height: 40px;
  padding: 3px;
  text-align: center; }
.btn.btn-out {
  outline: 1px solid #fff;
  outline-offset: -5px; }
.btn.btn-out-dashed {
  outline: 1px dashed #fff;
  outline-offset: -5px; }
.btn.btn-out-dotted {
  outline: 1px dotted #fff;
  outline-offset: -5px; }

.btn-group,
.btn-group-vertical {
  display: inline-block; }

.btn-group .btn {
  float: left; }

.icon-btn i {
  margin-right: 0 !important; }

.button-page .btn-group {
  margin-right: 10px; }

.show > .dropdown-menu {
  overflow: hidden; }

.dropdown-danger,
.dropdown-default,
.dropdown-disabled,
.dropdown-info,
.dropdown-inverse,
.dropdown-primary,
.dropdown-success,
.dropdown-warning,
.dropdown-warning {
  display: inline-block;
  vertical-align: center;
  position: relative; }

.btn-group [class*=btn-],
.dropdown-split [class*=btn-] {
  margin-right: 0; }

.list-group-item.active {
  background-color: #448aff;
  border-color: #448aff; }

.show > .btn-primary.dropdown-toggle, .sweet-alert .show > button.dropdown-toggle.confirm, .wizard > .actions .show > a.dropdown-toggle {
  background-color: #0764ff !important;
  border-color: #0764ff;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff; }

#Note-list li:hover .Note-delete {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px); }
  #Note-list li:hover .Note-delete:hover {
    background: rgba(0, 0, 0, 0.8); }

.button-list .btn {
  margin-bottom: 10px;
  margin-right: 30px; }

.Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transform: translateX(10px);
          transform: translateX(10px); }

.popover-body .color-code {
  margin-bottom: 10px; }
.popover-body .display-color {
  width: 75px;
  height: 2.50rem;
  border-radius: 0.25rem;
  margin-right: 30px;
  background-color: rgba(68, 138, 255, 0.5);
  border-color: rgba(68, 138, 255, 0.5); }
.popover-body span.block {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px; }

.popover-title {
  text-align: center; }

.btn.btn-disabled,
.dropdown-disabled,
.dropdown-split-disabled {
  cursor: not-allowed; }

.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active.active,
.nav-pills .nav-link.active.active:focus,
.nav-pills .nav-link.active.active:hover {
  background: transparent;
  color: #0275d8;
  text-align: center;
  border-bottom: 1px solid #0275d8; }

/**====== Basic-Buttons css Start ======**/
.btn-primary, .sweet-alert button.confirm, .wizard > .actions a {
  background-color: #448aff;
  border-color: #448aff;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .btn-primary:hover, .sweet-alert button.confirm:hover, .wizard > .actions a:hover {
    background-color: #77aaff;
    border-color: #77aaff; }
  .btn-primary:active, .sweet-alert button.confirm:active, .wizard > .actions a:active {
    background-color: #0764ff !important;
    border-color: #0764ff;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff; }
  .btn-primary:focus, .sweet-alert button.confirm:focus, .wizard > .actions a:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    background-color: #77aaff; }
  .btn-primary.disabled, .sweet-alert button.disabled.confirm, .wizard > .actions a.disabled {
    background-color: rgba(68, 138, 255, 0.5);
    border-color: rgba(68, 138, 255, 0.5); }

.btn-warning {
  background-color: #ffe100;
  border-color: #ffe100;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .btn-warning:hover {
    background-color: #ffe733;
    border-color: #ffe733;
    color: #fff; }
  .btn-warning:active {
    background-color: #c2ab00 !important;
    border-color: #c2ab00;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff; }
  .btn-warning:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    background-color: #ffe733; }
  .btn-warning.disabled {
    background-color: rgba(255, 225, 0, 0.5);
    border-color: rgba(255, 225, 0, 0.5); }

.btn-danger {
  background-color: #ff5252;
  border-color: #ff5252;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .btn-danger:hover {
    background-color: #ff8585;
    border-color: #ff8585; }
  .btn-danger:active {
    background-color: #ff1515 !important;
    border-color: #ff1515;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff; }
  .btn-danger:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    background-color: #ff8585; }
  .btn-danger.disabled {
    background-color: rgba(255, 82, 82, 0.5);
    border-color: rgba(255, 82, 82, 0.5); }

.btn-success {
  background-color: #11c15b;
  border-color: #11c15b;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .btn-success:hover {
    background-color: #1aeb72;
    border-color: #1aeb72; }
  .btn-success:active {
    background-color: #0c8940 !important;
    border-color: #0c8940;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff; }
  .btn-success:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    background-color: #1aeb72; }
  .btn-success.disabled {
    background-color: rgba(17, 193, 91, 0.5);
    border-color: rgba(17, 193, 91, 0.5); }

.btn-inverse {
  background-color: #37474f;
  border-color: #37474f;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .btn-inverse:hover {
    background-color: #4c626d;
    border-color: #4c626d; }
  .btn-inverse:active {
    background-color: #1e272b !important;
    border-color: #1e272b;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff; }
  .btn-inverse:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    background-color: #4c626d; }
  .btn-inverse.disabled {
    background-color: rgba(55, 71, 79, 0.5);
    border-color: rgba(55, 71, 79, 0.5); }

.btn-info {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .btn-info:hover {
    background-color: #08e3ff;
    border-color: #08e3ff; }
  .btn-info:active {
    background-color: #008697 !important;
    border-color: #008697;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff; }
  .btn-info:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    background-color: #08e3ff; }
  .btn-info.disabled {
    background-color: rgba(0, 188, 212, 0.5);
    border-color: rgba(0, 188, 212, 0.5); }

.btn-disabled {
  background-color: rgba(68, 138, 255, 0.5);
  border-color: rgba(68, 138, 255, 0.5);
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }

/**====== Basic-Buttons css end ======**/
/**====== Outline-Buttons css Start ======**/
.btn-outline-primary {
  color: #0764ff;
  background-color: #fff;
  background-color: transparent; }

.btn-outline-warning {
  color: #c2ab00;
  background-color: #fff;
  background-color: transparent; }

.btn-outline-danger {
  color: #ff1515;
  background-color: #fff;
  background-color: transparent; }

.btn-outline-success {
  color: #0c8940;
  background-color: #fff;
  background-color: transparent; }

.btn-outline-inverse {
  color: #1e272b;
  background-color: #fff;
  background-color: transparent; }
  .btn-outline-inverse:hover {
    color: #fff; }

.btn-outline-warning {
  color: #c2ab00;
  background-color: #fff;
  background-color: transparent; }

.btn-outline-info {
  color: #008697;
  background-color: #fff;
  background-color: transparent; }

.btn-outline-disabled {
  color: #0764ff;
  background-color: #fff; }

/**====== Outline-Buttons css end ======**/
/**====== gradient css Start ======**/
.btn-grd-danger,
.btn-grd-disabled,
.btn-grd-info,
.btn-grd-inverse,
.btn-grd-primary,
.btn-grd-success,
.btn-grd-warning,
.btn-grd-warning {
  background-size: 200% auto;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: #fff; }
  .btn-grd-danger:hover,
  .btn-grd-disabled:hover,
  .btn-grd-info:hover,
  .btn-grd-inverse:hover,
  .btn-grd-primary:hover,
  .btn-grd-success:hover,
  .btn-grd-warning:hover,
  .btn-grd-warning:hover {
    background-position: right center; }
  .btn-grd-danger.hor-grd,
  .btn-grd-disabled.hor-grd,
  .btn-grd-info.hor-grd,
  .btn-grd-inverse.hor-grd,
  .btn-grd-primary.hor-grd,
  .btn-grd-success.hor-grd,
  .btn-grd-warning.hor-grd,
  .btn-grd-warning.hor-grd {
    background-size: auto 200%; }
    .btn-grd-danger.hor-grd:hover,
    .btn-grd-disabled.hor-grd:hover,
    .btn-grd-info.hor-grd:hover,
    .btn-grd-inverse.hor-grd:hover,
    .btn-grd-primary.hor-grd:hover,
    .btn-grd-success.hor-grd:hover,
    .btn-grd-warning.hor-grd:hover,
    .btn-grd-warning.hor-grd:hover {
      background-position: bottom center; }

.btn-grd-primary {
  background-image: -webkit-gradient(linear, left top, right top, from(#77aaff), color-stop(51%, #0764ff), to(#77aaff));
  background-image: linear-gradient(to right, #77aaff 0%, #0764ff 51%, #77aaff 100%); }
  .btn-grd-primary.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#77aaff), color-stop(51%, #0764ff), to(#77aaff));
    background-image: linear-gradient(to top, #77aaff 0%, #0764ff 51%, #77aaff 100%); }

.btn-grd-warning {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffe733), color-stop(51%, #c2ab00), to(#ffe733));
  background-image: linear-gradient(to right, #ffe733 0%, #c2ab00 51%, #ffe733 100%); }
  .btn-grd-warning.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ffe733), color-stop(51%, #c2ab00), to(#ffe733));
    background-image: linear-gradient(to top, #ffe733 0%, #c2ab00 51%, #ffe733 100%); }

.btn-grd-danger {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff8585), color-stop(51%, #ff1515), to(#ff8585));
  background-image: linear-gradient(to right, #ff8585 0%, #ff1515 51%, #ff8585 100%); }
  .btn-grd-danger.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ff8585), color-stop(51%, #ff1515), to(#ff8585));
    background-image: linear-gradient(to top, #ff8585 0%, #ff1515 51%, #ff8585 100%); }

.btn-grd-success {
  background-image: -webkit-gradient(linear, left top, right top, from(#1aeb72), color-stop(51%, #0c8940), to(#1aeb72));
  background-image: linear-gradient(to right, #1aeb72 0%, #0c8940 51%, #1aeb72 100%); }
  .btn-grd-success.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#1aeb72), color-stop(51%, #0c8940), to(#1aeb72));
    background-image: linear-gradient(to top, #1aeb72 0%, #0c8940 51%, #1aeb72 100%); }

.btn-grd-inverse {
  background-image: -webkit-gradient(linear, left top, right top, from(#4c626d), color-stop(51%, #1e272b), to(#4c626d));
  background-image: linear-gradient(to right, #4c626d 0%, #1e272b 51%, #4c626d 100%); }
  .btn-grd-inverse.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#4c626d), color-stop(51%, #1e272b), to(#4c626d));
    background-image: linear-gradient(to top, #4c626d 0%, #1e272b 51%, #4c626d 100%); }

.btn-grd-info {
  background-image: -webkit-gradient(linear, left top, right top, from(#08e3ff), color-stop(51%, #008697), to(#08e3ff));
  background-image: linear-gradient(to right, #08e3ff 0%, #008697 51%, #08e3ff 100%); }
  .btn-grd-info.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#08e3ff), color-stop(51%, #008697), to(#08e3ff));
    background-image: linear-gradient(to top, #08e3ff 0%, #008697 51%, #08e3ff 100%); }

.btn-grd-disabled {
  background-image: -webkit-gradient(linear, left top, right top, from(#77aaff), color-stop(51%, #0764ff), to(#77aaff));
  background-image: linear-gradient(to right, #77aaff 0%, #0764ff 51%, #77aaff 100%); }
  .btn-grd-disabled.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#77aaff), color-stop(51%, #0764ff), to(#77aaff));
    background-image: linear-gradient(to top, #77aaff 0%, #0764ff 51%, #77aaff 100%); }

/**====== gradient-Buttons css end ======**/
/**====== matrialized-Buttons css Start ======**/
.btn.btn-mat {
  position: relative;
  border-radius: 0;
  border: none; }
  .btn.btn-mat:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    height: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .btn.btn-mat:hover {
    border: none; }
    .btn.btn-mat:hover:before {
      left: 0;
      right: 0; }

.btn-primary.btn-mat, .sweet-alert button.btn-mat.confirm, .wizard > .actions a.btn-mat {
  background-color: #448aff; }
  .btn-primary.btn-mat:before, .sweet-alert button.btn-mat.confirm:before, .wizard > .actions a.btn-mat:before {
    background-color: #0764ff; }

.btn-warning.btn-mat {
  background-color: #ffe100; }
  .btn-warning.btn-mat:before {
    background-color: #c2ab00; }

.btn-danger.btn-mat {
  background-color: #ff5252; }
  .btn-danger.btn-mat:before {
    background-color: #ff1515; }

.btn-success.btn-mat {
  background-color: #11c15b; }
  .btn-success.btn-mat:before {
    background-color: #0c8940; }

.btn-inverse.btn-mat {
  background-color: #37474f; }
  .btn-inverse.btn-mat:before {
    background-color: #1e272b; }

.btn-info.btn-mat {
  background-color: #00bcd4; }
  .btn-info.btn-mat:before {
    background-color: #008697; }

.btn-outline-disabled {
  background-color: #448aff; }
  .btn-outline-disabled:before {
    background-color: #0764ff; }

/**====== matrialized css end ======**/
/**====== Button-Size css start ======**/
.btn-xlg {
  padding: 14px 20px;
  font-size: 21px;
  line-height: 40px; }

.btn-lg {
  padding: 12px 18px;
  font-size: 18px;
  line-height: 32px; }

.btn-md {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 23px; }

.btn-sm {
  padding: 8px 14px;
  line-height: 16px;
  font-size: 11px; }

.btn-mini {
  padding: 5px 10px;
  line-height: 14px;
  font-size: 10px; }

/**====== Button-Size css end ======**/
/**====== Badges-button css start ======**/
.btn-primary .badge, .sweet-alert button.confirm .badge, .wizard > .actions a .badge {
  color: #448aff;
  background-color: #fff; }

.btn-success .badge {
  color: #11c15b;
  background-color: #fff; }

.btn-info .badge {
  color: #00bcd4;
  background-color: #fff; }

.btn-warning .badge {
  color: #ffe100;
  background-color: #fff; }

.btn-danger .badge {
  color: #ff5252;
  background-color: #fff; }

/**====== Badges-button css end ======**/
/**====== Social-Buttons css start ======**/
.btn-dribbble,
.btn-dropbox,
.btn-facebook,
.btn-flickr,
.btn-github,
.btn-google-plus,
.btn-instagram,
.btn-linkedin,
.btn-pinterest,
.btn-skype,
.btn-tumblr,
.btn-twitter,
.btn-youtube {
  color: #fff; }
  .btn-dribbble:focus, .btn-dribbble:hover,
  .btn-dropbox:focus,
  .btn-dropbox:hover,
  .btn-facebook:focus,
  .btn-facebook:hover,
  .btn-flickr:focus,
  .btn-flickr:hover,
  .btn-github:focus,
  .btn-github:hover,
  .btn-google-plus:focus,
  .btn-google-plus:hover,
  .btn-instagram:focus,
  .btn-instagram:hover,
  .btn-linkedin:focus,
  .btn-linkedin:hover,
  .btn-pinterest:focus,
  .btn-pinterest:hover,
  .btn-skype:focus,
  .btn-skype:hover,
  .btn-tumblr:focus,
  .btn-tumblr:hover,
  .btn-twitter:focus,
  .btn-twitter:hover,
  .btn-youtube:focus,
  .btn-youtube:hover {
    color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none; }

.btn-facebook {
  background-color: #3b5998; }

.btn-twitter {
  background-color: #00ACED; }

.btn-linkedin {
  background-color: #007BB6; }

.btn-dribbble {
  background-color: #EA4C89; }

.btn-google-plus {
  background-color: #DD4B39; }

.btn-instagram {
  background-color: #517FA4; }

.btn-pinterest {
  background-color: #CB2027; }

.btn-dropbox {
  background-color: #32506D; }

.btn-tumblr {
  background-color: #00ACED; }

/**====== Social-Buttons css end ======**/
/**====== Drop-down-Buttons css start ======**/
.dropdown-primary .dropdown-menu a:hover,
.dropdown-split-primary .dropdown-menu a:hover {
  background-color: #448aff;
  color: #fff; }

.dropdown-split-success .dropdown-menu a:hover,
.dropdown-success .dropdown-menu a:hover {
  background-color: #11c15b;
  color: #fff; }

.dropdown-info .dropdown-menu a:hover,
.dropdown-split-info .dropdown-menu a:hover {
  background-color: #00bcd4;
  color: #fff; }

.dropdown-split-warning .dropdown-menu a:hover,
.dropdown-warning .dropdown-menu a:hover {
  background-color: #ffe100;
  color: #fff; }

.dropdown-danger .dropdown-menu a:hover,
.dropdown-split-danger .dropdown-menu a:hover {
  background-color: #ff5252;
  color: #fff; }

.dropdown-inverse .dropdown-item,
.dropdown-split-inverse .dropdown-item {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; }
.dropdown-inverse .dropdown-item:hover,
.dropdown-split-inverse .dropdown-item:hover {
  background-color: #37474f;
  color: #fff; }

/**====== Drop-down-Buttons css end ======**/
/**====== Theme-Buttons css end ======**/
/**  =====================
      Basic form elements css start
==========================  **/
.col-form-label {
  font-size: 14px; }

.select2-container {
  width: 100% !important; }

.form-group {
  margin-bottom: 1.25em; }
  .form-group .form-control-lg {
    font-size: 1.25em; }
  .form-group .form-control-round {
    border-radius: 50px; }
  .form-group .form-control-static:focus {
    outline: none; }

.form-control {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #cccccc; }
  .form-control:focus {
    border: 1px solid #448aff; }
  .form-control.form-control-normal {
    font-weight: 400; }
  .form-control.form-control-bold {
    font-weight: 600; }
  .form-control.form-control-capitalize {
    text-transform: capitalize; }
  .form-control.form-control-uppercase {
    text-transform: uppercase; }
  .form-control.form-control-lowercase {
    text-transform: lowercase; }
  .form-control.form-control-variant {
    font-variant: small-caps; }
  .form-control.form-control-left {
    text-align: left; }
  .form-control.form-control-center {
    text-align: center; }
  .form-control.form-control-right {
    text-align: right; }
  .form-control.form-control-rtl {
    direction: rtl; }
  .form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5; }

/*===== Border Inputs start =====*/
.form-control-primary {
  border-color: #448aff;
  color: #448aff; }
  .form-control-primary:focus {
    border-color: #448aff; }

.input-group-primary .input-group-addon {
  background-color: #448aff; }
.input-group-primary .form-control {
  border-color: #448aff; }

.form-control-warning {
  border-color: #ffe100;
  color: #ffe100; }
  .form-control-warning:focus {
    border-color: #ffe100; }

.input-group-warning .input-group-addon {
  background-color: #ffe100; }
.input-group-warning .form-control {
  border-color: #ffe100; }

.form-control-default {
  border-color: #d6d6d6;
  color: #d6d6d6; }
  .form-control-default:focus {
    border-color: #d6d6d6; }

.input-group-default .input-group-addon {
  background-color: #d6d6d6; }
.input-group-default .form-control {
  border-color: #d6d6d6; }

.form-control-danger {
  border-color: #ff5252;
  color: #ff5252; }
  .form-control-danger:focus {
    border-color: #ff5252; }

.input-group-danger .input-group-addon {
  background-color: #ff5252; }
.input-group-danger .form-control {
  border-color: #ff5252; }

.form-control-success {
  border-color: #11c15b;
  color: #11c15b; }
  .form-control-success:focus {
    border-color: #11c15b; }

.input-group-success .input-group-addon {
  background-color: #11c15b; }
.input-group-success .form-control {
  border-color: #11c15b; }

.form-control-inverse {
  border-color: #37474f;
  color: #37474f; }
  .form-control-inverse:focus {
    border-color: #37474f; }

.input-group-inverse .input-group-addon {
  background-color: #37474f; }
.input-group-inverse .form-control {
  border-color: #37474f; }

.form-control-info {
  border-color: #00bcd4;
  color: #00bcd4; }
  .form-control-info:focus {
    border-color: #00bcd4; }

.input-group-info .input-group-addon {
  background-color: #00bcd4; }
.input-group-info .form-control {
  border-color: #00bcd4; }

/*===== Form Input-Text start =====*/
.form-txt-primary {
  color: #448aff; }
  .form-txt-primary:focus {
    color: #448aff; }
  .form-txt-primary::-moz-placeholder {
    color: #448aff;
    opacity: 1; }
  .form-txt-primary:-ms-input-placeholder {
    color: #448aff; }
  .form-txt-primary::-webkit-input-placeholder {
    color: #448aff; }

.form-txt-warning {
  color: #ffe100; }
  .form-txt-warning:focus {
    color: #ffe100; }
  .form-txt-warning::-moz-placeholder {
    color: #ffe100;
    opacity: 1; }
  .form-txt-warning:-ms-input-placeholder {
    color: #ffe100; }
  .form-txt-warning::-webkit-input-placeholder {
    color: #ffe100; }

.form-txt-default {
  color: #d6d6d6; }
  .form-txt-default:focus {
    color: #d6d6d6; }
  .form-txt-default::-moz-placeholder {
    color: #d6d6d6;
    opacity: 1; }
  .form-txt-default:-ms-input-placeholder {
    color: #d6d6d6; }
  .form-txt-default::-webkit-input-placeholder {
    color: #d6d6d6; }

.form-txt-danger {
  color: #ff5252; }
  .form-txt-danger:focus {
    color: #ff5252; }
  .form-txt-danger::-moz-placeholder {
    color: #ff5252;
    opacity: 1; }
  .form-txt-danger:-ms-input-placeholder {
    color: #ff5252; }
  .form-txt-danger::-webkit-input-placeholder {
    color: #ff5252; }

.form-txt-success {
  color: #11c15b; }
  .form-txt-success:focus {
    color: #11c15b; }
  .form-txt-success::-moz-placeholder {
    color: #11c15b;
    opacity: 1; }
  .form-txt-success:-ms-input-placeholder {
    color: #11c15b; }
  .form-txt-success::-webkit-input-placeholder {
    color: #11c15b; }

.form-txt-inverse {
  color: #37474f; }
  .form-txt-inverse:focus {
    color: #37474f; }
  .form-txt-inverse::-moz-placeholder {
    color: #37474f;
    opacity: 1; }
  .form-txt-inverse:-ms-input-placeholder {
    color: #37474f; }
  .form-txt-inverse::-webkit-input-placeholder {
    color: #37474f; }

.form-txt-info {
  color: #00bcd4; }
  .form-txt-info:focus {
    color: #00bcd4; }
  .form-txt-info::-moz-placeholder {
    color: #00bcd4;
    opacity: 1; }
  .form-txt-info:-ms-input-placeholder {
    color: #00bcd4; }
  .form-txt-info::-webkit-input-placeholder {
    color: #00bcd4; }

/*===== Form Background-inputs start =====*/
.form-bg-primary {
  background-color: #448aff;
  border-color: #448aff;
  color: #fff; }
  .form-bg-primary:focus {
    background-color: #448aff;
    border-color: #448aff;
    color: #fff; }
  .form-bg-primary::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-primary:-ms-input-placeholder {
    color: #fff; }
  .form-bg-primary::-webkit-input-placeholder {
    color: #fff; }

.form-bg-warning {
  background-color: #ffe100;
  border-color: #ffe100;
  color: #fff; }
  .form-bg-warning:focus {
    background-color: #ffe100;
    border-color: #ffe100;
    color: #fff; }
  .form-bg-warning::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-warning:-ms-input-placeholder {
    color: #fff; }
  .form-bg-warning::-webkit-input-placeholder {
    color: #fff; }

.form-bg-default {
  background-color: #d6d6d6;
  border-color: #d6d6d6;
  color: #fff; }
  .form-bg-default:focus {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #fff; }
  .form-bg-default::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-default:-ms-input-placeholder {
    color: #fff; }
  .form-bg-default::-webkit-input-placeholder {
    color: #fff; }

.form-bg-danger {
  background-color: #ff5252;
  border-color: #ff5252;
  color: #fff; }
  .form-bg-danger:focus {
    background-color: #ff5252;
    border-color: #ff5252;
    color: #fff; }
  .form-bg-danger::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-danger:-ms-input-placeholder {
    color: #fff; }
  .form-bg-danger::-webkit-input-placeholder {
    color: #fff; }

.form-bg-success {
  background-color: #11c15b;
  border-color: #11c15b;
  color: #fff; }
  .form-bg-success:focus {
    background-color: #11c15b;
    border-color: #11c15b;
    color: #fff; }
  .form-bg-success::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-success:-ms-input-placeholder {
    color: #fff; }
  .form-bg-success::-webkit-input-placeholder {
    color: #fff; }

.form-bg-inverse {
  background-color: #37474f;
  border-color: #37474f;
  color: #fff; }
  .form-bg-inverse:focus {
    background-color: #37474f;
    border-color: #37474f;
    color: #fff; }
  .form-bg-inverse::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-inverse:-ms-input-placeholder {
    color: #fff; }
  .form-bg-inverse::-webkit-input-placeholder {
    color: #fff; }

.form-bg-info {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff; }
  .form-bg-info:focus {
    background-color: #00bcd4;
    border-color: #00bcd4;
    color: #fff; }
  .form-bg-info::-moz-placeholder {
    color: #fff;
    opacity: 1; }
  .form-bg-info:-ms-input-placeholder {
    color: #fff; }
  .form-bg-info::-webkit-input-placeholder {
    color: #fff; }

/*===== Form Validation states start =====*/
.has-success .col-form-label {
  color: #11c15b; }
.has-success .form-control-success {
  border-color: #11c15b; }

.has-warning .col-form-label {
  color: #ffe100; }
.has-warning .form-control-success {
  border-color: #ffe100; }

.has-danger .col-form-label {
  color: #ff5252; }
.has-danger .form-control-success {
  border-color: #ff5252; }

/*===== Form-icon css starts =====*/
.left-icon-control {
  position: relative; }
  .left-icon-control input {
    padding-left: 50px; }
  .left-icon-control .form-icon {
    position: absolute;
    top: 50%;
    left: 17px;
    margin-top: -12px; }

.right-icon-control {
  position: relative; }
  .right-icon-control input {
    padding-right: 50px; }
  .right-icon-control .form-icon {
    position: absolute;
    bottom: 8px;
    right: 8px; }
    .right-icon-control .form-icon .btn.btn-icon {
      width: 35px;
      height: 35px;
      padding: 0; }
  .right-icon-control .btn > i {
    margin-right: 0; }

/*===== Form-icon css ends =====*/
/*===== Basic form elements ends =====*/
/**  =====================
      Form group-add-on css start
==========================  **/
.input-group {
  margin-bottom: 1.25em; }

.input-group-addon {
  background-color: #448aff;
  color: #fff; }

.input-group-button .input-group-addon {
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
  .input-group-button .input-group-addon:hover {
    background-color: #77aaff;
    border-color: #77aaff; }
    .input-group-button .input-group-addon:hover .btn {
      background-color: #77aaff;
      border-color: #77aaff; }

.input-group-dropdown [class*=btn-] {
  margin-bottom: 0; }

/*===== Form group-add-on ends =====*/
/**  =====================
      Switches css start
==========================  **/
.js-dynamic-disable,
.js-dynamic-enable {
  vertical-align: inherit; }

/*===== Switches css ends =====*/
/**  =====================
      Radio-button css start
==========================  **/
.form-radio {
  position: relative; }
  .form-radio .form-help {
    position: absolute;
    width: 100%; }
  .form-radio label {
    position: relative;
    padding-left: 1.5rem;
    text-align: left;
    color: #333;
    display: block;
    line-height: 1.8; }
  .form-radio input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0; }

.radio .helper {
  position: absolute;
  top: -0.15rem;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999; }
  .radio .helper::after {
    -webkit-transform: scale(0);
            transform: scale(0);
    background-color: #448aff;
    border-color: #448aff; }
  .radio .helper::after, .radio .helper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    margin: 0.25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transition: -webkit-transform 0.28s ease;
    transition: -webkit-transform 0.28s ease;
    transition: transform 0.28s ease, -webkit-transform 0.28s ease;
    border-radius: 50%;
    border: 0.125rem solid #448aff; }
.radio label:hover .helper {
  color: #448aff; }
.radio input:checked ~ .helper::after {
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }
.radio input:checked ~ .helper::before {
  color: #448aff;
  -webkit-animation: ripple 0.3s linear forwards;
          animation: ripple 0.3s linear forwards; }
.radio.radiofill input:checked ~ .helper::after {
  -webkit-transform: scale(1);
          transform: scale(1); }
.radio.radiofill .helper::after {
  background-color: #448aff; }
.radio.radio-outline input:checked ~ .helper::after {
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }
.radio.radio-outline .helper::after {
  background-color: #fff;
  border: 0.225rem solid #448aff; }
.radio.radio-matrial input ~ .helper::after {
  background-color: #fff; }
.radio.radio-matrial input:checked ~ .helper::after {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72);
          box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72); }
.radio.radio-matrial input:checked ~ .helper::before {
  background-color: #448aff; }
.radio.radio-disable {
  opacity: 0.7; }
  .radio.radio-disable label {
    cursor: not-allowed; }

.radio-inline {
  display: inline-block;
  margin-right: 20px; }

.radio.radiofill.radio-primary .helper::after {
  background-color: #448aff;
  border-color: #448aff; }
.radio.radiofill.radio-primary .helper::before {
  border-color: #448aff; }
.radio.radio-outline.radio-primary .helper::after {
  background-color: #fff;
  border: 0.225rem solid #448aff; }
.radio.radio-outline.radio-primary .helper::before {
  border-color: #448aff; }
.radio.radio-matrial.radio-primary input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-primary input ~ .helper::before {
  background-color: #448aff;
  border-color: #448aff; }

.radio.radiofill.radio-warning .helper::after {
  background-color: #ffe100;
  border-color: #ffe100; }
.radio.radiofill.radio-warning .helper::before {
  border-color: #ffe100; }
.radio.radio-outline.radio-warning .helper::after {
  background-color: #fff;
  border: 0.225rem solid #ffe100; }
.radio.radio-outline.radio-warning .helper::before {
  border-color: #ffe100; }
.radio.radio-matrial.radio-warning input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-warning input ~ .helper::before {
  background-color: #ffe100;
  border-color: #ffe100; }

.radio.radiofill.radio-default .helper::after {
  background-color: #d6d6d6;
  border-color: #d6d6d6; }
.radio.radiofill.radio-default .helper::before {
  border-color: #d6d6d6; }
.radio.radio-outline.radio-default .helper::after {
  background-color: #fff;
  border: 0.225rem solid #d6d6d6; }
.radio.radio-outline.radio-default .helper::before {
  border-color: #d6d6d6; }
.radio.radio-matrial.radio-default input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-default input ~ .helper::before {
  background-color: #d6d6d6;
  border-color: #d6d6d6; }

.radio.radiofill.radio-danger .helper::after {
  background-color: #ff5252;
  border-color: #ff5252; }
.radio.radiofill.radio-danger .helper::before {
  border-color: #ff5252; }
.radio.radio-outline.radio-danger .helper::after {
  background-color: #fff;
  border: 0.225rem solid #ff5252; }
.radio.radio-outline.radio-danger .helper::before {
  border-color: #ff5252; }
.radio.radio-matrial.radio-danger input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-danger input ~ .helper::before {
  background-color: #ff5252;
  border-color: #ff5252; }

.radio.radiofill.radio-success .helper::after {
  background-color: #11c15b;
  border-color: #11c15b; }
.radio.radiofill.radio-success .helper::before {
  border-color: #11c15b; }
.radio.radio-outline.radio-success .helper::after {
  background-color: #fff;
  border: 0.225rem solid #11c15b; }
.radio.radio-outline.radio-success .helper::before {
  border-color: #11c15b; }
.radio.radio-matrial.radio-success input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-success input ~ .helper::before {
  background-color: #11c15b;
  border-color: #11c15b; }

.radio.radiofill.radio-inverse .helper::after {
  background-color: #37474f;
  border-color: #37474f; }
.radio.radiofill.radio-inverse .helper::before {
  border-color: #37474f; }
.radio.radio-outline.radio-inverse .helper::after {
  background-color: #fff;
  border: 0.225rem solid #37474f; }
.radio.radio-outline.radio-inverse .helper::before {
  border-color: #37474f; }
.radio.radio-matrial.radio-inverse input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-inverse input ~ .helper::before {
  background-color: #37474f;
  border-color: #37474f; }

.radio.radiofill.radio-info .helper::after {
  background-color: #00bcd4;
  border-color: #00bcd4; }
.radio.radiofill.radio-info .helper::before {
  border-color: #00bcd4; }
.radio.radio-outline.radio-info .helper::after {
  background-color: #fff;
  border: 0.225rem solid #00bcd4; }
.radio.radio-outline.radio-info .helper::before {
  border-color: #00bcd4; }
.radio.radio-matrial.radio-info input ~ .helper::after {
  background-color: #fff;
  border-color: #fff; }
.radio.radio-matrial.radio-info input ~ .helper::before {
  background-color: #00bcd4;
  border-color: #00bcd4; }

@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0); }
  50% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.05); }
  100% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0); } }

@keyframes ripple {
  0% {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0); }
  50% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.05); }
  100% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0); } }
/*===== Radio Button css ends =====*/
/*===== Border-checkbox css start =====*/
.border-checkbox-section .border-checkbox-group {
  display: inline-block; }
  .border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:before {
    -webkit-animation: ripple 0.3s linear forwards;
            animation: ripple 0.3s linear forwards; }
  .border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:after {
    -webkit-animation: check linear 0.5s;
    animation: check linear 0.5s;
    opacity: 1;
    border-color: #448aff; }
    .border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:after .border-checkbox-label:before {
      border-color: #eee; }
  .border-checkbox-section .border-checkbox-group .border-checkbox-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    padding-left: 30px;
    margin-right: 15px; }
    .border-checkbox-section .border-checkbox-group .border-checkbox-label:after {
      content: "";
      display: block;
      width: 6px;
      height: 12px;
      opacity: 0.9;
      border-right: 2px solid #eee;
      border-top: 2px solid #eee;
      position: absolute;
      left: 4px;
      top: 11px;
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      -webkit-transform-origin: left top;
      transform-origin: left top; }
    .border-checkbox-section .border-checkbox-group .border-checkbox-label:before {
      content: "";
      display: block;
      border: 2px solid #448aff;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0; }
.border-checkbox-section .border-checkbox {
  display: none; }
  .border-checkbox-section .border-checkbox:disabled ~ .border-checkbox-label {
    cursor: no-drop;
    color: #ccc; }

@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0; }
  25% {
    height: 0;
    width: 6px; }
  50% {
    height: 12px;
    width: 6px; } }
@keyframes check {
  0% {
    height: 0;
    width: 0; }
  25% {
    height: 0;
    width: 6px; }
  50% {
    height: 12px;
    width: 6px; } }
.border-checkbox-section .border-checkbox-group-primary .border-checkbox-label:before {
  border: 2px solid #448aff; }
.border-checkbox-section .border-checkbox-group-primary .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #448aff; }

.border-checkbox-section .border-checkbox-group-warning .border-checkbox-label:before {
  border: 2px solid #ffe100; }
.border-checkbox-section .border-checkbox-group-warning .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #ffe100; }

.border-checkbox-section .border-checkbox-group-default .border-checkbox-label:before {
  border: 2px solid #d6d6d6; }
.border-checkbox-section .border-checkbox-group-default .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #d6d6d6; }

.border-checkbox-section .border-checkbox-group-danger .border-checkbox-label:before {
  border: 2px solid #ff5252; }
.border-checkbox-section .border-checkbox-group-danger .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #ff5252; }

.border-checkbox-section .border-checkbox-group-success .border-checkbox-label:before {
  border: 2px solid #11c15b; }
.border-checkbox-section .border-checkbox-group-success .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #11c15b; }

.border-checkbox-section .border-checkbox-group-inverse .border-checkbox-label:before {
  border: 2px solid #37474f; }
.border-checkbox-section .border-checkbox-group-inverse .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #37474f; }

.border-checkbox-section .border-checkbox-group-info .border-checkbox-label:before {
  border: 2px solid #00bcd4; }
.border-checkbox-section .border-checkbox-group-info .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #00bcd4; }

/*===== zoom Fade-in-checkbox css start =====*/
.checkbox-fade,
.checkbox-zoom {
  display: inline-block;
  margin-right: 15px; }
  .checkbox-fade label,
  .checkbox-zoom label {
    line-height: 20px; }
    .checkbox-fade label input[type="checkbox"],
    .checkbox-fade label input[type="radio"],
    .checkbox-zoom label input[type="checkbox"],
    .checkbox-zoom label input[type="radio"] {
      display: none; }
      .checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon,
      .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon,
      .checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon,
      .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
        -webkit-transform: scale(1) rotateZ(0deg);
                transform: scale(1) rotateZ(0deg);
        opacity: 1; }
      .checkbox-fade label input[type="checkbox"] + .cr > .cr-icon,
      .checkbox-fade label input[type="radio"] + .cr > .cr-icon,
      .checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon,
      .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
        -webkit-transform: scale(3) rotateZ(-20deg);
                transform: scale(3) rotateZ(-20deg);
        opacity: 0;
        -webkit-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in; }
    .checkbox-fade label:after,
    .checkbox-zoom label:after {
      content: '';
      display: table;
      clear: both; }
  .checkbox-fade.fade-in-disable .cr,
  .checkbox-fade.fade-in-disable label,
  .checkbox-zoom.fade-in-disable .cr,
  .checkbox-zoom.fade-in-disable label {
    color: #ccc;
    cursor: no-drop; }
  .checkbox-fade .cr,
  .checkbox-zoom .cr {
    border-radius: 0;
    border: 2px solid #448aff;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin-right: 1rem;
    position: relative;
    width: 20px; }
    .checkbox-fade .cr .cr-icon,
    .checkbox-zoom .cr .cr-icon {
      color: #448aff;
      font-size: 0.8em;
      left: 0;
      line-height: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%; }
  .checkbox-fade input[type="checkbox"]:checked + .cr,
  .checkbox-zoom input[type="checkbox"]:checked + .cr {
    -webkit-animation: ripple 0.3s linear forwards;
            animation: ripple 0.3s linear forwards; }

.checkbox-fade label {
  line-height: 20px; }
  .checkbox-fade label input[type="checkbox"],
  .checkbox-fade label input[type="radio"] {
    display: none; }
    .checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon,
    .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon {
      -webkit-transform: scale(1) rotateZ(0deg);
              transform: scale(1) rotateZ(0deg);
      opacity: 1; }
    .checkbox-fade label input[type="checkbox"] + .cr > .cr-icon,
    .checkbox-fade label input[type="radio"] + .cr > .cr-icon {
      -webkit-transform: scale(3) rotateZ(-20deg);
              transform: scale(3) rotateZ(-20deg);
      opacity: 0;
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in; }

.checkbox-zoom label {
  line-height: 20px; }
  .checkbox-zoom label input[type="checkbox"],
  .checkbox-zoom label input[type="radio"] {
    display: none; }
    .checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon,
    .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
      -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
              transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
      opacity: 1; }
    .checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon,
    .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
      -webkit-transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
              transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
      opacity: 0;
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in; }

.checkbox-fade.fade-in-primary .cr, .checkbox-fade.zoom-primary .cr,
.checkbox-zoom.fade-in-primary .cr,
.checkbox-zoom.zoom-primary .cr {
  border: 2px solid #448aff; }
  .checkbox-fade.fade-in-primary .cr .cr-icon, .checkbox-fade.zoom-primary .cr .cr-icon,
  .checkbox-zoom.fade-in-primary .cr .cr-icon,
  .checkbox-zoom.zoom-primary .cr .cr-icon {
    color: #448aff; }

.checkbox-fade.fade-in-warning .cr, .checkbox-fade.zoom-warning .cr,
.checkbox-zoom.fade-in-warning .cr,
.checkbox-zoom.zoom-warning .cr {
  border: 2px solid #ffe100; }
  .checkbox-fade.fade-in-warning .cr .cr-icon, .checkbox-fade.zoom-warning .cr .cr-icon,
  .checkbox-zoom.fade-in-warning .cr .cr-icon,
  .checkbox-zoom.zoom-warning .cr .cr-icon {
    color: #ffe100; }

.checkbox-fade.fade-in-default .cr, .checkbox-fade.zoom-default .cr,
.checkbox-zoom.fade-in-default .cr,
.checkbox-zoom.zoom-default .cr {
  border: 2px solid #d6d6d6; }
  .checkbox-fade.fade-in-default .cr .cr-icon, .checkbox-fade.zoom-default .cr .cr-icon,
  .checkbox-zoom.fade-in-default .cr .cr-icon,
  .checkbox-zoom.zoom-default .cr .cr-icon {
    color: #d6d6d6; }

.checkbox-fade.fade-in-danger .cr, .checkbox-fade.zoom-danger .cr,
.checkbox-zoom.fade-in-danger .cr,
.checkbox-zoom.zoom-danger .cr {
  border: 2px solid #ff5252; }
  .checkbox-fade.fade-in-danger .cr .cr-icon, .checkbox-fade.zoom-danger .cr .cr-icon,
  .checkbox-zoom.fade-in-danger .cr .cr-icon,
  .checkbox-zoom.zoom-danger .cr .cr-icon {
    color: #ff5252; }

.checkbox-fade.fade-in-success .cr, .checkbox-fade.zoom-success .cr,
.checkbox-zoom.fade-in-success .cr,
.checkbox-zoom.zoom-success .cr {
  border: 2px solid #11c15b; }
  .checkbox-fade.fade-in-success .cr .cr-icon, .checkbox-fade.zoom-success .cr .cr-icon,
  .checkbox-zoom.fade-in-success .cr .cr-icon,
  .checkbox-zoom.zoom-success .cr .cr-icon {
    color: #11c15b; }

.checkbox-fade.fade-in-inverse .cr, .checkbox-fade.zoom-inverse .cr,
.checkbox-zoom.fade-in-inverse .cr,
.checkbox-zoom.zoom-inverse .cr {
  border: 2px solid #37474f; }
  .checkbox-fade.fade-in-inverse .cr .cr-icon, .checkbox-fade.zoom-inverse .cr .cr-icon,
  .checkbox-zoom.fade-in-inverse .cr .cr-icon,
  .checkbox-zoom.zoom-inverse .cr .cr-icon {
    color: #37474f; }

.checkbox-fade.fade-in-info .cr, .checkbox-fade.zoom-info .cr,
.checkbox-zoom.fade-in-info .cr,
.checkbox-zoom.zoom-info .cr {
  border: 2px solid #00bcd4; }
  .checkbox-fade.fade-in-info .cr .cr-icon, .checkbox-fade.zoom-info .cr .cr-icon,
  .checkbox-zoom.fade-in-info .cr .cr-icon,
  .checkbox-zoom.zoom-info .cr .cr-icon {
    color: #00bcd4; }

/*===== Color-checkbox css start =====*/
.checkbox-color {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer; }
  .checkbox-color label {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    line-height: 20px; }
    .checkbox-color label::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 20px;
      height: 20px;
      left: 0;
      right: 0;
      text-align: center;
      margin-left: -20px;
      border: 1px solid #ccc;
      border-radius: 0;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .checkbox-color label::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: -1px;
      top: 0;
      margin-left: -17px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #fff; }
  .checkbox-color input[type="checkbox"] {
    opacity: 0; }
    .checkbox-color input[type="checkbox"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .checkbox-color input[type="checkbox"]:checked + label::after {
      font-family: 'FontAwesome';
      content: "\f00c"; }
    .checkbox-color input[type="checkbox"]:checked + label::before {
      -webkit-animation: ripple 0.3s linear forwards;
              animation: ripple 0.3s linear forwards; }
    .checkbox-color input[type="checkbox"]:disabled + label {
      opacity: 0.65; }
      .checkbox-color input[type="checkbox"]:disabled + label::before {
        background-color: #eee;
        cursor: not-allowed; }
  .checkbox-color.checkbox-circle label::before {
    border-radius: 50%; }
  .checkbox-color.checkbox-inline {
    margin-top: 0; }

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #2196f3;
  border-color: #2196f3; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #448aff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffe100; }

.checkbox-default input[type="checkbox"]:checked + label::before {
  background-color: #d6d6d6; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #ff5252; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #11c15b; }

.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #37474f; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #00bcd4; }

/*===== Tag input css start =====*/
.bootstrap-tagsinput {
  border: 1px solid #448aff;
  line-height: 30px;
  border-radius: 2px; }
  .bootstrap-tagsinput .tag {
    padding: 6px;
    border-radius: 2px; }

/**  =====================
      Select-2 css start
==========================  **/
.select2-container--default:focus {
  border-color: #448aff; }
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #fff transparent; }
.select2-container--default .select2-search__field:focus {
  border: 1px solid #448aff; }
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #448aff; }
.select2-container--default .select2-selection--multiple {
  padding: 3px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #448aff;
    border: 1px solid #448aff;
    padding: 5px 15px;
    color: #fff; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice span {
      color: #fff; }
  .select2-container--default .select2-selection--multiple .select2-search__field {
    border: none; }
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #448aff; }
.select2-container--default .select2-selection--single {
  color: #fff;
  height: auto; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    background-color: #448aff;
    color: #fff;
    padding: 8px 30px 8px 20px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px;
    right: 15px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #fff transparent transparent transparent; }

/*===== Select-2 css ends =====*/
/**  =====================
      Multi-select css start
==========================  **/
.ms-container .ms-list.ms-focus {
  border-color: #448aff;
  -webkit-box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5);
          box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5); }

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background-color: #448aff; }

.ms-selectable .custom-header,
.ms-selection .custom-header {
  background-color: #448aff;
  color: #fff;
  text-align: center; }

.ms-list {
  display: block !important; }
  .ms-list li {
    width: 100%; }

/*===== Multi-select css ends =====*/
/**  =====================
      Validation-forms css start
==========================  **/
.form-group .messages p {
  margin-bottom: 0;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s; }
.form-group .popover-valid {
  position: absolute;
  right: 40px;
  top: 7px; }

/*===== Validation-forms css end =====*/
/**  =====================
      Form-wizards css start
==========================  **/
.wizard > .steps .current a {
  background: #448aff;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  cursor: pointer;
  border-radius: 2px;
  outline: 1px solid #fff;
  outline-offset: -7px; }
  .wizard > .steps .current a:hover {
    background: #5e9aff;
    outline-offset: 0; }

#design-wizard .steps li {
  position: relative;
  z-index: 99;
  margin-bottom: 20px; }
  #design-wizard .steps li:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #448aff;
    width: 100%;
    top: 30px;
    z-index: -1; }
  #design-wizard .steps li a {
    width: 20%;
    margin: 0 auto;
    text-align: center;
    border-radius: 2px; }

.wizard > .steps .done a {
  background: #d6d6d6; }

.card .card-block .wizard-form .steps ul {
  display: block; }

.card .card-block .wizard-form li {
  margin-right: 0; }

.wizard > .content {
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fff; }

/*===== Form-wizard forms css end =====*/
/*Form control start*/
.form-material .form-group {
  position: relative; }
  .form-material .form-group.form-static-label .form-control ~ .float-label {
    top: -14px;
    font-size: 11px; }
.form-material .form-control {
  display: inline-block;
  height: 43px;
  width: 100%;
  border: none;
  border-radius: 0 !important;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: 1px solid #ccc; }
  .form-material .form-control:focus {
    border-color: transparent;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .form-material .form-control:focus ~ .form-bar:after, .form-material .form-control:focus ~ .form-bar:before {
      width: 50%; }
  .form-material .form-control:focus ~ .float-label, .form-material .form-control:valid ~ .float-label {
    top: -14px;
    font-size: 11px; }
.form-material textarea {
  height: 100px !important; }
.form-material .form-bar {
  position: relative;
  display: block;
  width: 100%; }
  .form-material .form-bar:after, .form-material .form-bar:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all; }
  .form-material .form-bar:before {
    left: 50%; }
  .form-material .form-bar:after {
    right: 50%; }
.form-material .float-label {
  pointer-events: none;
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }
.form-material .material-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.25em; }
  .form-material .material-group .material-addone {
    padding: .5rem .75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .form-material .material-group .material-addone i, .form-material .material-group .material-addone span {
      font-size: 20px; }
  .form-material .material-group .form-group {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
  .form-material .material-group.material-group-lg .material-addone {
    padding: .5rem 1rem; }
    .form-material .material-group.material-group-lg .material-addone i, .form-material .material-group.material-group-lg .material-addone span {
      font-size: 1.55rem;
      font-weight: 500; }
  .form-material .material-group.material-group-lg .form-control {
    padding: .5rem 0;
    font-size: 1.25rem;
    line-height: 1.5; }
    .form-material .material-group.material-group-lg .form-control:focus ~ .float-label, .form-material .material-group.material-group-lg .form-control:valid ~ .float-label {
      top: -15px;
      font-size: 14px; }
  .form-material .material-group.material-group-lg .float-label {
    font-size: 1.25rem; }
  .form-material .material-group.material-group-sm .material-addone {
    padding: .25rem .5rem; }
    .form-material .material-group.material-group-sm .material-addone i, .form-material .material-group.material-group-sm .material-addone span {
      font-size: .775rem; }
  .form-material .material-group.material-group-sm .form-control {
    padding: .25rem  0;
    font-size: .875rem;
    line-height: 1.5; }
    .form-material .material-group.material-group-sm .form-control:focus ~ .float-label, .form-material .material-group.material-group-sm .form-control:valid ~ .float-label {
      top: -9px;
      font-size: 11px; }
  .form-material .material-group.material-group-sm .float-label {
    font-size: .775rem; }
.form-material .form-primary .form-control:focus ~ label {
  color: #448aff; }
.form-material .form-primary .form-control:focus ~ .form-bar:after, .form-material .form-primary .form-control:focus ~ .form-bar:before {
  background: #448aff; }
.form-material .form-primary.form-static-label .float-label {
  color: #448aff; }
.form-material .form-primary.form-static-label .form-control {
  border-bottom-color: #448aff; }
.form-material .material-group-primary .material-addone i, .form-material .material-group-primary .material-addone span {
  color: #448aff; }
.form-material .form-warning .form-control:focus ~ label {
  color: #ffe100; }
.form-material .form-warning .form-control:focus ~ .form-bar:after, .form-material .form-warning .form-control:focus ~ .form-bar:before {
  background: #ffe100; }
.form-material .form-warning.form-static-label .float-label {
  color: #ffe100; }
.form-material .form-warning.form-static-label .form-control {
  border-bottom-color: #ffe100; }
.form-material .material-group-warning .material-addone i, .form-material .material-group-warning .material-addone span {
  color: #ffe100; }
.form-material .form-default .form-control:focus ~ label {
  color: #d6d6d6; }
.form-material .form-default .form-control:focus ~ .form-bar:after, .form-material .form-default .form-control:focus ~ .form-bar:before {
  background: #d6d6d6; }
.form-material .form-default.form-static-label .float-label {
  color: #d6d6d6; }
.form-material .form-default.form-static-label .form-control {
  border-bottom-color: #d6d6d6; }
.form-material .material-group-default .material-addone i, .form-material .material-group-default .material-addone span {
  color: #d6d6d6; }
.form-material .form-danger .form-control:focus ~ label {
  color: #ff5252; }
.form-material .form-danger .form-control:focus ~ .form-bar:after, .form-material .form-danger .form-control:focus ~ .form-bar:before {
  background: #ff5252; }
.form-material .form-danger.form-static-label .float-label {
  color: #ff5252; }
.form-material .form-danger.form-static-label .form-control {
  border-bottom-color: #ff5252; }
.form-material .material-group-danger .material-addone i, .form-material .material-group-danger .material-addone span {
  color: #ff5252; }
.form-material .form-success .form-control:focus ~ label {
  color: #11c15b; }
.form-material .form-success .form-control:focus ~ .form-bar:after, .form-material .form-success .form-control:focus ~ .form-bar:before {
  background: #11c15b; }
.form-material .form-success.form-static-label .float-label {
  color: #11c15b; }
.form-material .form-success.form-static-label .form-control {
  border-bottom-color: #11c15b; }
.form-material .material-group-success .material-addone i, .form-material .material-group-success .material-addone span {
  color: #11c15b; }
.form-material .form-inverse .form-control:focus ~ label {
  color: #37474f; }
.form-material .form-inverse .form-control:focus ~ .form-bar:after, .form-material .form-inverse .form-control:focus ~ .form-bar:before {
  background: #37474f; }
.form-material .form-inverse.form-static-label .float-label {
  color: #37474f; }
.form-material .form-inverse.form-static-label .form-control {
  border-bottom-color: #37474f; }
.form-material .material-group-inverse .material-addone i, .form-material .material-group-inverse .material-addone span {
  color: #37474f; }
.form-material .form-info .form-control:focus ~ label {
  color: #00bcd4; }
.form-material .form-info .form-control:focus ~ .form-bar:after, .form-material .form-info .form-control:focus ~ .form-bar:before {
  background: #00bcd4; }
.form-material .form-info.form-static-label .float-label {
  color: #00bcd4; }
.form-material .form-info.form-static-label .form-control {
  border-bottom-color: #00bcd4; }
.form-material .material-group-info .material-addone i, .form-material .material-group-info .material-addone span {
  color: #00bcd4; }

select.form-control, select.form-control:focus, select.form-control:hover {
  border-top: none;
  border-right: none;
  border-left: none; }

/* form controll end*/
.md-input-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0); }

.md-input-file {
  overflow: hidden;
  padding-left: 10px; }

.md-add-on-file {
  float: left;
  height: 3rem;
  line-height: 3rem; }
  .md-add-on-file .btn {
    padding: 7px 20px; }

.md-form-file ~ .md-form-control {
  opacity: 0;
  position: absolute;
  top: 0; }

.md-input-wrapper .md-check {
  padding-left: 0;
  top: 0; }

/**  =====================
      Label-Badges css start
==========================  **/
.label {
  border-radius: 4px;
  font-size: 75%;
  padding: 4px 7px;
  margin-right: 5px;
  font-weight: 400;
  color: #fff !important; }

.label-main {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 5px; }

.badge {
  border-radius: 10px;
  padding: 3px 7px; }

.badge-top-left {
  margin-right: -10px;
  right: 100%;
  top: -3px; }

.badge-top-right {
  margin-left: -10px;
  top: -3px; }

.badge-bottom-left {
  margin-left: -33px;
  bottom: -12px;
  right: 97%; }

.badge-bottom-right {
  margin-left: -10px;
  bottom: -12px; }

.label.label-lg {
  padding: 8px 21px; }

.label.label-md {
  padding: 6px 14px; }

.badge-lg {
  padding: 5px 9px;
  font-size: 14px; }

.badge-md {
  padding: 4px 8px;
  font-size: 14px; }

span.badge {
  display: inline-block !important; }

.label-default {
  background-color: #d6d6d6 !important;
  border-color: #d6d6d6;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff; }

.label-inverse-default {
  border: 1px solid;
  border-color: #d6d6d6;
  color: #d6d6d6 !important; }

.label-inverse-primary {
  border: 1px solid;
  border-color: #448aff;
  color: #448aff !important; }

.label-inverse-success {
  border: 1px solid;
  border-color: #11c15b;
  color: #11c15b !important; }

.label-inverse-warning {
  border: 1px solid;
  border-color: #ffe100;
  color: #ffe100 !important; }

.label-inverse-danger {
  border: 1px solid;
  border-color: #ff5252;
  color: #ff5252 !important; }

.label-inverse-info {
  border: 1px solid;
  border-color: #00bcd4;
  color: #00bcd4 !important; }

.label-inverse-info-border {
  border: 1px solid;
  border-color: #37474f;
  color: #37474f !important; }

.badge-inverse-default {
  border: 1px solid;
  border-color: #d6d6d6;
  color: #d6d6d6 !important; }

.badge-inverse-primary {
  border: 1px solid;
  border-color: #448aff;
  color: #448aff !important; }

.badge-inverse-success {
  border: 1px solid;
  border-color: #11c15b;
  color: #11c15b !important; }

.badge-inverse-warning {
  border: 1px solid;
  border-color: #ffe100;
  color: #ffe100 !important; }

.badge-inverse-danger {
  border: 1px solid;
  border-color: #ff5252;
  color: #ff5252 !important; }

.badge-inverse-info {
  border: 1px solid;
  border-color: #37474f;
  color: #37474f !important; }

.label-icon {
  position: relative; }
  .label-icon label {
    position: absolute; }

.icofont.icofont-envelope {
  font-size: 20px; }

/*===== Label and Badges css end =====*/
/* ======================
    All Icon Common Page css
======================== */
.data-table-main.icon-list-demo [class*=col-] {
  margin-bottom: 10px; }

.data-table-main.icon-svg-demo [class*=col-] {
  margin-bottom: 10px; }

.icon-list-demo i {
  border: 1px solid #eceeef;
  border-radius: 3px;
  color: rgba(43, 61, 81, 0.7);
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 0 12px 0 0;
  text-align: center;
  vertical-align: middle;
  width: 50px; }

.icon-list-demo div {
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 10px; }

.icon-list-demo i:hover {
  color: #64b0f2; }

/**====== Flag-icon css start ======**/
.flags .f-item {
  padding: 12px;
  border: 1px solid #ddd;
  margin-right: 15px;
  display: inline-block; }

.data-table-main.flags [class*=col-] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }

.content-flag label {
  margin-bottom: 0;
  cursor: pointer; }

.content-flag .txt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  overflow: hidden; }

.outer-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px; }

label.txt-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 135px; }

.flags .f-item .name,
.flags .f-item .capital {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0; }

.flags .f-item .code {
  display: block;
  font-size: 90%;
  color: #999;
  padding-bottom: 0; }

/**====== All icon common page css end ======**/
/**  =====================
      Ready-to-use css start
==========================  **/
.bg-pic {
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay; }

.j-pro {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  .j-pro .j-header {
    background-color: #448aff;
    border-top: 1px solid #448aff;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .j-pro .j-icon-right {
    border-left: 1px solid rgba(0, 0, 0, 0.32); }
  .j-pro .j-icon-left {
    border-right: 1px solid rgba(0, 0, 0, 0.32); }
  .j-pro .j-footer {
    background-color: #fff;
    border-top: 1px dashed #448aff;
    padding: 20px 25px;
    position: inherit; }
    .j-pro .j-footer button {
      margin-bottom: 0;
      float: right; }
  .j-pro .j-divider-text span {
    color: #222; }
  .j-pro .btn-primary.disabled, .j-pro .sweet-alert button.disabled.confirm, .sweet-alert .j-pro button.disabled.confirm, .j-pro .wizard > .actions a.disabled, .wizard > .actions .j-pro a.disabled,
  .j-pro .btn-primary:disabled,
  .j-pro .sweet-alert button.confirm:disabled,
  .sweet-alert .j-pro button.confirm:disabled,
  .j-pro .wizard > .actions a:disabled,
  .wizard > .actions .j-pro a:disabled {
    background-color: #11c15b;
    border-color: #11c15b; }

.j-pro input[type="text"],
.j-pro input[type="password"],
.j-pro input[type="email"],
.j-pro input[type="search"],
.j-pro input[type="url"],
.j-pro textarea,
.j-pro select,
.j-forms input[type="text"],
.j-forms input[type="password"],
.j-forms input[type="email"],
.j-forms input[type="search"],
.j-forms input[type="url"],
.j-forms textarea,
.j-forms select {
  border: 1px solid rgba(0, 0, 0, 0.15); }

.j-pro input[type="text"]:hover,
.j-pro input[type="password"]:hover,
.j-pro input[type="email"]:hover,
.j-pro input[type="search"]:hover,
.j-pro input[type="url"]:hover,
.j-pro textarea:hover,
.j-pro select:hover,
.j-pro input[type="text"]:focus,
.j-pro input[type="password"]:focus,
.j-pro input[type="email"]:focus,
.j-pro input[type="search"]:focus,
.j-pro input[type="url"]:focus,
.j-pro textarea:focus,
.j-pro select:focus,
.j-pro .j-file-button:hover + input,
.j-forms input[type="text"]:hover,
.j-forms input[type="password"]:hover,
.j-forms input[type="email"]:hover,
.j-forms input[type="search"]:hover,
.j-forms input[type="url"]:hover,
.j-forms textarea:hover,
.j-forms select:hover,
.j-forms input[type="text"]:focus,
.j-forms input[type="password"]:focus,
.j-forms input[type="email"]:focus,
.j-forms input[type="search"]:focus,
.j-forms input[type="url"]:focus,
.j-forms textarea:focus,
.j-forms select:focus {
  border: 1px solid #448aff; }

.j-pro .j-radio-toggle,
.j-pro .j-checkbox-toggle,
.j-pro .j-inline-group .j-radio-toggle,
.j-pro .j-inline-group .j-checkbox-toggle {
  padding: 9px 0 18px 0; }

.j-pro .j-tooltip,
.j-pro .j-tooltip-image {
  background-color: #448aff; }
  .j-pro .j-tooltip:before,
  .j-pro .j-tooltip-image:before {
    border-color: #448aff transparent; }

.j-pro .j-primary-btn,
.j-pro .j-file-button,
.j-pro .j-secondary-btn,
.j-pro .j-widget .j-addon-btn {
  background-color: #448aff; }
  .j-pro .j-primary-btn:hover,
  .j-pro .j-file-button:hover,
  .j-pro .j-secondary-btn:hover,
  .j-pro .j-widget .j-addon-btn:hover {
    background-color: #5e9aff; }

.j-pro .j-ratings input + label:hover,
.j-pro .j-ratings input + label:hover ~ label,
.j-pro .j-ratings input:checked + label,
.j-pro .j-ratings input:checked + label ~ label {
  color: #448aff; }

.j-unit .checkbox-fade {
  display: block;
  margin-top: 20px; }

.j-forms {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  .j-forms button i {
    margin-right: 0; }
  .j-forms .checkbox-fade {
    margin-top: 10px; }
    .j-forms .checkbox-fade .disabled-view {
      opacity: 0.5;
      cursor: not-allowed; }
  .j-forms .link {
    color: #448aff;
    border-bottom: none;
    text-transform: capitalize;
    margin-bottom: 15px; }
  .j-forms .label {
    margin-bottom: 15px; }
  .j-forms .btn-primary:disabled, .j-forms .sweet-alert button.confirm:disabled, .sweet-alert .j-forms button.confirm:disabled, .j-forms .wizard > .actions a:disabled, .wizard > .actions .j-forms a:disabled {
    background-color: #d6d6d6;
    border-color: #d6d6d6; }
  .j-forms .radio-toggle,
  .j-forms .checkbox-toggle,
  .j-forms .inline-group .radio-toggle,
  .j-forms .inline-group .checkbox-toggle {
    padding: 9px 0 8px 0px; }
  .j-forms .header {
    background-color: #448aff;
    border-top: 1px solid #448aff;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .j-forms .divider-text span {
    color: #222; }
  .j-forms .widget .addon,
  .j-forms .widget .addon-btn {
    background-color: #448aff;
    color: #fff; }
    .j-forms .widget .addon:hover, .j-forms .widget .addon:focus,
    .j-forms .widget .addon-btn:hover,
    .j-forms .widget .addon-btn:focus {
      background-color: #5e9aff; }
      .j-forms .widget .addon:hover i, .j-forms .widget .addon:focus i,
      .j-forms .widget .addon-btn:hover i,
      .j-forms .widget .addon-btn:focus i {
        color: #fff; }
    .j-forms .widget .addon i,
    .j-forms .widget .addon-btn i {
      color: #fff; }
  .j-forms .footer {
    background-color: #fff;
    border-top: 1px dashed #1abc9c;
    padding: 20px 25px;
    position: inherit; }
    .j-forms .footer button {
      float: right;
      margin-bottom: 0; }
  .j-forms .stepper .stepper-arrow {
    background-color: #448aff; }
    .j-forms .stepper .stepper-arrow:hover {
      background-color: #5e9aff; }
    .j-forms .stepper .stepper-arrow.up:after {
      border-bottom: 7px solid #fff; }
    .j-forms .stepper .stepper-arrow.down:after {
      border-top: 7px solid #fff; }

.popup-menu {
  padding: 0; }
  .popup-menu .popup-list {
    background: linear-gradient(45deg, #448aff, #77aaff);
    border-radius: 0; }
    .popup-menu .popup-list > ul > li {
      -webkit-transition: all ease-in .3s;
      transition: all ease-in .3s;
      color: #fff;
      border-left: none;
      cursor: pointer; }
      .popup-menu .popup-list > ul > li:hover {
        background-color: #384c5f;
        color: #fff; }

.j-tabs-container .j-tabs-label,
.j-tabs-container input[type="radio"]:checked + .j-tabs-label {
  border-top: 4px solid #448aff; }

.pop-up-logo img {
  margin-top: 10px;
  margin-left: 80px; }

.popup-list-open .popup-list-wrapper {
  z-index: 99; }

.span4 label {
  color: #222 !important; }

.pop-up-wrapper {
  margin-left: 0 !important; }

@media only screen and (max-width: 480px) {
  .j-forms, .j-pro {
    border: none;
    background-color: #fff; }
    .j-forms .footer, .j-forms .j-footer, .j-pro .footer, .j-pro .j-footer {
      border-top: none; }
      .j-forms .footer button, .j-forms .j-footer button, .j-pro .footer button, .j-pro .j-footer button {
        margin: 5px; }
    .j-forms .j-content, .j-pro .j-content {
      padding: 0; }
    .j-forms .j-divider-text, .j-pro .j-divider-text {
      border-top: none; }
      .j-forms .j-divider-text span, .j-pro .j-divider-text span {
        white-space: unset;
        background: transparent;
        border: none; }
    .j-forms .icon-right ~ input, .j-forms .j-forms .icon-right ~ textarea, .j-pro .icon-right ~ input, .j-pro .j-forms .icon-right ~ textarea {
      padding-right: 0; }

  .j-wrapper {
    padding: 0;
    border: none;
    background-color: transparent; } }
/**====== Ready-to-use css end ======**/
/**  =====================
      Box-shadow css start
==========================  **/
.box-list div div div {
  margin-bottom: 20px; }

.box-list {
  padding-bottom: 0; }

.box-list p {
  margin-bottom: 0 !important; }

/**====== z-depth-top css start ======**/
.z-depth-top-0 {
  -webkit-box-shadow: 0 -5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 -5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-1 {
  -webkit-box-shadow: 0 -7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 -7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-2 {
  -webkit-box-shadow: 0 -9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 -9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-3 {
  -webkit-box-shadow: 0 -10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 -10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-4 {
  -webkit-box-shadow: 0 -11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 -11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-top-5 {
  -webkit-box-shadow: 0 -12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 -12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-top css end ======**/
/**====== z-depth-bottom css start ======**/
.z-depth-bottom-0 {
  -webkit-box-shadow: 0 5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-1 {
  -webkit-box-shadow: 0 7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-2 {
  -webkit-box-shadow: 0 9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-3 {
  -webkit-box-shadow: 0 10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-4 {
  -webkit-box-shadow: 0 11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-bottom-5 {
  -webkit-box-shadow: 0 12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-bottom css end ======**/
/**====== z-depth-left css start ======**/
.z-depth-left-0 {
  -webkit-box-shadow: -5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: -5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-1 {
  -webkit-box-shadow: -7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: -7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-2 {
  -webkit-box-shadow: -9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: -9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-3 {
  -webkit-box-shadow: -10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: -10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-4 {
  -webkit-box-shadow: -11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: -11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-left-5 {
  -webkit-box-shadow: -12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: -12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-left css end ======**/
/**====== z-depth-right css start ======**/
.z-depth-right-0 {
  -webkit-box-shadow: 5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-1 {
  -webkit-box-shadow: 7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-2 {
  -webkit-box-shadow: 9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-3 {
  -webkit-box-shadow: 10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-4 {
  -webkit-box-shadow: 11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-right-5 {
  -webkit-box-shadow: 12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-right css end ======**/
/**====== z-depth-all-side css start ======**/
.z-depth-0 {
  -webkit-box-shadow: 0 0 25px 5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 25px 5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-1 {
  -webkit-box-shadow: 0 0 25px 7px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 25px 7px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-2 {
  -webkit-box-shadow: 0 0 25px 9px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 25px 9px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-3 {
  -webkit-box-shadow: 0 0 25px 10px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 25px 10px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-4 {
  -webkit-box-shadow: 0 0 25px 11px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 25px 11px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

.z-depth-5 {
  -webkit-box-shadow: 0 0 25px 12px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 25px 12px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0); }

/**====== z-depth-all-side css end ======**/
/**====== Box-shadow css end ======**/
/**  =====================
      Data-table css start
==========================  **/
table.table-bordered.dataTable tbody th:focus,
table.table-bordered.dataTable tbody td:focus {
  outline: none; }

.card .card-block ul.pagination li {
  margin-right: 0; }

.page-item.active .page-link {
  background-color: #448aff;
  border-color: #448aff; }

.page-link {
  color: #222; }

.table-hover tbody tr:hover {
  background-color: rgba(68, 138, 255, 0.1); }

td.highlight {
  font-weight: 700;
  color: #00bcd4;
  background-color: #f5f5f5; }

.table.compact td,
.table.compact th {
  padding: .45rem; }

.dataTables_paginate .pagination {
  float: right; }

#multi-table_wrapper .dataTables_paginate .pagination {
  float: none; }

#footer-search tfoot .form-control,
#footer-select tfoot .form-control,
#form-input-table .form-control,
.search-api .form-control,
#dt-live-dom .form-control {
  width: 90%; }

.search-api .global_filter,
.search-api .column_filter {
  margin: 0 auto; }

.search-api .checkbox-fade {
  display: block;
  text-align: center; }



#row-select .selected,
#row-delete .selected {
  background-color: #448aff;
  color: #fff; }

/*===== Autofill data-table ===== */
div.dt-autofill-list div.dt-autofill-button button {
  background-color: #448aff;
  border-color: #448aff; }

table.dataTable {
  border-collapse: collapse !important; }

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #448aff;
  color: #fff; }

table.dataTable td.select-checkbox:before,
table.dataTable th.select-checkbox:before {
  margin-top: 0; }

table.dataTable tr.selected td.select-checkbox:after,
table.dataTable tr.selected th.select-checkbox:after {
  margin-top: -6px;
  margin-left: -5px; }

/*===== Button data-table ===== */
button.dt-button,
div.dt-button,
a.dt-button,
button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled),
button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button.active:not(.disabled),
a.dt-button:active:not(.disabled),
a.dt-button.active:not(.disabled) {
  background-color: #448aff;
  border-color: #448aff;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px; }

button.dt-button.btn-warning,
div.dt-button.btn-warning,
a.dt-button.btn-warning {
  background-color: #ffe100;
  border-color: #ffe100;
  border-radius: 2px;
  color: #fff;
  background-image: none; }

button.dt-button.btn-danger,
div.dt-button.btn-danger,
a.dt-button.btn-danger {
  background-color: #ff5252;
  border-color: #ff5252;
  border-radius: 2px;
  color: #fff;
  background-image: none; }

button.dt-button.btn-inverse,
div.dt-button.btn-inverse,
a.dt-button.btn-inverse {
  background-color: #37474f;
  border-color: #37474f;
  border-radius: 2px;
  color: #fff;
  background-image: none; }

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  background-image: none;
  background-color: #5e9aff;
  border-color: #448aff; }

button.dt-button.btn-warning:hover:not(.disabled),
div.dt-button.btn-warning:hover:not(.disabled),
a.dt-button.btn-warning:hover:not(.disabled) {
  background-image: none;
  background-color: #ffe733;
  border-color: #ffe100; }

button.dt-button.btn-danger:hover:not(.disabled),
div.dt-button.btn-danger:hover:not(.disabled),
a.dt-button.btn-danger:hover:not(.disabled) {
  background-image: none;
  background-color: #ff8585;
  border-color: #ff5252; }

button.dt-button.btn-inverse:hover:not(.disabled),
div.dt-button.btn-inverse:hover:not(.disabled),
a.dt-button.btn-inverse:hover:not(.disabled) {
  background-image: none;
  background-color: #4c626d;
  border-color: #37474f; }

div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection a.dt-button:active:not(.disabled),
div.dt-button-collection a.dt-button.active:not(.disabled) {
  background-color: #448aff;
  border-color: #448aff;
  background-image: none; }

div.dt-buttons {
  clear: both; }

.card .table-card-header b {
  display: block;
  color: #448aff;
  margin-top: 15px; }
.card .table-card-header span {
  color: #116aff;
  display: inline-block;
  margin-top: 0; }

div.dataTables_wrapper div.dataTables_info {
  display: inline-block; }

/*===== Colreorder data-table ===== */
table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202; }

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201; }

/*===== Fixed-column data-table ===== */
table.DTFC_Cloned thead,
table.DTFC_Cloned tfoot {
  background-color: white; }

div.DTFC_Blocker {
  background-color: white; }

div.DTFC_LeftWrapper table.dataTable,
div.DTFC_RightWrapper table.dataTable {
  margin-bottom: 0;
  z-index: 2; }

div.DTFC_LeftWrapper table.dataTable.no-footer,
div.DTFC_RightWrapper table.dataTable.no-footer {
  border-bottom: none; }

th,
td {
  white-space: nowrap; }

/*===== Fixed-header data-table ===== */
table.fixedHeader-floating {
  position: fixed !important;
  background-color: white; }

table.fixedHeader-floating.no-footer {
  border-bottom-width: 0; }

table.fixedHeader-locked {
  position: absolute !important;
  background-color: white; }

@media print {
  table.fixedHeader-floating {
    display: none; } }
/*===== Key-table data-table ===== */
table.dataTable th.focus,
table.dataTable td.focus {
  outline: 3px solid #448aff;
  outline-offset: -1px; }

table.dataTable td.focus {
  outline: 1px solid #ff5252;
  outline-offset: -3px;
  background-color: #f8e6e6 !important; }

#events {
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f6f6f6;
  border: 1px solid #999;
  border-radius: 3px;
  height: 100px;
  overflow: auto; }

/*===== Row-Re-order data-table ===== */
table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.8;
  table-layout: fixed;
  outline: 2px solid #888;
  outline-offset: -2px;
  z-index: 2001; }

tr.dt-rowReorder-moving {
  outline: 2px solid #555;
  outline-offset: -2px; }

body.dt-rowReorder-noOverflow {
  overflow-x: hidden; }

table.dataTable td.reorder {
  text-align: center;
  cursor: move; }

#result {
  border: 1px solid #888;
  background: #f7f7f7;
  padding: 1em;
  margin-bottom: 1em; }

/*===== Scroller data-table ===== */
div.DTS {
  display: block !important; }

div.DTS tbody th,
div.DTS tbody td {
  white-space: nowrap; }

div.DTS div.DTS_Loading {
  z-index: 1; }

div.DTS div.dataTables_scrollBody table {
  z-index: 2; }

div.DTS div.dataTables_paginate,
div.DTS div.dataTables_length {
  display: none; }

/**====== Data-table css end ======**/
/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > a:focus,
.footable .pagination > .active > a:hover,
.footable .pagination > .active > span,
.footable .pagination > .active > span:focus,
.footable .pagination > .active > span:hover {
  background-color: #448aff;
  border-color: #448aff; }

.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222; }

.footable-details.table,
.footable.table,
table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0; }

table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0; }

/**====== Foo-table css end ======**/
/**  =====================
      Handson css start
==========================  **/
.make-me-red {
  color: red; }

.scroll-container .wtHolder {
  height: 350px !important; }

.scroll-container #highlighting .wtHolder,
.scroll-container #populating .wtHolder,
.scroll-container #paginating .wtHolder,
.scroll-container #searching .wtHolder,
.scroll-container #drag .wtHolder,
.scroll-container #validation .wtHolder,
.scroll-container #readOnly .wtHolder,
.scroll-container #nonEditable .wtHolder,
.scroll-container #numericData .wtHolder,
.scroll-container #dateDate .wtHolder,
.scroll-container #timeData .wtHolder,
.scroll-container #checkbox .wtHolder,
.scroll-container #select .wtHolder,
.scroll-container #dropdown .wtHolder,
.scroll-container #autocomplete .wtHolder,
.scroll-container #jQuery .wtHolder,
.scroll-container #chromaJS .wtHolder,
.scroll-container #context .wtHolder,
.scroll-container #configuration .wtHolder,
.scroll-container #copyPaste .wtHolder,
.scroll-container #buttons .wtHolder,
.scroll-container #comments .wtHolder {
  height: auto !important; }

.handson-pagination {
  margin-top: 30px; }

.currentRow {
  background-color: #F9F9FB !important; }

.currentCol {
  background-color: #E7E8EF !important; }

.contact-table tr td:nth-child(n-2) {
  text-align: center; }

.contact-table tr td:last-child {
  position: relative; }
  .contact-table tr td:last-child .dropdown-menu {
    top: 52px; }

/**====== Handson css end ======**/
#checkbox-select tr td.select-checkbox, #checkbox-select tr th.select-checkbox {
  padding-left: 30px; }
  #checkbox-select tr td.select-checkbox:before, #checkbox-select tr td.select-checkbox:after, #checkbox-select tr th.select-checkbox:before, #checkbox-select tr th.select-checkbox:after {
    left: 15px; }

.table td, .table th {
  padding: 1.25rem 0.75rem; }

.table thead th {
  border-bottom: 1px solid #d6dde1; }

.invoive-info .table td, .invoive-info .table th,
.invoice-box .table td,
.invoice-box .table th {
  padding: 3px 0; }

table.invoice-table td, table.invoice-table th {
  padding: 3px 0; }

th {
  font-weight: 500; }

.per-task-card .card-block {
  padding: 0; }
.per-task-card .per-task-block {
  margin-left: 0;
  margin-right: 0; }
  .per-task-card .per-task-block > div {
    padding-top: 20px;
    padding-bottom: 20px; }
    .per-task-card .per-task-block > div:nth-child(odd) {
      padding-left: 0; }
    .per-task-card .per-task-block > div:nth-child(even) {
      background-color: rgba(68, 138, 255, 0.1);
      padding-right: 0; }

.social-card {
  color: #fff;
  overflow: hidden; }
  .social-card .social-icon {
    background-color: #fff;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 25px 0; }
  .social-card .download-icon {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 125px;
    width: 125px;
    right: -125px;
    font-size: 35px;
    padding: 40px 22px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .social-card:hover .download-icon {
    right: -60px; }

.social-user .card-block {
  position: relative; }
  .social-user .card-block .bg-icon {
    position: absolute;
    font-size: 95px;
    opacity: 0.1;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 110px);
    text-align: center;
    padding: 10px 0; }

.statustic-progress-card h6 {
  position: relative; }
  .statustic-progress-card h6:after {
    content: "";
    background-color: #448aff;
    position: absolute;
    left: -20px;
    top: 0;
    width: 4px;
    height: 20px; }
.statustic-progress-card .progress {
  height: 4px; }

.order-visitor-card {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .order-visitor-card:hover {
    background-color: #448aff;
    color: #fff; }

.wather-card {
  overflow: hidden; }
  .wather-card .nature-card {
    position: relative;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #5e9aff), to(#448aff));
    background: linear-gradient(#5e9aff 60%, #448aff 100%);
    overflow: hidden; }
    .wather-card .nature-card .main-img {
      width: 100%; }
      @media only screen and (min-width: 1400px) {
        .wather-card .nature-card .main-img {
          height: 260px; } }
    .wather-card .nature-card .bottom-img {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%; }
    .wather-card .nature-card .snow1,
    .wather-card .nature-card .snow2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
    .wather-card .nature-card .snow1 {
      -webkit-animation: sparcle 2.1s linear infinite;
              animation: sparcle 2.1s linear infinite; }
    .wather-card .nature-card .snow2 {
      -webkit-animation: sparcle 2.1s linear infinite;
              animation: sparcle 2.1s linear infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s; }
    .wather-card .nature-card .nature-cont {
      position: absolute;
      top: 20px;
      text-align: center;
      width: 100%; }

@-webkit-keyframes sparcle {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  50% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); } }

@keyframes sparcle {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  50% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); } }
.wather-card-2 {
  overflow: hidden; }
  .wather-card-2 .nature-card {
    position: relative;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #5e9aff), to(#448aff));
    background: linear-gradient(#5e9aff 60%, #448aff 100%); }
    .wather-card-2 .nature-card .bottom-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
    .wather-card-2 .nature-card .mountain {
      width: 100%; }
      @media only screen and (min-width: 1400px) {
        .wather-card-2 .nature-card .mountain {
          height: 260px; } }
    .wather-card-2 .nature-card .snow1,
    .wather-card-2 .nature-card .snow2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
    .wather-card-2 .nature-card .snow1 {
      -webkit-animation: sparcle2 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: sparcle2 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .wather-card-2 .nature-card .snow2 {
      -webkit-animation: sparcle2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: sparcle2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s; }
    .wather-card-2 .nature-card .nature-cont {
      position: absolute;
      top: 20px;
      text-align: center;
      width: 100%; }
    .wather-card-2 .nature-card .sun {
      position: absolute;
      top: 45px;
      left: 45px;
      width: 1px;
      height: 1px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.8); }
      .wather-card-2 .nature-card .sun:after, .wather-card-2 .nature-card .sun:before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute; }
      .wather-card-2 .nature-card .sun:after {
        -webkit-animation: sunsine 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
                animation: sunsine 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
      .wather-card-2 .nature-card .sun:before {
        -webkit-animation: sunsine 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
                animation: sunsine 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        -webkit-animation-delay: 1.15s;
                animation-delay: 1.15s; }

@-webkit-keyframes sparcle2 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes sparcle2 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }
@-webkit-keyframes sunsine {
  0% {
    -webkit-box-shadow: 0 0 7px 14px rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 7px 14px rgba(255, 255, 255, 0.9); }
  50% {
    -webkit-box-shadow: 0 0 8px 18px white;
            box-shadow: 0 0 8px 18px white; } }
@keyframes sunsine {
  0% {
    -webkit-box-shadow: 0 0 7px 14px rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 7px 14px rgba(255, 255, 255, 0.9); }
  50% {
    -webkit-box-shadow: 0 0 8px 18px white;
            box-shadow: 0 0 8px 18px white; } }
.user-card .usre-image {
  position: relative;
  display: inline-block;
  margin: 0 auto; }
  .user-card .usre-image img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #448aff;
    padding: 4px; }
  .user-card .usre-image:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    background-color: #11c15b;
    border-radius: 50%;
    padding: 0 3px; }
  .user-card .usre-image:after {
    content: "Permenant";
    position: absolute;
    bottom: -15px;
    left: calc(100% - 27px);
    color: #fff;
    background-color: #448aff;
    border-radius: 5px;
    padding: 0 3px; }
.user-card .btn {
  margin: 10px auto 15px; }

.user-card2 .risk-rate {
  display: inline-block;
  margin: 0 auto; }
  .user-card2 .risk-rate span {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 6px dashed #d6d6d6;
    border-top-color: #448aff;
    border-bottom-color: transparent;
    padding: 45px;
    display: block;
    position: relative; }
    .user-card2 .risk-rate span:after {
      content: "";
      width: 90px;
      height: 90px;
      background-color: rgba(68, 138, 255, 0.5);
      border-radius: 50%;
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 1; }
    .user-card2 .risk-rate span b {
      font-size: 20px;
      color: #fff;
      z-index: 2;
      position: relative; }

.user-card-full {
  overflow: hidden; }
  .user-card-full .user-profile {
    border-radius: 5px 0 0 5px; }
  .user-card-full .social-link {
    margin-bottom: 35px; }
    .user-card-full .social-link li {
      display: inline-block; }
      .user-card-full .social-link li a {
        font-size: 20px;
        margin: 0 10px 0 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        .user-card-full .social-link li a i {
          color: #666; }
        .user-card-full .social-link li a i.facebook:hover {
          color: #3B5997; }
        .user-card-full .social-link li a i.twitter:hover {
          color: #42C0FB; }
        .user-card-full .social-link li a i.instagram:hover {
          color: #AA7C62; }
        .user-card-full .social-link li a i.youtube:hover {
          color: #E0291D; }

.office-card .progress {
  height: 4px; }

.trafic-card .progress {
  height: 7px; }
  .trafic-card .progress.blue {
    background-color: rgba(68, 138, 255, 0.5); }
  .trafic-card .progress.green {
    background-color: rgba(17, 193, 91, 0.5); }
  .trafic-card .progress.red {
    background-color: rgba(255, 82, 82, 0.5); }
  .trafic-card .progress.purple {
    background-color: rgba(83, 109, 254, 0.5); }
  .trafic-card .progress.yellow {
    background-color: rgba(255, 225, 0, 0.5); }

.table-card .card-block {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }
  .table-card .card-block .table > thead > tr > th {
    border-top: 0; }
  .table-card .card-block .table .chk-option {
    vertical-align: middle;
    width: 30px;
    display: inline-block; }
    .table-card .card-block .table .chk-option .checkbox-fade,
    .table-card .card-block .table .chk-option .checkbox-fade .cr,
    .table-card .card-block .table .chk-option .checkbox-zoom,
    .table-card .card-block .table .chk-option .checkbox-zoom .cr {
      margin-right: 0; }
  .table-card .card-block .table label {
    margin-bottom: 0; }
  .table-card .card-block .table tr td:first-child,
  .table-card .card-block .table tr th:first-child {
    padding-left: 20px; }
  .table-card .card-block .table tr td:last-child,
  .table-card .card-block .table tr th:last-child {
    padding-right: 20px; }

.user-activity-card .u-img {
  position: relative; }
  .user-activity-card .u-img .cover-img {
    width: 40px;
    height: 40px; }
  .user-activity-card .u-img .profile-img {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: -5px; }

.user-Messages-card .u-img {
  position: relative; }
  .user-Messages-card .u-img .profile-img {
    width: 40px;
    height: 40px; }
  .user-Messages-card .u-img .tot-msg {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    right: -5px;
    background-color: #ff5252;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    text-align: center; }

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative; }
  .chat-card .msg img {
    width: 60px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px; }
.chat-card .received-chat .msg {
  background: rgba(68, 138, 255, 0.1);
  border-radius: 0 5px 5px 5px; }
  .chat-card .received-chat .msg:after {
    content: "";
    position: absolute;
    left: -7px;
    top: -7px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border: 7px solid transparent;
    border-bottom-color: rgba(68, 138, 255, 0.1); }
.chat-card .send-chat {
  text-align: right; }
  .chat-card .send-chat .msg {
    background: rgba(17, 193, 91, 0.1);
    border-radius: 5px 0 5px 5px; }
    .chat-card .send-chat .msg:after {
      content: "";
      position: absolute;
      right: -7px;
      top: -7px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      border: 7px solid transparent;
      border-right-color: rgba(17, 193, 91, 0.1); }

.latest-update-card .card-block {
  padding-top: 0; }
  .latest-update-card .card-block .latest-update-box {
    position: relative; }
    .latest-update-card .card-block .latest-update-box:after {
      content: "";
      position: absolute;
      background: #d6d6d6;
      height: 100%;
      width: 1px;
      top: 0;
      left: 110px;
      z-index: 1; }
    .latest-update-card .card-block .latest-update-box .update-meta {
      z-index: 2;
      min-width: 160px; }
      .latest-update-card .card-block .latest-update-box .update-meta .update-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding: 12px 0;
        font-size: 16px;
        color: #fff;
        margin-left: 10px; }
    @media only screen and (max-width: 575px) {
      .latest-update-card .card-block .latest-update-box:after {
        display: none; }
      .latest-update-card .card-block .latest-update-box .update-meta {
        z-index: 2;
        min-width: 100%;
        text-align: left !important;
        margin-bottom: 15px;
        border-top: 1px solid #f1f1f1;
        padding-top: 15px; } }

.latest-posts-card .latest-posts-img {
  width: 90px;
  height: 80px; }

.task-card .task-list {
  position: relative; }
  .task-card .task-list:after {
    content: "";
    position: absolute;
    background: #d6d6d6;
    height: 100%;
    width: 2px;
    top: 0;
    left: 10px;
    z-index: 1; }
  .task-card .task-list:before {
    content: "";
    position: absolute;
    background: #d6d6d6;
    height: 15px;
    width: 15px;
    bottom: -14px;
    left: 3px;
    z-index: 2;
    border-radius: 50%; }
  .task-card .task-list li {
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative; }
    .task-card .task-list li .task-icon {
      position: absolute;
      left: 3px;
      top: 1px;
      border-radius: 50%;
      padding: 2px;
      color: #fff;
      min-width: 15px;
      min-height: 15px;
      z-index: 2; }

.latest-activity-card .card-block {
  padding-top: 0; }
  .latest-activity-card .card-block .latest-update-box {
    position: relative; }
    .latest-activity-card .card-block .latest-update-box .update-meta {
      z-index: 2;
      min-width: 160px; }
      .latest-activity-card .card-block .latest-update-box .update-meta .update-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding: 12px 0;
        font-size: 16px;
        color: #fff;
        margin-left: 10px; }
    @media only screen and (max-width: 575px) {
      .latest-activity-card .card-block .latest-update-box .update-meta {
        z-index: 2;
        min-width: 100%;
        text-align: left !important;
        margin-bottom: 15px;
        border-top: 1px solid #f1f1f1;
        padding-top: 15px; } }

.feed-card h6 {
  margin-top: 7px; }
.feed-card .feed-icon {
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 7px 0; }

.latest-activity-card .table td {
  vertical-align: middle; }

.review-card .review-block .cust-img {
  width: 50px;
  height: 50px; }
.review-card .review-block > div {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px; }
  .review-card .review-block > div:hover {
    background-color: rgba(68, 138, 255, 0.1); }

#app-sale1,
#app-sale2,
#app-sale3,
#app-sale4 {
  height: 46px !important;
  width: 100px !important; }

#proj-status1,
#proj-status2,
#proj-status3 {
  height: 30px !important;
  width: 30px !important; }

#top-3-prod {
  height: 100px !important;
  width: 100px !important;
  margin: 0 auto; }

.quater-card .progress {
  height: 4px; }

#sale-chart1,
#sale-chart2,
#sale-chart3 {
  height: 150px !important;
  width: 100%; }

/**  =====================
      Alert css start
==========================  **/
.alert {
  font-size: 15px;
  margin-bottom: 2rem; }

.alert.icons-alert {
  border-left-width: 48px; }
  .alert.icons-alert .alert-icon {
    left: 15px;
    position: relative;
    text-align: center;
    top: 0;
    z-index: 3; }
  .alert.icons-alert p {
    line-height: 21px;
    margin-bottom: 0; }

.close {
  font-size: 16px;
  margin-top: 5px; }

/*===== Primary alert ===== */
.alert-default {
  background-color: #fff;
  border-color: #d6d6d6;
  color: #d6d6d6; }

.alert-primary {
  background-color: #fff;
  border-color: #448aff;
  color: #448aff; }

.alert-success {
  background-color: #fff;
  border-color: #11c15b;
  color: #11c15b; }

.alert-info {
  background-color: #fff;
  border-color: #00bcd4;
  color: #00bcd4; }

.alert-warning {
  background-color: #fff;
  border-color: #ffe100;
  color: #ffe100; }

.alert-danger {
  background-color: #fff;
  border-color: #ff5252;
  color: #ff5252; }

/*===== Border alert ===== */
.border-default {
  border-color: transparent;
  border-left: 3px solid #d6d6d6;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px #999;
          box-shadow: 0 0 1px #999;
  color: #d6d6d6; }

.border-primary {
  border-color: transparent;
  border-left: 3px solid #448aff;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px #999;
          box-shadow: 0 0 1px #999;
  color: #448aff; }

.border-success {
  border-color: transparent;
  border-left: 3px solid #11c15b;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px #999;
          box-shadow: 0 0 1px #999;
  color: #11c15b; }

.border-info {
  border-color: transparent;
  border-left: 3px solid #00bcd4;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px #999;
          box-shadow: 0 0 1px #999;
  color: #00bcd4; }

.border-warning {
  border-color: transparent;
  border-left: 3px solid #ffe100;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px #999;
          box-shadow: 0 0 1px #999;
  color: #ffe100; }

.border-danger {
  border-color: transparent;
  border-left: 3px solid #ff5252;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px #999;
          box-shadow: 0 0 1px #999;
  color: #ff5252; }

/*===== Solid-background alert ===== */
.background-default {
  background-color: #d6d6d6;
  color: #fff; }

.background-primary {
  background-color: #448aff;
  color: #fff; }

.background-success {
  background-color: #11c15b;
  color: #fff; }

.background-info {
  background-color: #00bcd4;
  color: #fff; }

.background-warning {
  background-color: #ffe100;
  color: #fff; }

.background-danger {
  background-color: #ff5252;
  color: #fff; }

.icons-alert {
  position: relative; }
  .icons-alert:before {
    color: #fff;
    content: '\f027';
    font-family: 'IcoFont' !important;
    font-size: 16px;
    left: -30px;
    position: absolute;
    top: 20px; }

[class*="alert-"] code {
  margin-left: 10px; }

/*======= Alert css ends ===========*/
/* =======================================
      Authentication-page css start
 ========================================= */
.login-block {
  margin: 30px auto; }
  .login-block .auth-box {
    margin: 20px auto 0 auto;
    max-width: 450px; }
    .login-block .auth-box .confirm h3 {
      color: #448aff;
      font-size: 34px; }
    .login-block .auth-box i.icofont-check-circled {
      font-size: 42px; }
  .login-block.offline-404 .auth-box {
    max-width: 650px; }
    .login-block.offline-404 .auth-box h1 {
      color: #2c3e50;
      font-size: 160px;
      font-weight: 600;
      letter-spacing: 5px;
      text-shadow: 3px -2px 4px rgba(128, 128, 128, 0.57); }

.footer {
  background-color: #37474f;
  color: #fff;
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  width: 100%; }

/**====== Authentication-page css end ======**/
/**  =====================
      Breadcrumb css start
==========================  **/
.caption-breadcrumb .breadcrumb-header, .primary-breadcrumb .breadcrumb-header, .inverse-breadcrumb .breadcrumb-header, .danger-breadcrumb .breadcrumb-header, .info-breadcrumb .breadcrumb-header, .warning-breadcrumb .breadcrumb-header, .success-breadcrumb .breadcrumb-header {
  display: inline-block; }
  .caption-breadcrumb .breadcrumb-header span, .primary-breadcrumb .breadcrumb-header span, .inverse-breadcrumb .breadcrumb-header span, .danger-breadcrumb .breadcrumb-header span, .info-breadcrumb .breadcrumb-header span, .warning-breadcrumb .breadcrumb-header span, .success-breadcrumb .breadcrumb-header span {
    display: block;
    font-size: 13px;
    margin-top: 5px; }

.front-icon-breadcrumb .breadcrumb-header {
  display: inline-block; }
.front-icon-breadcrumb .big-icon {
  display: inline-block; }
  .front-icon-breadcrumb .big-icon i {
    font-size: 50px;
    margin-right: 10px;
    color: #448aff; }
.front-icon-breadcrumb .d-inline-block span {
  display: block;
  font-size: 13px;
  margin-top: 5px; }

.primary-breadcrumb, .inverse-breadcrumb, .danger-breadcrumb, .info-breadcrumb, .warning-breadcrumb, .success-breadcrumb {
  background-color: #448aff;
  color: #fff; }
  .primary-breadcrumb h5, .inverse-breadcrumb h5, .danger-breadcrumb h5, .info-breadcrumb h5, .warning-breadcrumb h5, .success-breadcrumb h5, .primary-breadcrumb a, .inverse-breadcrumb a, .danger-breadcrumb a, .info-breadcrumb a, .warning-breadcrumb a, .success-breadcrumb a, .primary-breadcrumb .breadcrumb-title li:last-child a, .inverse-breadcrumb .breadcrumb-title li:last-child a, .danger-breadcrumb .breadcrumb-title li:last-child a, .info-breadcrumb .breadcrumb-title li:last-child a, .warning-breadcrumb .breadcrumb-title li:last-child a, .success-breadcrumb .breadcrumb-title li:last-child a, .primary-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .inverse-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .danger-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .info-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .warning-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .success-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #fff; }

.inverse-breadcrumb {
  background-color: #37474f; }

.danger-breadcrumb {
  background-color: #ff5252; }

.info-breadcrumb {
  background-color: #00bcd4; }

.warning-breadcrumb {
  background-color: #ffe100; }

.success-breadcrumb {
  background-color: #11c15b; }

/**====== Breadcrumb css end ======**/
/**  =====================
      Chart css start
==========================  **/
.nvd-chart {
  height: 400px; }

.peity-chart .peity {
  width: 100%;
  height: 250px; }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }
.morris-hover {
  position: absolute;
  min-width: 100px;
  width: 100px;
  right: 0;
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc; }

/**====== Chart css end ======**/
.rickshaw_graph svg {
  width: 100% !important; }

/* =======================
   Start Color-page Css
=========================== */
.amber-colors ul li p,
.bg-danger li p,
.bg-default li p,
.bg-info li p,
.bg-primary li p,
.bg-success li p,
.bg-warning li p,
.blue-colors ul li p,
.blue-grey-colors ul li p,
.brown-colors ul li p,
.cyan-colors ul li p,
.deep-orange-colors ul li p,
.deep-purple-colors ul li p,
.green-colors ul li p,
.grey-colors ul li p,
.indigo-colors ul li p,
.light-blue-colors ul li p,
.light-green-colors ul li p,
.lime-colors ul li p,
.orange-colors ul li p,
.pink-colors ul li p,
.purple-colors ul li p,
.red-colors ul li p,
.teal-colors ul li p,
.yellow-colors ul li p,
.primary-colorr ul li p,
.success-colorr ul li p,
.info-colorr ul li p,
.warning-colorr ul li p,
.danger-colorr ul li p {
  font-size: 18px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0; }

/**====== Accent-red-color css start ======**/
.red-colors ul li:nth-child(14) {
  background-color: #e40505; }
  .red-colors ul li:nth-child(14) p:before {
    content: "#e40505"; }

.red-colors ul li:nth-child(13) {
  background-color: #f30606; }
  .red-colors ul li:nth-child(13) p:before {
    content: "#f30606"; }

.red-colors ul li:nth-child(12) {
  background-color: #f90f0f; }
  .red-colors ul li:nth-child(12) p:before {
    content: "#f90f0f"; }

.red-colors ul li:nth-child(11) {
  background-color: #fa1d1d; }
  .red-colors ul li:nth-child(11) p:before {
    content: "#fa1d1d"; }

.red-colors ul li:nth-child(10) {
  background-color: #fa2c2c; }
  .red-colors ul li:nth-child(10) p:before {
    content: "#fa2c2c"; }

.red-colors ul li:nth-child(9) {
  background-color: #fa3b3b; }
  .red-colors ul li:nth-child(9) p:before {
    content: "#fa3b3b"; }

.red-colors ul li:nth-child(8) {
  background-color: #fb4a4a; }
  .red-colors ul li:nth-child(8) p:before {
    content: "#fb4a4a"; }

.red-colors ul li:nth-child(7) {
  background-color: #fb5959; }
  .red-colors ul li:nth-child(7) p:before {
    content: "#fb5959"; }

.red-colors ul li:nth-child(6) {
  background-color: #fb6868; }
  .red-colors ul li:nth-child(6) p:before {
    content: "#fb6868"; }

.red-colors ul li:nth-child(5) {
  background-color: #fc7777; }
  .red-colors ul li:nth-child(5) p:before {
    content: "#fc7777"; }

.red-colors ul li:nth-child(4) {
  background-color: #fc8686; }
  .red-colors ul li:nth-child(4) p:before {
    content: "#fc8686"; }

.red-colors ul li:nth-child(3) {
  background-color: #fd9595; }
  .red-colors ul li:nth-child(3) p:before {
    content: "#fd9595"; }

.red-colors ul li:nth-child(2) {
  background-color: #fda4a4; }
  .red-colors ul li:nth-child(2) p:before {
    content: "#fda4a4"; }

.red-colors ul li:nth-child(1) {
  background-color: #fdb3b3; }
  .red-colors ul li:nth-child(1) p:before {
    content: "#fdb3b3"; }

/**====== Accent-red-color css end ======**/
/**====== Accent-pink-color css start ======**/
.pink-colors ul li:nth-child(14) {
  background-color: #d4166c; }
  .pink-colors ul li:nth-child(14) p:before {
    content: "#d4166c"; }

.pink-colors ul li:nth-child(13) {
  background-color: #e21873; }
  .pink-colors ul li:nth-child(13) p:before {
    content: "#e21873"; }

.pink-colors ul li:nth-child(12) {
  background-color: #e7217b; }
  .pink-colors ul li:nth-child(12) p:before {
    content: "#e7217b"; }

.pink-colors ul li:nth-child(11) {
  background-color: #e92f83; }
  .pink-colors ul li:nth-child(11) p:before {
    content: "#e92f83"; }

.pink-colors ul li:nth-child(10) {
  background-color: #ea3d8b; }
  .pink-colors ul li:nth-child(10) p:before {
    content: "#ea3d8b"; }

.pink-colors ul li:nth-child(9) {
  background-color: #ec4b94; }
  .pink-colors ul li:nth-child(9) p:before {
    content: "#ec4b94"; }

.pink-colors ul li:nth-child(8) {
  background-color: #ed599c; }
  .pink-colors ul li:nth-child(8) p:before {
    content: "#ed599c"; }

.pink-colors ul li:nth-child(7) {
  background-color: #ef67a4; }
  .pink-colors ul li:nth-child(7) p:before {
    content: "#ef67a4"; }

.pink-colors ul li:nth-child(6) {
  background-color: #f074ac; }
  .pink-colors ul li:nth-child(6) p:before {
    content: "#f074ac"; }

.pink-colors ul li:nth-child(5) {
  background-color: #f282b5; }
  .pink-colors ul li:nth-child(5) p:before {
    content: "#f282b5"; }

.pink-colors ul li:nth-child(4) {
  background-color: #f390bd; }
  .pink-colors ul li:nth-child(4) p:before {
    content: "#f390bd"; }

.pink-colors ul li:nth-child(3) {
  background-color: #f59ec5; }
  .pink-colors ul li:nth-child(3) p:before {
    content: "#f59ec5"; }

.pink-colors ul li:nth-child(2) {
  background-color: #f6accd; }
  .pink-colors ul li:nth-child(2) p:before {
    content: "#f6accd"; }

.pink-colors ul li:nth-child(1) {
  background-color: #f8bad6; }
  .pink-colors ul li:nth-child(1) p:before {
    content: "#f8bad6"; }

/**====== Accent-pink-color css end ======**/
/**====== Accent-purple-color css start ======**/
.purple-colors ul li:nth-child(14) {
  background-color: #b014ff; }
  .purple-colors ul li:nth-child(14) p:before {
    content: "#b014ff"; }

.purple-colors ul li:nth-child(13) {
  background-color: #b524ff; }
  .purple-colors ul li:nth-child(13) p:before {
    content: "#b524ff"; }

.purple-colors ul li:nth-child(12) {
  background-color: #ba33ff; }
  .purple-colors ul li:nth-child(12) p:before {
    content: "#ba33ff"; }

.purple-colors ul li:nth-child(11) {
  background-color: #c042ff; }
  .purple-colors ul li:nth-child(11) p:before {
    content: "#c042ff"; }

.purple-colors ul li:nth-child(10) {
  background-color: #c552ff; }
  .purple-colors ul li:nth-child(10) p:before {
    content: "#c552ff"; }

.purple-colors ul li:nth-child(9) {
  background-color: #ca61ff; }
  .purple-colors ul li:nth-child(9) p:before {
    content: "#ca61ff"; }

.purple-colors ul li:nth-child(8) {
  background-color: #cf70ff; }
  .purple-colors ul li:nth-child(8) p:before {
    content: "#cf70ff"; }

.purple-colors ul li:nth-child(7) {
  background-color: #d47fff; }
  .purple-colors ul li:nth-child(7) p:before {
    content: "#d47fff"; }

.purple-colors ul li:nth-child(6) {
  background-color: #d98fff; }
  .purple-colors ul li:nth-child(6) p:before {
    content: "#d98fff"; }

.purple-colors ul li:nth-child(5) {
  background-color: #de9eff; }
  .purple-colors ul li:nth-child(5) p:before {
    content: "#de9eff"; }

.purple-colors ul li:nth-child(4) {
  background-color: #e4adff; }
  .purple-colors ul li:nth-child(4) p:before {
    content: "#e4adff"; }

.purple-colors ul li:nth-child(3) {
  background-color: #e9bdff; }
  .purple-colors ul li:nth-child(3) p:before {
    content: "#e9bdff"; }

.purple-colors ul li:nth-child(2) {
  background-color: #eeccff; }
  .purple-colors ul li:nth-child(2) p:before {
    content: "#eeccff"; }

.purple-colors ul li:nth-child(1) {
  background-color: #f3dbff; }
  .purple-colors ul li:nth-child(1) p:before {
    content: "#f3dbff"; }

/**====== Accent-purple-color css end ======**/
/**====== Deep-purple-color css start ======**/
.deep-purple-colors ul li:nth-child(14) {
  background-color: #6b05f9; }
  .deep-purple-colors ul li:nth-child(14) p:before {
    content: "#6b05f9"; }

.deep-purple-colors ul li:nth-child(13) {
  background-color: #7414fa; }
  .deep-purple-colors ul li:nth-child(13) p:before {
    content: "#7414fa"; }

.deep-purple-colors ul li:nth-child(12) {
  background-color: #7d23fa; }
  .deep-purple-colors ul li:nth-child(12) p:before {
    content: "#7d23fa"; }

.deep-purple-colors ul li:nth-child(11) {
  background-color: #8632fb; }
  .deep-purple-colors ul li:nth-child(11) p:before {
    content: "#8632fb"; }

.deep-purple-colors ul li:nth-child(10) {
  background-color: #8f41fb; }
  .deep-purple-colors ul li:nth-child(10) p:before {
    content: "#8f41fb"; }

.deep-purple-colors ul li:nth-child(9) {
  background-color: #9850fb; }
  .deep-purple-colors ul li:nth-child(9) p:before {
    content: "#9850fb"; }

.deep-purple-colors ul li:nth-child(8) {
  background-color: #a05ffc; }
  .deep-purple-colors ul li:nth-child(8) p:before {
    content: "#a05ffc"; }

.deep-purple-colors ul li:nth-child(7) {
  background-color: #a96efc; }
  .deep-purple-colors ul li:nth-child(7) p:before {
    content: "#a96efc"; }

.deep-purple-colors ul li:nth-child(6) {
  background-color: #b27cfc; }
  .deep-purple-colors ul li:nth-child(6) p:before {
    content: "#b27cfc"; }

.deep-purple-colors ul li:nth-child(5) {
  background-color: #bb8bfd; }
  .deep-purple-colors ul li:nth-child(5) p:before {
    content: "#bb8bfd"; }

.deep-purple-colors ul li:nth-child(4) {
  background-color: #c49afd; }
  .deep-purple-colors ul li:nth-child(4) p:before {
    content: "#c49afd"; }

.deep-purple-colors ul li:nth-child(3) {
  background-color: #cda9fd; }
  .deep-purple-colors ul li:nth-child(3) p:before {
    content: "#cda9fd"; }

.deep-purple-colors ul li:nth-child(2) {
  background-color: #d5b8fd; }
  .deep-purple-colors ul li:nth-child(2) p:before {
    content: "#d5b8fd"; }

.deep-purple-colors ul li:nth-child(1) {
  background-color: #dec7fe; }
  .deep-purple-colors ul li:nth-child(1) p:before {
    content: "#dec7fe"; }

/**====== Deep-purple-color css end ======**/
/**====== Indigo-color css start ======**/
.indigo-colors ul li:nth-child(14) {
  background-color: #415dfe; }
  .indigo-colors ul li:nth-child(14) p:before {
    content: "#415dfe"; }

.indigo-colors ul li:nth-child(13) {
  background-color: #4d68fe; }
  .indigo-colors ul li:nth-child(13) p:before {
    content: "#4d68fe"; }

.indigo-colors ul li:nth-child(12) {
  background-color: #5a72fe; }
  .indigo-colors ul li:nth-child(12) p:before {
    content: "#5a72fe"; }

.indigo-colors ul li:nth-child(11) {
  background-color: #677dfe; }
  .indigo-colors ul li:nth-child(11) p:before {
    content: "#677dfe"; }

.indigo-colors ul li:nth-child(10) {
  background-color: #7388fe; }
  .indigo-colors ul li:nth-child(10) p:before {
    content: "#7388fe"; }

.indigo-colors ul li:nth-child(9) {
  background-color: #8093fe; }
  .indigo-colors ul li:nth-child(9) p:before {
    content: "#8093fe"; }

.indigo-colors ul li:nth-child(8) {
  background-color: #8d9efe; }
  .indigo-colors ul li:nth-child(8) p:before {
    content: "#8d9efe"; }

.indigo-colors ul li:nth-child(7) {
  background-color: #9aa9ff; }
  .indigo-colors ul li:nth-child(7) p:before {
    content: "#9aa9ff"; }

.indigo-colors ul li:nth-child(6) {
  background-color: #a6b3ff; }
  .indigo-colors ul li:nth-child(6) p:before {
    content: "#a6b3ff"; }

.indigo-colors ul li:nth-child(5) {
  background-color: #b3beff; }
  .indigo-colors ul li:nth-child(5) p:before {
    content: "#b3beff"; }

.indigo-colors ul li:nth-child(4) {
  background-color: #c0c9ff; }
  .indigo-colors ul li:nth-child(4) p:before {
    content: "#c0c9ff"; }

.indigo-colors ul li:nth-child(3) {
  background-color: #ccd4ff; }
  .indigo-colors ul li:nth-child(3) p:before {
    content: "#ccd4ff"; }

.indigo-colors ul li:nth-child(2) {
  background-color: #d9dfff; }
  .indigo-colors ul li:nth-child(2) p:before {
    content: "#d9dfff"; }

.indigo-colors ul li:nth-child(1) {
  background-color: #e6e9ff; }
  .indigo-colors ul li:nth-child(1) p:before {
    content: "#e6e9ff"; }

/**====== Indigo-color css end ======**/
/**====== Blue-accent-color css start ======**/
.blue-colors ul li:nth-child(14) {
  background-color: #3a6eff; }
  .blue-colors ul li:nth-child(14) p:before {
    content: "#3a6eff"; }

.blue-colors ul li:nth-child(13) {
  background-color: #4778ff; }
  .blue-colors ul li:nth-child(13) p:before {
    content: "#4778ff"; }

.blue-colors ul li:nth-child(12) {
  background-color: #5381ff; }
  .blue-colors ul li:nth-child(12) p:before {
    content: "#5381ff"; }

.blue-colors ul li:nth-child(11) {
  background-color: #608aff; }
  .blue-colors ul li:nth-child(11) p:before {
    content: "#608aff"; }

.blue-colors ul li:nth-child(10) {
  background-color: #6d94ff; }
  .blue-colors ul li:nth-child(10) p:before {
    content: "#6d94ff"; }

.blue-colors ul li:nth-child(9) {
  background-color: #7a9dff; }
  .blue-colors ul li:nth-child(9) p:before {
    content: "#7a9dff"; }

.blue-colors ul li:nth-child(8) {
  background-color: #86a6ff; }
  .blue-colors ul li:nth-child(8) p:before {
    content: "#86a6ff"; }

.blue-colors ul li:nth-child(7) {
  background-color: #93b0ff; }
  .blue-colors ul li:nth-child(7) p:before {
    content: "#93b0ff"; }

.blue-colors ul li:nth-child(6) {
  background-color: #a0b9ff; }
  .blue-colors ul li:nth-child(6) p:before {
    content: "#a0b9ff"; }

.blue-colors ul li:nth-child(5) {
  background-color: #adc3ff; }
  .blue-colors ul li:nth-child(5) p:before {
    content: "#adc3ff"; }

.blue-colors ul li:nth-child(4) {
  background-color: #b9ccff; }
  .blue-colors ul li:nth-child(4) p:before {
    content: "#b9ccff"; }

.blue-colors ul li:nth-child(3) {
  background-color: #c6d5ff; }
  .blue-colors ul li:nth-child(3) p:before {
    content: "#c6d5ff"; }

.blue-colors ul li:nth-child(2) {
  background-color: #d3dfff; }
  .blue-colors ul li:nth-child(2) p:before {
    content: "#d3dfff"; }

.blue-colors ul li:nth-child(1) {
  background-color: #e0e8ff; }
  .blue-colors ul li:nth-child(1) p:before {
    content: "#e0e8ff"; }

/**====== Blue-accent-color css end ======**/
/**====== Light-blue-color css start ======**/
.light-blue-colors ul li:nth-child(14) {
  background-color: #059cf9; }
  .light-blue-colors ul li:nth-child(14) p:before {
    content: "#059cf9"; }

.light-blue-colors ul li:nth-child(13) {
  background-color: #14a2fa; }
  .light-blue-colors ul li:nth-child(13) p:before {
    content: "#14a2fa"; }

.light-blue-colors ul li:nth-child(12) {
  background-color: #23a8fa; }
  .light-blue-colors ul li:nth-child(12) p:before {
    content: "#23a8fa"; }

.light-blue-colors ul li:nth-child(11) {
  background-color: #32aefb; }
  .light-blue-colors ul li:nth-child(11) p:before {
    content: "#32aefb"; }

.light-blue-colors ul li:nth-child(10) {
  background-color: #41b4fb; }
  .light-blue-colors ul li:nth-child(10) p:before {
    content: "#41b4fb"; }

.light-blue-colors ul li:nth-child(9) {
  background-color: #50bafb; }
  .light-blue-colors ul li:nth-child(9) p:before {
    content: "#50bafb"; }

.light-blue-colors ul li:nth-child(8) {
  background-color: #5fc0fc; }
  .light-blue-colors ul li:nth-child(8) p:before {
    content: "#5fc0fc"; }

.light-blue-colors ul li:nth-child(7) {
  background-color: #6ec6fc; }
  .light-blue-colors ul li:nth-child(7) p:before {
    content: "#6ec6fc"; }

.light-blue-colors ul li:nth-child(6) {
  background-color: #7cccfc; }
  .light-blue-colors ul li:nth-child(6) p:before {
    content: "#7cccfc"; }

.light-blue-colors ul li:nth-child(5) {
  background-color: #8bd2fd; }
  .light-blue-colors ul li:nth-child(5) p:before {
    content: "#8bd2fd"; }

.light-blue-colors ul li:nth-child(4) {
  background-color: #9ad7fd; }
  .light-blue-colors ul li:nth-child(4) p:before {
    content: "#9ad7fd"; }

.light-blue-colors ul li:nth-child(3) {
  background-color: #a9ddfd; }
  .light-blue-colors ul li:nth-child(3) p:before {
    content: "#a9ddfd"; }

.light-blue-colors ul li:nth-child(2) {
  background-color: #b8e3fd; }
  .light-blue-colors ul li:nth-child(2) p:before {
    content: "#b8e3fd"; }

.light-blue-colors ul li:nth-child(1) {
  background-color: #c7e9fe; }
  .light-blue-colors ul li:nth-child(1) p:before {
    content: "#c7e9fe"; }

/**====== Light-blue-color css end ======**/
/**====== Cyan-color css start ======**/
.cyan-colors ul li:nth-child(14) {
  background-color: #05c6e3; }
  .cyan-colors ul li:nth-child(14) p:before {
    content: "#05c6e3"; }

.cyan-colors ul li:nth-child(13) {
  background-color: #06d3f2; }
  .cyan-colors ul li:nth-child(13) p:before {
    content: "#06d3f2"; }

.cyan-colors ul li:nth-child(12) {
  background-color: #0edbf9; }
  .cyan-colors ul li:nth-child(12) p:before {
    content: "#0edbf9"; }

.cyan-colors ul li:nth-child(11) {
  background-color: #1dddfa; }
  .cyan-colors ul li:nth-child(11) p:before {
    content: "#1dddfa"; }

.cyan-colors ul li:nth-child(10) {
  background-color: #2bdffa; }
  .cyan-colors ul li:nth-child(10) p:before {
    content: "#2bdffa"; }

.cyan-colors ul li:nth-child(9) {
  background-color: #3ae1fa; }
  .cyan-colors ul li:nth-child(9) p:before {
    content: "#3ae1fa"; }

.cyan-colors ul li:nth-child(8) {
  background-color: #49e4fb; }
  .cyan-colors ul li:nth-child(8) p:before {
    content: "#49e4fb"; }

.cyan-colors ul li:nth-child(7) {
  background-color: #58e6fb; }
  .cyan-colors ul li:nth-child(7) p:before {
    content: "#58e6fb"; }

.cyan-colors ul li:nth-child(6) {
  background-color: #67e8fb; }
  .cyan-colors ul li:nth-child(6) p:before {
    content: "#67e8fb"; }

.cyan-colors ul li:nth-child(5) {
  background-color: #76eafc; }
  .cyan-colors ul li:nth-child(5) p:before {
    content: "#76eafc"; }

.cyan-colors ul li:nth-child(4) {
  background-color: #85edfc; }
  .cyan-colors ul li:nth-child(4) p:before {
    content: "#85edfc"; }

.cyan-colors ul li:nth-child(3) {
  background-color: #94effc; }
  .cyan-colors ul li:nth-child(3) p:before {
    content: "#94effc"; }

.cyan-colors ul li:nth-child(2) {
  background-color: #a3f1fd; }
  .cyan-colors ul li:nth-child(2) p:before {
    content: "#a3f1fd"; }

.cyan-colors ul li:nth-child(1) {
  background-color: #b2f3fd; }
  .cyan-colors ul li:nth-child(1) p:before {
    content: "#b2f3fd"; }

/**====== Cyan-color css start ======**/
/**====== Teal-color css start ======**/
.teal-colors ul li:nth-child(14) {
  background-color: #05cfb3; }
  .teal-colors ul li:nth-child(14) p:before {
    content: "#05cfb3"; }

.teal-colors ul li:nth-child(13) {
  background-color: #06dec0; }
  .teal-colors ul li:nth-child(13) p:before {
    content: "#06dec0"; }

.teal-colors ul li:nth-child(12) {
  background-color: #06edcd; }
  .teal-colors ul li:nth-child(12) p:before {
    content: "#06edcd"; }

.teal-colors ul li:nth-child(11) {
  background-color: #0af9d7; }
  .teal-colors ul li:nth-child(11) p:before {
    content: "#0af9d7"; }

.teal-colors ul li:nth-child(10) {
  background-color: #19f9da; }
  .teal-colors ul li:nth-child(10) p:before {
    content: "#19f9da"; }

.teal-colors ul li:nth-child(9) {
  background-color: #27f9dc; }
  .teal-colors ul li:nth-child(9) p:before {
    content: "#27f9dc"; }

.teal-colors ul li:nth-child(8) {
  background-color: #36fadf; }
  .teal-colors ul li:nth-child(8) p:before {
    content: "#36fadf"; }

.teal-colors ul li:nth-child(7) {
  background-color: #45fae1; }
  .teal-colors ul li:nth-child(7) p:before {
    content: "#45fae1"; }

.teal-colors ul li:nth-child(6) {
  background-color: #54fbe3; }
  .teal-colors ul li:nth-child(6) p:before {
    content: "#54fbe3"; }

.teal-colors ul li:nth-child(5) {
  background-color: #63fbe6; }
  .teal-colors ul li:nth-child(5) p:before {
    content: "#63fbe6"; }

.teal-colors ul li:nth-child(4) {
  background-color: #72fbe8; }
  .teal-colors ul li:nth-child(4) p:before {
    content: "#72fbe8"; }

.teal-colors ul li:nth-child(3) {
  background-color: #81fceb; }
  .teal-colors ul li:nth-child(3) p:before {
    content: "#81fceb"; }

.teal-colors ul li:nth-child(2) {
  background-color: #90fced; }
  .teal-colors ul li:nth-child(2) p:before {
    content: "#90fced"; }

.teal-colors ul li:nth-child(1) {
  background-color: #9ffcef; }
  .teal-colors ul li:nth-child(1) p:before {
    content: "#9ffcef"; }

/**====== Teal-color css end ======**/
/**====== Green-color css start ======**/
.green-colors ul li:nth-child(14) {
  background-color: #05d85c; }
  .green-colors ul li:nth-child(14) p:before {
    content: "#05d85c"; }

.green-colors ul li:nth-child(13) {
  background-color: #06e763; }
  .green-colors ul li:nth-child(13) p:before {
    content: "#06e763"; }

.green-colors ul li:nth-child(12) {
  background-color: #06f669; }
  .green-colors ul li:nth-child(12) p:before {
    content: "#06f669"; }

.green-colors ul li:nth-child(11) {
  background-color: #12f972; }
  .green-colors ul li:nth-child(11) p:before {
    content: "#12f972"; }

.green-colors ul li:nth-child(10) {
  background-color: #21f97a; }
  .green-colors ul li:nth-child(10) p:before {
    content: "#21f97a"; }

.green-colors ul li:nth-child(9) {
  background-color: #30fa83; }
  .green-colors ul li:nth-child(9) p:before {
    content: "#30fa83"; }

.green-colors ul li:nth-child(8) {
  background-color: #3ffa8c; }
  .green-colors ul li:nth-child(8) p:before {
    content: "#3ffa8c"; }

.green-colors ul li:nth-child(7) {
  background-color: #4efb95; }
  .green-colors ul li:nth-child(7) p:before {
    content: "#4efb95"; }

.green-colors ul li:nth-child(6) {
  background-color: #5dfb9e; }
  .green-colors ul li:nth-child(6) p:before {
    content: "#5dfb9e"; }

.green-colors ul li:nth-child(5) {
  background-color: #6cfba7; }
  .green-colors ul li:nth-child(5) p:before {
    content: "#6cfba7"; }

.green-colors ul li:nth-child(4) {
  background-color: #7bfcb0; }
  .green-colors ul li:nth-child(4) p:before {
    content: "#7bfcb0"; }

.green-colors ul li:nth-child(3) {
  background-color: #8afcb9; }
  .green-colors ul li:nth-child(3) p:before {
    content: "#8afcb9"; }

.green-colors ul li:nth-child(2) {
  background-color: #98fcc2; }
  .green-colors ul li:nth-child(2) p:before {
    content: "#98fcc2"; }

.green-colors ul li:nth-child(1) {
  background-color: #a7fdcb; }
  .green-colors ul li:nth-child(1) p:before {
    content: "#a7fdcb"; }

/**====== Green-color css end ======**/
/**====== Light-Green-color css start ======**/
.light-green-colors ul li:nth-child(14) {
  background-color: #6fe423; }
  .light-green-colors ul li:nth-child(14) p:before {
    content: "#6fe423"; }

.light-green-colors ul li:nth-child(13) {
  background-color: #78e631; }
  .light-green-colors ul li:nth-child(13) p:before {
    content: "#78e631"; }

.light-green-colors ul li:nth-child(12) {
  background-color: #81e73e; }
  .light-green-colors ul li:nth-child(12) p:before {
    content: "#81e73e"; }

.light-green-colors ul li:nth-child(11) {
  background-color: #8ae94c; }
  .light-green-colors ul li:nth-child(11) p:before {
    content: "#8ae94c"; }

.light-green-colors ul li:nth-child(10) {
  background-color: #93eb5a; }
  .light-green-colors ul li:nth-child(10) p:before {
    content: "#93eb5a"; }

.light-green-colors ul li:nth-child(9) {
  background-color: #9bec67; }
  .light-green-colors ul li:nth-child(9) p:before {
    content: "#9bec67"; }

.light-green-colors ul li:nth-child(8) {
  background-color: #a4ee75; }
  .light-green-colors ul li:nth-child(8) p:before {
    content: "#a4ee75"; }

.light-green-colors ul li:nth-child(7) {
  background-color: #adf083; }
  .light-green-colors ul li:nth-child(7) p:before {
    content: "#adf083"; }

.light-green-colors ul li:nth-child(6) {
  background-color: #b6f190; }
  .light-green-colors ul li:nth-child(6) p:before {
    content: "#b6f190"; }

.light-green-colors ul li:nth-child(5) {
  background-color: #bff39e; }
  .light-green-colors ul li:nth-child(5) p:before {
    content: "#bff39e"; }

.light-green-colors ul li:nth-child(4) {
  background-color: #c8f5ac; }
  .light-green-colors ul li:nth-child(4) p:before {
    content: "#c8f5ac"; }

.light-green-colors ul li:nth-child(3) {
  background-color: #d1f6b9; }
  .light-green-colors ul li:nth-child(3) p:before {
    content: "#d1f6b9"; }

.light-green-colors ul li:nth-child(2) {
  background-color: #daf8c7; }
  .light-green-colors ul li:nth-child(2) p:before {
    content: "#daf8c7"; }

.light-green-colors ul li:nth-child(1) {
  background-color: #e3fad4; }
  .light-green-colors ul li:nth-child(1) p:before {
    content: "#e3fad4"; }

/**====== Light-Green-color css end ======**/
/**====== Lime-color css start ======**/
.lime-colors ul li:nth-child(14) {
  background-color: #baf905; }
  .lime-colors ul li:nth-child(14) p:before {
    content: "#baf905"; }

.lime-colors ul li:nth-child(13) {
  background-color: #bffa14; }
  .lime-colors ul li:nth-child(13) p:before {
    content: "#bffa14"; }

.lime-colors ul li:nth-child(12) {
  background-color: #c3fa23; }
  .lime-colors ul li:nth-child(12) p:before {
    content: "#c3fa23"; }

.lime-colors ul li:nth-child(11) {
  background-color: #c7fb32; }
  .lime-colors ul li:nth-child(11) p:before {
    content: "#c7fb32"; }

.lime-colors ul li:nth-child(10) {
  background-color: #cbfb41; }
  .lime-colors ul li:nth-child(10) p:before {
    content: "#cbfb41"; }

.lime-colors ul li:nth-child(9) {
  background-color: #cffb50; }
  .lime-colors ul li:nth-child(9) p:before {
    content: "#cffb50"; }

.lime-colors ul li:nth-child(8) {
  background-color: #d3fc5f; }
  .lime-colors ul li:nth-child(8) p:before {
    content: "#d3fc5f"; }

.lime-colors ul li:nth-child(7) {
  background-color: #d7fc6e; }
  .lime-colors ul li:nth-child(7) p:before {
    content: "#d7fc6e"; }

.lime-colors ul li:nth-child(6) {
  background-color: #dbfc7c; }
  .lime-colors ul li:nth-child(6) p:before {
    content: "#dbfc7c"; }

.lime-colors ul li:nth-child(5) {
  background-color: #dffd8b; }
  .lime-colors ul li:nth-child(5) p:before {
    content: "#dffd8b"; }

.lime-colors ul li:nth-child(4) {
  background-color: #e3fd9a; }
  .lime-colors ul li:nth-child(4) p:before {
    content: "#e3fd9a"; }

.lime-colors ul li:nth-child(3) {
  background-color: #e8fda9; }
  .lime-colors ul li:nth-child(3) p:before {
    content: "#e8fda9"; }

.lime-colors ul li:nth-child(2) {
  background-color: #ecfdb8; }
  .lime-colors ul li:nth-child(2) p:before {
    content: "#ecfdb8"; }

.lime-colors ul li:nth-child(1) {
  background-color: #f0fec7; }
  .lime-colors ul li:nth-child(1) p:before {
    content: "#f0fec7"; }

/**====== Lime-color css end ======**/
/**====== Yellow-color css start ======**/
.yellow-colors ul li:nth-child(14) {
  background-color: #ffd812; }
  .yellow-colors ul li:nth-child(14) p:before {
    content: "#ffd812"; }

.yellow-colors ul li:nth-child(13) {
  background-color: #ffda1f; }
  .yellow-colors ul li:nth-child(13) p:before {
    content: "#ffda1f"; }

.yellow-colors ul li:nth-child(12) {
  background-color: #ffdc2b; }
  .yellow-colors ul li:nth-child(12) p:before {
    content: "#ffdc2b"; }

.yellow-colors ul li:nth-child(11) {
  background-color: #ffde38; }
  .yellow-colors ul li:nth-child(11) p:before {
    content: "#ffde38"; }

.yellow-colors ul li:nth-child(10) {
  background-color: #ffe045; }
  .yellow-colors ul li:nth-child(10) p:before {
    content: "#ffe045"; }

.yellow-colors ul li:nth-child(9) {
  background-color: #ffe352; }
  .yellow-colors ul li:nth-child(9) p:before {
    content: "#ffe352"; }

.yellow-colors ul li:nth-child(8) {
  background-color: #ffe55e; }
  .yellow-colors ul li:nth-child(8) p:before {
    content: "#ffe55e"; }

.yellow-colors ul li:nth-child(7) {
  background-color: #ffe76b; }
  .yellow-colors ul li:nth-child(7) p:before {
    content: "#ffe76b"; }

.yellow-colors ul li:nth-child(6) {
  background-color: #ffe978; }
  .yellow-colors ul li:nth-child(6) p:before {
    content: "#ffe978"; }

.yellow-colors ul li:nth-child(5) {
  background-color: #ffeb85; }
  .yellow-colors ul li:nth-child(5) p:before {
    content: "#ffeb85"; }

.yellow-colors ul li:nth-child(4) {
  background-color: #ffed91; }
  .yellow-colors ul li:nth-child(4) p:before {
    content: "#ffed91"; }

.yellow-colors ul li:nth-child(3) {
  background-color: #ffef9e; }
  .yellow-colors ul li:nth-child(3) p:before {
    content: "#ffef9e"; }

.yellow-colors ul li:nth-child(2) {
  background-color: #fff1ab; }
  .yellow-colors ul li:nth-child(2) p:before {
    content: "#fff1ab"; }

.yellow-colors ul li:nth-child(1) {
  background-color: #fff3b8; }
  .yellow-colors ul li:nth-child(1) p:before {
    content: "#fff3b8"; }

/**====== Yellow-color css end ======**/
/**====== Amber-color css start ======**/
.amber-colors ul li:nth-child(14) {
  background-color: #ffb012; }
  .amber-colors ul li:nth-child(14) p:before {
    content: "#ffb012"; }

.amber-colors ul li:nth-child(13) {
  background-color: #ffb41f; }
  .amber-colors ul li:nth-child(13) p:before {
    content: "#ffb41f"; }

.amber-colors ul li:nth-child(12) {
  background-color: #ffb92b; }
  .amber-colors ul li:nth-child(12) p:before {
    content: "#ffb92b"; }

.amber-colors ul li:nth-child(11) {
  background-color: #ffbd38; }
  .amber-colors ul li:nth-child(11) p:before {
    content: "#ffbd38"; }

.amber-colors ul li:nth-child(10) {
  background-color: #ffc145; }
  .amber-colors ul li:nth-child(10) p:before {
    content: "#ffc145"; }

.amber-colors ul li:nth-child(9) {
  background-color: #ffc552; }
  .amber-colors ul li:nth-child(9) p:before {
    content: "#ffc552"; }

.amber-colors ul li:nth-child(8) {
  background-color: #ffca5e; }
  .amber-colors ul li:nth-child(8) p:before {
    content: "#ffca5e"; }

.amber-colors ul li:nth-child(7) {
  background-color: #ffce6b; }
  .amber-colors ul li:nth-child(7) p:before {
    content: "#ffce6b"; }

.amber-colors ul li:nth-child(6) {
  background-color: #ffd278; }
  .amber-colors ul li:nth-child(6) p:before {
    content: "#ffd278"; }

.amber-colors ul li:nth-child(5) {
  background-color: #ffd685; }
  .amber-colors ul li:nth-child(5) p:before {
    content: "#ffd685"; }

.amber-colors ul li:nth-child(4) {
  background-color: #ffdb91; }
  .amber-colors ul li:nth-child(4) p:before {
    content: "#ffdb91"; }

.amber-colors ul li:nth-child(3) {
  background-color: #ffdf9e; }
  .amber-colors ul li:nth-child(3) p:before {
    content: "#ffdf9e"; }

.amber-colors ul li:nth-child(2) {
  background-color: #ffe3ab; }
  .amber-colors ul li:nth-child(2) p:before {
    content: "#ffe3ab"; }

.amber-colors ul li:nth-child(1) {
  background-color: #ffe7b8; }
  .amber-colors ul li:nth-child(1) p:before {
    content: "#ffe7b8"; }

/**====== Amber-color css end ======**/
/**====== Orange-color css start ======**/
.orange-colors ul li:nth-child(14) {
  background-color: #ff7814; }
  .orange-colors ul li:nth-child(14) p:before {
    content: "#ff7814"; }

.orange-colors ul li:nth-child(13) {
  background-color: #ff8124; }
  .orange-colors ul li:nth-child(13) p:before {
    content: "#ff8124"; }

.orange-colors ul li:nth-child(12) {
  background-color: #ff8933; }
  .orange-colors ul li:nth-child(12) p:before {
    content: "#ff8933"; }

.orange-colors ul li:nth-child(11) {
  background-color: #ff9242; }
  .orange-colors ul li:nth-child(11) p:before {
    content: "#ff9242"; }

.orange-colors ul li:nth-child(10) {
  background-color: #ff9b52; }
  .orange-colors ul li:nth-child(10) p:before {
    content: "#ff9b52"; }

.orange-colors ul li:nth-child(9) {
  background-color: #ffa461; }
  .orange-colors ul li:nth-child(9) p:before {
    content: "#ffa461"; }

.orange-colors ul li:nth-child(8) {
  background-color: #ffad70; }
  .orange-colors ul li:nth-child(8) p:before {
    content: "#ffad70"; }

.orange-colors ul li:nth-child(7) {
  background-color: #ffb67f; }
  .orange-colors ul li:nth-child(7) p:before {
    content: "#ffb67f"; }

.orange-colors ul li:nth-child(6) {
  background-color: #ffbe8f; }
  .orange-colors ul li:nth-child(6) p:before {
    content: "#ffbe8f"; }

.orange-colors ul li:nth-child(5) {
  background-color: #ffc79e; }
  .orange-colors ul li:nth-child(5) p:before {
    content: "#ffc79e"; }

.orange-colors ul li:nth-child(4) {
  background-color: #ffd0ad; }
  .orange-colors ul li:nth-child(4) p:before {
    content: "#ffd0ad"; }

.orange-colors ul li:nth-child(3) {
  background-color: #ffd9bd; }
  .orange-colors ul li:nth-child(3) p:before {
    content: "#ffd9bd"; }

.orange-colors ul li:nth-child(2) {
  background-color: #ffe2cc; }
  .orange-colors ul li:nth-child(2) p:before {
    content: "#ffe2cc"; }

.orange-colors ul li:nth-child(1) {
  background-color: #ffeadb; }
  .orange-colors ul li:nth-child(1) p:before {
    content: "#ffeadb"; }

/**====== Orange-color css start ======**/
/**====== Deep-Orange-color css start ======**/
.deep-orange-colors ul li:nth-child(14) {
  background-color: #ec3305; }
  .deep-orange-colors ul li:nth-child(14) p:before {
    content: "#ec3305"; }

.deep-orange-colors ul li:nth-child(13) {
  background-color: #f93707; }
  .deep-orange-colors ul li:nth-child(13) p:before {
    content: "#f93707"; }

.deep-orange-colors ul li:nth-child(12) {
  background-color: #fa4316; }
  .deep-orange-colors ul li:nth-child(12) p:before {
    content: "#fa4316"; }

.deep-orange-colors ul li:nth-child(11) {
  background-color: #fa5025; }
  .deep-orange-colors ul li:nth-child(11) p:before {
    content: "#fa5025"; }

.deep-orange-colors ul li:nth-child(10) {
  background-color: #fa5c34; }
  .deep-orange-colors ul li:nth-child(10) p:before {
    content: "#fa5c34"; }

.deep-orange-colors ul li:nth-child(9) {
  background-color: #fb6843; }
  .deep-orange-colors ul li:nth-child(9) p:before {
    content: "#fb6843"; }

.deep-orange-colors ul li:nth-child(8) {
  background-color: #fb7452; }
  .deep-orange-colors ul li:nth-child(8) p:before {
    content: "#fb7452"; }

.deep-orange-colors ul li:nth-child(7) {
  background-color: #fb8061; }
  .deep-orange-colors ul li:nth-child(7) p:before {
    content: "#fb8061"; }

.deep-orange-colors ul li:nth-child(6) {
  background-color: #fc8c70; }
  .deep-orange-colors ul li:nth-child(6) p:before {
    content: "#fc8c70"; }

.deep-orange-colors ul li:nth-child(5) {
  background-color: #fc987f; }
  .deep-orange-colors ul li:nth-child(5) p:before {
    content: "#fc987f"; }

.deep-orange-colors ul li:nth-child(4) {
  background-color: #fca48e; }
  .deep-orange-colors ul li:nth-child(4) p:before {
    content: "#fca48e"; }

.deep-orange-colors ul li:nth-child(3) {
  background-color: #fdb09d; }
  .deep-orange-colors ul li:nth-child(3) p:before {
    content: "#fdb09d"; }

.deep-orange-colors ul li:nth-child(2) {
  background-color: #fdbcac; }
  .deep-orange-colors ul li:nth-child(2) p:before {
    content: "#fdbcac"; }

.deep-orange-colors ul li:nth-child(1) {
  background-color: #fdc8bb; }
  .deep-orange-colors ul li:nth-child(1) p:before {
    content: "#fdc8bb"; }

/**====== Deep-Orange-color css end ======**/
/**====== Brown-color css start ======**/
.brown-colors ul li:nth-child(14) {
  background-color: #513631; }
  .brown-colors ul li:nth-child(14) p:before {
    content: "#513631"; }

.brown-colors ul li:nth-child(13) {
  background-color: #61403a; }
  .brown-colors ul li:nth-child(13) p:before {
    content: "#61403a"; }

.brown-colors ul li:nth-child(12) {
  background-color: #714b44; }
  .brown-colors ul li:nth-child(12) p:before {
    content: "#714b44"; }

.brown-colors ul li:nth-child(11) {
  background-color: #81554d; }
  .brown-colors ul li:nth-child(11) p:before {
    content: "#81554d"; }

.brown-colors ul li:nth-child(10) {
  background-color: #916057; }
  .brown-colors ul li:nth-child(10) p:before {
    content: "#916057"; }

.brown-colors ul li:nth-child(9) {
  background-color: #a06b61; }
  .brown-colors ul li:nth-child(9) p:before {
    content: "#a06b61"; }

.brown-colors ul li:nth-child(8) {
  background-color: #aa7a71; }
  .brown-colors ul li:nth-child(8) p:before {
    content: "#aa7a71"; }

.brown-colors ul li:nth-child(7) {
  background-color: #b38981; }
  .brown-colors ul li:nth-child(7) p:before {
    content: "#b38981"; }

.brown-colors ul li:nth-child(6) {
  background-color: #bd9791; }
  .brown-colors ul li:nth-child(6) p:before {
    content: "#bd9791"; }

.brown-colors ul li:nth-child(5) {
  background-color: #c6a6a1; }
  .brown-colors ul li:nth-child(5) p:before {
    content: "#c6a6a1"; }

.brown-colors ul li:nth-child(4) {
  background-color: #d0b5b1; }
  .brown-colors ul li:nth-child(4) p:before {
    content: "#d0b5b1"; }

.brown-colors ul li:nth-child(3) {
  background-color: #dac4c1; }
  .brown-colors ul li:nth-child(3) p:before {
    content: "#dac4c1"; }

.brown-colors ul li:nth-child(2) {
  background-color: #e3d3d0; }
  .brown-colors ul li:nth-child(2) p:before {
    content: "#e3d3d0"; }

.brown-colors ul li:nth-child(1) {
  background-color: #ede2e0; }
  .brown-colors ul li:nth-child(1) p:before {
    content: "#ede2e0"; }

/**====== Brown-color css end ======**/
/**====== Grey-color css start ======**/
.grey-colors ul li:nth-child(14) {
  background-color: #323232; }
  .grey-colors ul li:nth-child(14) p:before {
    content: "#323232"; }

.grey-colors ul li:nth-child(13) {
  background-color: #3f3f3f; }
  .grey-colors ul li:nth-child(13) p:before {
    content: "#3f3f3f"; }

.grey-colors ul li:nth-child(12) {
  background-color: #4b4b4b; }
  .grey-colors ul li:nth-child(12) p:before {
    content: "#4b4b4b"; }

.grey-colors ul li:nth-child(11) {
  background-color: #585858; }
  .grey-colors ul li:nth-child(11) p:before {
    content: "#585858"; }

.grey-colors ul li:nth-child(10) {
  background-color: #656565; }
  .grey-colors ul li:nth-child(10) p:before {
    content: "#656565"; }

.grey-colors ul li:nth-child(9) {
  background-color: #727272; }
  .grey-colors ul li:nth-child(9) p:before {
    content: "#727272"; }

.grey-colors ul li:nth-child(8) {
  background-color: #7e7e7e; }
  .grey-colors ul li:nth-child(8) p:before {
    content: "#7e7e7e"; }

.grey-colors ul li:nth-child(7) {
  background-color: #8b8b8b; }
  .grey-colors ul li:nth-child(7) p:before {
    content: "#8b8b8b"; }

.grey-colors ul li:nth-child(6) {
  background-color: #989898; }
  .grey-colors ul li:nth-child(6) p:before {
    content: "#989898"; }

.grey-colors ul li:nth-child(5) {
  background-color: #a5a5a5; }
  .grey-colors ul li:nth-child(5) p:before {
    content: "#a5a5a5"; }

.grey-colors ul li:nth-child(4) {
  background-color: #b1b1b1; }
  .grey-colors ul li:nth-child(4) p:before {
    content: "#b1b1b1"; }

.grey-colors ul li:nth-child(3) {
  background-color: #bebebe; }
  .grey-colors ul li:nth-child(3) p:before {
    content: "#bebebe"; }

.grey-colors ul li:nth-child(2) {
  background-color: #cbcbcb; }
  .grey-colors ul li:nth-child(2) p:before {
    content: "#cbcbcb"; }

.grey-colors ul li:nth-child(1) {
  background-color: #d8d8d8; }
  .grey-colors ul li:nth-child(1) p:before {
    content: "#d8d8d8"; }

/**====== Grey-color css end ======**/
/**====== Blue-color css start ======**/
.blue-grey-colors ul li:nth-child(14) {
  background-color: #35444a; }
  .blue-grey-colors ul li:nth-child(14) p:before {
    content: "#35444a"; }

.blue-grey-colors ul li:nth-child(13) {
  background-color: #3f5159; }
  .blue-grey-colors ul li:nth-child(13) p:before {
    content: "#3f5159"; }

.blue-grey-colors ul li:nth-child(12) {
  background-color: #4a5f68; }
  .blue-grey-colors ul li:nth-child(12) p:before {
    content: "#4a5f68"; }

.blue-grey-colors ul li:nth-child(11) {
  background-color: #546d77; }
  .blue-grey-colors ul li:nth-child(11) p:before {
    content: "#546d77"; }

.blue-grey-colors ul li:nth-child(10) {
  background-color: #5f7a85; }
  .blue-grey-colors ul li:nth-child(10) p:before {
    content: "#5f7a85"; }

.blue-grey-colors ul li:nth-child(9) {
  background-color: #6a8894; }
  .blue-grey-colors ul li:nth-child(9) p:before {
    content: "#6a8894"; }

.blue-grey-colors ul li:nth-child(8) {
  background-color: #78949f; }
  .blue-grey-colors ul li:nth-child(8) p:before {
    content: "#78949f"; }

.blue-grey-colors ul li:nth-child(7) {
  background-color: #87a0aa; }
  .blue-grey-colors ul li:nth-child(7) p:before {
    content: "#87a0aa"; }

.blue-grey-colors ul li:nth-child(6) {
  background-color: #96abb4; }
  .blue-grey-colors ul li:nth-child(6) p:before {
    content: "#96abb4"; }

.blue-grey-colors ul li:nth-child(5) {
  background-color: #a5b7bf; }
  .blue-grey-colors ul li:nth-child(5) p:before {
    content: "#a5b7bf"; }

.blue-grey-colors ul li:nth-child(4) {
  background-color: #b4c3ca; }
  .blue-grey-colors ul li:nth-child(4) p:before {
    content: "#b4c3ca"; }

.blue-grey-colors ul li:nth-child(3) {
  background-color: #c3cfd4; }
  .blue-grey-colors ul li:nth-child(3) p:before {
    content: "#c3cfd4"; }

.blue-grey-colors ul li:nth-child(2) {
  background-color: #d2dbdf; }
  .blue-grey-colors ul li:nth-child(2) p:before {
    content: "#d2dbdf"; }

.blue-grey-colors ul li:nth-child(1) {
  background-color: #e1e7e9; }
  .blue-grey-colors ul li:nth-child(1) p:before {
    content: "#e1e7e9"; }

/**====== Blue-color css end ======**/
/**====== Primary-color css start ======**/
.primary-colorr ul li:nth-child(14) {
  background-color: #1cc9a7; }
  .primary-colorr ul li:nth-child(14) p:before {
    content: "#1cc9a7"; }

.primary-colorr ul li:nth-child(13) {
  background-color: #1ed7b2; }
  .primary-colorr ul li:nth-child(13) p:before {
    content: "#1ed7b2"; }

.primary-colorr ul li:nth-child(12) {
  background-color: #23e1bb; }
  .primary-colorr ul li:nth-child(12) p:before {
    content: "#23e1bb"; }

.primary-colorr ul li:nth-child(11) {
  background-color: #31e2bf; }
  .primary-colorr ul li:nth-child(11) p:before {
    content: "#31e2bf"; }

.primary-colorr ul li:nth-child(10) {
  background-color: #3ee4c4; }
  .primary-colorr ul li:nth-child(10) p:before {
    content: "#3ee4c4"; }

.primary-colorr ul li:nth-child(9) {
  background-color: #4ce6c8; }
  .primary-colorr ul li:nth-child(9) p:before {
    content: "#4ce6c8"; }

.primary-colorr ul li:nth-child(8) {
  background-color: #59e8cc; }
  .primary-colorr ul li:nth-child(8) p:before {
    content: "#59e8cc"; }

.primary-colorr ul li:nth-child(7) {
  background-color: #66ead0; }
  .primary-colorr ul li:nth-child(7) p:before {
    content: "#66ead0"; }

.primary-colorr ul li:nth-child(6) {
  background-color: #74ecd4; }
  .primary-colorr ul li:nth-child(6) p:before {
    content: "#74ecd4"; }

.primary-colorr ul li:nth-child(5) {
  background-color: #81eed8; }
  .primary-colorr ul li:nth-child(5) p:before {
    content: "#81eed8"; }

.primary-colorr ul li:nth-child(4) {
  background-color: #8fefdc; }
  .primary-colorr ul li:nth-child(4) p:before {
    content: "#8fefdc"; }

.primary-colorr ul li:nth-child(3) {
  background-color: #9cf1e1; }
  .primary-colorr ul li:nth-child(3) p:before {
    content: "#9cf1e1"; }

.primary-colorr ul li:nth-child(2) {
  background-color: #aaf3e5; }
  .primary-colorr ul li:nth-child(2) p:before {
    content: "#aaf3e5"; }

.primary-colorr ul li:nth-child(1) {
  background-color: #b7f5e9; }
  .primary-colorr ul li:nth-child(1) p:before {
    content: "#b7f5e9"; }

/**====== Primary-color css end ======**/
/**====== Success-color css start ======**/
.success-colorr ul li:nth-child(14) {
  background-color: #33d176; }
  .success-colorr ul li:nth-child(14) p:before {
    content: "#33d176"; }

.success-colorr ul li:nth-child(13) {
  background-color: #3bd37c; }
  .success-colorr ul li:nth-child(13) p:before {
    content: "#3bd37c"; }

.success-colorr ul li:nth-child(12) {
  background-color: #44d581; }
  .success-colorr ul li:nth-child(12) p:before {
    content: "#44d581"; }

.success-colorr ul li:nth-child(11) {
  background-color: #4cd787; }
  .success-colorr ul li:nth-child(11) p:before {
    content: "#4cd787"; }

.success-colorr ul li:nth-child(10) {
  background-color: #54d98c; }
  .success-colorr ul li:nth-child(10) p:before {
    content: "#54d98c"; }

.success-colorr ul li:nth-child(9) {
  background-color: #5dda92; }
  .success-colorr ul li:nth-child(9) p:before {
    content: "#5dda92"; }

.success-colorr ul li:nth-child(8) {
  background-color: #65dc98; }
  .success-colorr ul li:nth-child(8) p:before {
    content: "#65dc98"; }

.success-colorr ul li:nth-child(7) {
  background-color: #6dde9d; }
  .success-colorr ul li:nth-child(7) p:before {
    content: "#6dde9d"; }

.success-colorr ul li:nth-child(6) {
  background-color: #76e0a3; }
  .success-colorr ul li:nth-child(6) p:before {
    content: "#76e0a3"; }

.success-colorr ul li:nth-child(5) {
  background-color: #7ee2a8; }
  .success-colorr ul li:nth-child(5) p:before {
    content: "#7ee2a8"; }

.success-colorr ul li:nth-child(4) {
  background-color: #86e4ae; }
  .success-colorr ul li:nth-child(4) p:before {
    content: "#86e4ae"; }

.success-colorr ul li:nth-child(3) {
  background-color: #8fe6b4; }
  .success-colorr ul li:nth-child(3) p:before {
    content: "#8fe6b4"; }

.success-colorr ul li:nth-child(2) {
  background-color: #97e8b9; }
  .success-colorr ul li:nth-child(2) p:before {
    content: "#97e8b9"; }

.success-colorr ul li:nth-child(1) {
  background-color: #9fe9bf; }
  .success-colorr ul li:nth-child(1) p:before {
    content: "#9fe9bf"; }

/**====== Success-color css end ======**/
/**====== Info-color css start ======**/
.info-colorr ul li:nth-child(14) {
  background-color: #3d9cdd; }
  .info-colorr ul li:nth-child(14) p:before {
    content: "#3d9cdd"; }

.info-colorr ul li:nth-child(13) {
  background-color: #45a1de; }
  .info-colorr ul li:nth-child(13) p:before {
    content: "#45a1de"; }

.info-colorr ul li:nth-child(12) {
  background-color: #4ea5e0; }
  .info-colorr ul li:nth-child(12) p:before {
    content: "#4ea5e0"; }

.info-colorr ul li:nth-child(11) {
  background-color: #57aae1; }
  .info-colorr ul li:nth-child(11) p:before {
    content: "#57aae1"; }

.info-colorr ul li:nth-child(10) {
  background-color: #5faee3; }
  .info-colorr ul li:nth-child(10) p:before {
    content: "#5faee3"; }

.info-colorr ul li:nth-child(9) {
  background-color: #68b2e4; }
  .info-colorr ul li:nth-child(9) p:before {
    content: "#68b2e4"; }

.info-colorr ul li:nth-child(8) {
  background-color: #71b7e6; }
  .info-colorr ul li:nth-child(8) p:before {
    content: "#71b7e6"; }

.info-colorr ul li:nth-child(7) {
  background-color: #79bbe7; }
  .info-colorr ul li:nth-child(7) p:before {
    content: "#79bbe7"; }

.info-colorr ul li:nth-child(6) {
  background-color: #82c0e9; }
  .info-colorr ul li:nth-child(6) p:before {
    content: "#82c0e9"; }

.info-colorr ul li:nth-child(5) {
  background-color: #8bc4ea; }
  .info-colorr ul li:nth-child(5) p:before {
    content: "#8bc4ea"; }

.info-colorr ul li:nth-child(4) {
  background-color: #93c8ec; }
  .info-colorr ul li:nth-child(4) p:before {
    content: "#93c8ec"; }

.info-colorr ul li:nth-child(3) {
  background-color: #9ccded; }
  .info-colorr ul li:nth-child(3) p:before {
    content: "#9ccded"; }

.info-colorr ul li:nth-child(2) {
  background-color: #a5d1ef; }
  .info-colorr ul li:nth-child(2) p:before {
    content: "#a5d1ef"; }

.info-colorr ul li:nth-child(1) {
  background-color: #add6f1; }
  .info-colorr ul li:nth-child(1) p:before {
    content: "#add6f1"; }

/**====== Info-color css end ======**/
/**====== Warning-color css start ======**/
.warning-colorr ul li:nth-child(14) {
  background-color: #f2c619; }
  .warning-colorr ul li:nth-child(14) p:before {
    content: "#f2c619"; }

.warning-colorr ul li:nth-child(13) {
  background-color: #f2c922; }
  .warning-colorr ul li:nth-child(13) p:before {
    content: "#f2c922"; }

.warning-colorr ul li:nth-child(12) {
  background-color: #f3cb2c; }
  .warning-colorr ul li:nth-child(12) p:before {
    content: "#f3cb2c"; }

.warning-colorr ul li:nth-child(11) {
  background-color: #f3cd36; }
  .warning-colorr ul li:nth-child(11) p:before {
    content: "#f3cd36"; }

.warning-colorr ul li:nth-child(10) {
  background-color: #f4d03f; }
  .warning-colorr ul li:nth-child(10) p:before {
    content: "#f4d03f"; }

.warning-colorr ul li:nth-child(9) {
  background-color: #f4d249; }
  .warning-colorr ul li:nth-child(9) p:before {
    content: "#f4d249"; }

.warning-colorr ul li:nth-child(8) {
  background-color: #f5d552; }
  .warning-colorr ul li:nth-child(8) p:before {
    content: "#f5d552"; }

.warning-colorr ul li:nth-child(7) {
  background-color: #f5d75c; }
  .warning-colorr ul li:nth-child(7) p:before {
    content: "#f5d75c"; }

.warning-colorr ul li:nth-child(6) {
  background-color: #f6d966; }
  .warning-colorr ul li:nth-child(6) p:before {
    content: "#f6d966"; }

.warning-colorr ul li:nth-child(5) {
  background-color: #f7dc6f; }
  .warning-colorr ul li:nth-child(5) p:before {
    content: "#f7dc6f"; }

.warning-colorr ul li:nth-child(4) {
  background-color: #f7de79; }
  .warning-colorr ul li:nth-child(4) p:before {
    content: "#f7de79"; }

.warning-colorr ul li:nth-child(3) {
  background-color: #f8e083; }
  .warning-colorr ul li:nth-child(3) p:before {
    content: "#f8e083"; }

.warning-colorr ul li:nth-child(2) {
  background-color: #f8e38c; }
  .warning-colorr ul li:nth-child(2) p:before {
    content: "#f8e38c"; }

.warning-colorr ul li:nth-child(1) {
  background-color: #f9e596; }
  .warning-colorr ul li:nth-child(1) p:before {
    content: "#f9e596"; }

/**====== Warning-color css end ======**/
/**====== Danger-color css start ======**/
.danger-colorr ul li:nth-child(14) {
  background-color: #e85445; }
  .danger-colorr ul li:nth-child(14) p:before {
    content: "#e85445"; }

.danger-colorr ul li:nth-child(13) {
  background-color: #e95d4e; }
  .danger-colorr ul li:nth-child(13) p:before {
    content: "#e95d4e"; }

.danger-colorr ul li:nth-child(12) {
  background-color: #ea6557; }
  .danger-colorr ul li:nth-child(12) p:before {
    content: "#ea6557"; }

.danger-colorr ul li:nth-child(11) {
  background-color: #eb6d60; }
  .danger-colorr ul li:nth-child(11) p:before {
    content: "#eb6d60"; }

.danger-colorr ul li:nth-child(10) {
  background-color: #ed7669; }
  .danger-colorr ul li:nth-child(10) p:before {
    content: "#ed7669"; }

.danger-colorr ul li:nth-child(9) {
  background-color: #ee7e72; }
  .danger-colorr ul li:nth-child(9) p:before {
    content: "#ee7e72"; }

.danger-colorr ul li:nth-child(8) {
  background-color: #ef867c; }
  .danger-colorr ul li:nth-child(8) p:before {
    content: "#ef867c"; }

.danger-colorr ul li:nth-child(7) {
  background-color: #f08f85; }
  .danger-colorr ul li:nth-child(7) p:before {
    content: "#f08f85"; }

.danger-colorr ul li:nth-child(6) {
  background-color: #f1978e; }
  .danger-colorr ul li:nth-child(6) p:before {
    content: "#f1978e"; }

.danger-colorr ul li:nth-child(5) {
  background-color: #f29f97; }
  .danger-colorr ul li:nth-child(5) p:before {
    content: "#f29f97"; }

.danger-colorr ul li:nth-child(4) {
  background-color: #f3a8a0; }
  .danger-colorr ul li:nth-child(4) p:before {
    content: "#f3a8a0"; }

.danger-colorr ul li:nth-child(3) {
  background-color: #f4b0a9; }
  .danger-colorr ul li:nth-child(3) p:before {
    content: "#f4b0a9"; }

.danger-colorr ul li:nth-child(2) {
  background-color: #f6b8b2; }
  .danger-colorr ul li:nth-child(2) p:before {
    content: "#f6b8b2"; }

.danger-colorr ul li:nth-child(1) {
  background-color: #f7c1bb; }
  .danger-colorr ul li:nth-child(1) p:before {
    content: "#f7c1bb"; }

/**====== Danger-color css end ======**/
/**====== Color-color css end ======**/
.set-map {
  height: 400px;
  width: 100% !important; }

#mapGeo {
  height: 345px; }

#address-search .input-group {
  margin-bottom: 0; }

/**  =====================
      Prism css start
==========================  **/
pre[class*="language-"] {
  background-color: #fff;
  border: 1px solid #ddd; }
  pre[class*="language-"] code {
    background-color: #fff !important; }

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber; }

pre.line-numbers > code {
  position: relative; }

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber; }

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right; }

/**====== Prism css end ======**/
/**  =====================
      Progress-bar css start
==========================  **/
.progress-xl {
  height: 30px; }

.progress-lg {
  height: 24px; }

.progres-md {
  height: 18px; }

.progress-sm {
  height: 12px; }

.progress-xs {
  height: 6px; }

.progress-bar-page .progress {
  margin-bottom: 2rem; }

.progress-bar-default {
  background-color: #d6d6d6; }

.progress-bar-primary {
  background-color: #448aff; }

.progress-bar-success {
  background-color: #11c15b; }

.progress-bar-info {
  background-color: #00bcd4; }

.progress-bar-warning {
  background-color: #ffe100; }

.progress-bar-danger {
  background-color: #ff5252; }

.progress-bar-pink {
  background-color: #ff7aa3; }

.progress-bar-purple {
  background-color: #9261c6; }

.progress-bar-orange {
  background-color: #e67e22; }

.progress-bar-yellow {
  background-color: #f1c40f; }

.progress-bar-emrald {
  background-color: #2ecc71; }

/**====== Progress-bar css end ======**/
/**  =====================
      Pre-loader css start
==========================  **/
/*===== Loader1 =====*/
.loader {
  margin: 18px auto;
  top: 65px;
  left: 65px;
  right: 0;
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .loader.animation-start .circle {
    -webkit-animation: rotating 2s ease-in-out infinite;
            animation: rotating 2s ease-in-out infinite; }
  .loader.animation-stop .circle {
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1; }
  .loader .circle {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-animation-iteration-count: 0;
            animation-iteration-count: 0; }
  .loader .circle:after {
    content: '';
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #1abc9c; }
  .loader .circle.delay-1 {
    -webkit-animation-delay: 0.125s;
            animation-delay: 0.125s; }
  .loader .circle.size-1:after {
    width: 11px;
    height: 11px; }
  .loader .circle.delay-2 {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s; }
  .loader .circle.size-2:after {
    width: 12px;
    height: 12px; }
  .loader .circle.delay-3 {
    -webkit-animation-delay: 0.375s;
            animation-delay: 0.375s; }
  .loader .circle.size-3:after {
    width: 13px;
    height: 13px; }
  .loader .circle.delay-4 {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s; }
  .loader .circle.size-4:after {
    width: 14px;
    height: 14px; }
  .loader .circle.delay-5 {
    -webkit-animation-delay: 0.625s;
            animation-delay: 0.625s; }
  .loader .circle.size-5:after {
    width: 15px;
    height: 15px; }
  .loader .circle.delay-6 {
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s; }
  .loader .circle.size-6:after {
    width: 16px;
    height: 16px; }
  .loader .circle.delay-7 {
    -webkit-animation-delay: 0.875s;
            animation-delay: 0.875s; }
  .loader .circle.size-7:after {
    width: 17px;
    height: 17px; }
  .loader .circle.delay-8 {
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }
  .loader .circle.size-8:after {
    width: 18px;
    height: 18px; }
  .loader .circle.delay-9 {
    -webkit-animation-delay: 1.125s;
            animation-delay: 1.125s; }
  .loader .circle.size-9:after {
    width: 19px;
    height: 19px; }
  .loader .circle.delay-10 {
    -webkit-animation-delay: 1.25s;
            animation-delay: 1.25s; }
  .loader .circle.size-10:after {
    width: 20px;
    height: 20px; }
  .loader .circle.delay-11 {
    -webkit-animation-delay: 1.375s;
            animation-delay: 1.375s; }
  .loader .circle.size-11:after {
    width: 21px;
    height: 21px; }
  .loader .circle.delay-12 {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s; }
  .loader .circle.size-12:after {
    width: 22px;
    height: 22px; }

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
/*===== Loader2 =====*/
#loader2 {
  width: 80px;
  height: 130px;
  display: block;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  margin: 0 auto; }

#circle-loader2 {
  fill: none;
  stroke: #448aff;
  stroke-width: 4;
  -webkit-animation: draw 3s ease-in-out infinite;
          animation: draw 3s ease-in-out infinite; }

@-webkit-keyframes draw {
  0% {
    stroke-dasharray: 20, 282.6; }
  50% {
    stroke-dasharray: 200, 282.6; }
  100% {
    stroke-dasharray: 20, 282.6; } }

@keyframes draw {
  0% {
    stroke-dasharray: 20, 282.6; }
  50% {
    stroke-dasharray: 200, 282.6; }
  100% {
    stroke-dasharray: 20, 282.6; } }
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
/*==== Loader3 ====*/
.preloader3 {
  width: auto;
  height: 130px;
  text-align: center;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .preloader3 > div {
    margin: 2px;
    background-color: #448aff;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
    animation: stretchdelay 0.7s infinite ease-in-out; }
  .preloader3 .circ2 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s; }
  .preloader3 .circ3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s; }
  .preloader3 .circ4 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s; }
  .preloader3 .circ5 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s; }
  .preloader3 .loader-md {
    height: 13px;
    width: 13px; }
  .preloader3 .loader-lg {
    height: 18px;
    width: 18px; }

@-webkit-keyframes stretchdelay {
  0%,
    40%,
    100% {
    -webkit-transform: translateY(-10px); }
  20% {
    -webkit-transform: translateY(-20px); } }
@keyframes stretchdelay {
  0%,
    40%,
    100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px); }
  20% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px); } }
@-webkit-keyframes rotateColor {
  0% {
    border-top-color: #1b8bf9; }
  12.5% {
    border-bottom-color: rgba(27, 139, 249, 0.8); }
  25% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  37.5% {
    border-bottom-color: rgba(27, 139, 249, 0.4); }
  50% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  50.00001% {
    border-bottom-color: #1b8bf9; }
  62.5% {
    border-bottom-color: rgba(27, 139, 249, 0.8); }
  75% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  87.5% {
    border-bottom-color: rgba(27, 139, 249, 0.4); } }
@keyframes rotateColor {
  0% {
    border-top-color: #1b8bf9; }
  12.5% {
    border-bottom-color: rgba(27, 139, 249, 0.8); }
  25% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  37.5% {
    border-bottom-color: rgba(27, 139, 249, 0.4); }
  50% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  50.00001% {
    border-bottom-color: #1b8bf9; }
  62.5% {
    border-bottom-color: rgba(27, 139, 249, 0.8); }
  75% {
    border-bottom-color: rgba(27, 139, 249, 0.6); }
  87.5% {
    border-bottom-color: rgba(27, 139, 249, 0.4); } }
/*===== Loader4 =====*/
.preloader4 {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 55px auto; }

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #448aff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@-webkit-keyframes bounce {
  0%,
    100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }
@keyframes bounce {
  0%,
    100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
/*===== Loader5 =====*/
.preloader5 {
  margin: 55px 0;
  text-align: center; }
  .preloader5 .circle-5 {
    display: inline-block;
    padding: 11px;
    margin: 0 0.6em;
    background: #448aff;
    border-radius: 100%; }
  .preloader5 .l {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear; }
  .preloader5 .m {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
  .preloader5 .r {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }

@-webkit-keyframes pulse {
  10% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  30% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  50% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  70% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes pulse {
  10% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  30% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  50% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  70% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7); }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
/*===== Loader6 =====*/
.preloader6 {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: inherit;
  margin: 18px 0; }

.preloader6 hr {
  border: 0;
  background: inherit;
  width: 80%;
  height: 80%;
  margin: 10%;
  border-radius: 100%; }

.preloader6::before,
.preloader6::after,
.preloader6 hr::before,
.preloader6 hr::after {
  content: '';
  position: absolute;
  top: 0;
  z-index: -1;
  left: 33px;
  border: 17px solid transparent;
  border-bottom: 50px solid #448aff;
  border-top: 50px solid #448aff;
  -webkit-animation: rotateColor 1000ms infinite;
          animation: rotateColor 1000ms infinite; }

.preloader6::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation-delay: 125ms;
          animation-delay: 125ms; }

.preloader6 hr::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms; }

.preloader6 hr::after {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  -webkit-animation-delay: 375ms;
          animation-delay: 375ms; }

.loader-block,
.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

@keyframes rotateColor {
  0% {
    border-top-color: #1abc9c; }
  12.5% {
    border-bottom-color: rgba(26, 188, 156, 0.8); }
  25% {
    border-bottom-color: rgba(26, 188, 156, 0.6); }
  37.5% {
    border-bottom-color: rgba(26, 188, 156, 0.4); }
  50% {
    border-bottom-color: rgba(26, 188, 156, 0.6); }
  50.00001% {
    border-bottom-color: #1abc9c; }
  62.5% {
    border-bottom-color: rgba(26, 188, 156, 0.8); }
  75% {
    border-bottom-color: rgba(26, 188, 156, 0.6); }
  87.5% {
    border-bottom-color: rgba(26, 188, 156, 0.4); } }
/*========= Pre-loader css end ============*/
/**  =====================
      Todo css start
==========================  **/
#task-container ul {
  overflow: hidden; }
#task-container .task-headline {
  display: none;
  color: #666666;
  border-bottom: 1px solid #C8C7BB;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 1.6em;
  position: relative; }
  #task-container .task-headline:before {
    height: 1px;
    width: 100%;
    background: #FFF;
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0; }
#task-container .nothing-message {
  height: 160px;
  color: #666;
  background-size: 15%; }
#task-container li {
  float: left;
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #FFF;
  display: inline-block;
  padding: 20px;
  border: 1px solid #CCC;
  color: #666;
  border-top: 9px solid #448aff;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative; }
  #task-container li:nth-child(even) {
    margin-right: 0; }
  #task-container li:hover {
    opacity: 1;
    border-top: 9px solid #d6d6d6; }
  #task-container li.complete {
    opacity: 1;
    border-top: 9px solid #ff5252;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s; }
    #task-container li.complete:before {
      /* background: url("../images/complete.png") no-repeat; */
      position: absolute;
      top: 5px;
      right: 5px;
      content: "";
      width: 55px;
      height: 55px;
      background-size: 100%; }
    #task-container li.complete:hover {
      border-top: 9px solid #d6d6d6;
      opacity: 1; }
    #task-container li.complete p {
      text-decoration: line-through; }
#task-container p {
  line-height: 1.6em;
  text-align: left; }

.add-line.complete {
  text-decoration: line-through; }

.task-panel .to-do-label {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .task-panel .to-do-label:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0; }

.to-do-list {
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .to-do-list i {
    color: #ccc;
    font-size: 17px;
    opacity: 0; }
  .to-do-list:last-child {
    border: none;
    padding: 0;
    margin: 0; }
  .to-do-list:hover i {
    opacity: 1;
    -webkit-transition: opacity ease-in 0.3s;
    transition: opacity ease-in 0.3s; }
  .to-do-list p {
    display: inline-block; }

.to-do-label .checkbox-fade {
  display: block; }
  .to-do-label .checkbox-fade .delete_todo {
    float: right;
    font-size: 24px;
    color: #ccc; }
.to-do-label .check-task {
  display: block; }
.to-do-label i {
  cursor: pointer; }

.done-task span,
.done-task .captions {
  color: #919aa3;
  text-decoration: line-through; }

/**====== Todo css end ======**/
@media only screen and (min-width: 1367px) and (max-width: 1700px) {
  .timeline-dot:after, .timeline-dot:before {
    left: 34px; }
  .timeline-dot .social-timelines:before {
    left: 37px; }
  .timeline-dot .social-timelines-left:after {
    right: -30px; }
  .timeline-dot .timeline-right .card {
    margin-left: 30px; } }
@media only screen and (max-width: 1366px) {
  .to-do-list {
    margin-bottom: 15px; }

  .img-tabs img {
    width: 75px; }

  .card .card-block .sub-title {
    font-size: 12px; } }
@media only screen and (min-width: 1279px) and (max-width: 1500px) {
  .cd-horizontal-timeline {
    margin: 0 auto; }

  #chartdiv {
    height: 440px; }

  .social-card-1 .card-block-big {
    padding: 1.3em; }

  #morris-extra-area {
    height: 400px !important; }

  .widget-chat-box .receive-chat,
  .widget-chat-box .send-chat {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

  .similar-job .media {
    display: inline-block; }
    .similar-job .media .media-body {
      margin-top: 20px; } }
@media only screen and (min-width: 1200px) and (max-width: 1258px) {
  .user-head {
    text-align: center; }
    .user-head .user-face {
      margin: 0 auto; }
    .user-head .user-name {
      display: none; } }
@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .timeline-dot .card {
    margin-left: 50px; }
  .timeline-dot:after, .timeline-dot:before {
    left: 35px; }
  .timeline-dot .social-timelines:before {
    left: 38px; }
  .timeline-dot .social-timelines-left:after {
    right: -50px; } }
@media only screen and (max-width: 1199px) {
  .pagination {
    float: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important; }

  .pagination li {
    float: left;
    margin-bottom: 10px; }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 14px; }

  #design-wizard .steps li a {
    padding: 1em 0.5em; }

  .blog-page-card {
    margin-bottom: 30px; }
    .blog-page-card:last-child {
      margin-bottom: 0; }

  #draggablePanelList [class*="col-"] .card-sub {
    margin-bottom: 30px; }
  #draggablePanelList [class*="col-"]:last-child .card-sub {
    margin-bottom: 0; }

  .user-head {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

  .user-body {
    min-height: auto !important; }

  .mail-box-head {
    text-align: center; }
    .mail-box-head form {
      float: inherit; }

  #external-events h6 {
    margin-top: 0;
    margin-bottom: 20px; }

  .just_edit .input-group {
    width: 100% !important; }

  .dotted-line-theme .ibtn_container {
    top: 10px;
    position: inherit; }

  .users-card [class*="col-"] {
    margin-bottom: 20px; }
    .users-card [class*="col-"]:last-child {
      margin-bottom: 0; }

  .timeline-btn {
    text-align: center;
    width: 100%;
    right: 0; }

  .social-timeline-left {
    position: inherit;
    top: 0;
    margin-right: 0; }

  .timeline-icon {
    position: absolute;
    margin-left: 0; }

  .social-timelines:before {
    left: 35px; }

  .timeline-dot:after,
  .timeline-dot:before {
    left: 32px; }

  .user-box .social-client-description {
    border-bottom: 1px solid #ddd; }

  .user-box .f-right {
    float: none;
    display: block; }

  .main-timeline {
    padding: 10px; }

  .main-timeline:after,
  .main-timeline:before {
    margin-left: -94px;
    margin-top: 25px;
    display: none; }

  .cd-timeline-content::before {
    top: 15px; }

  .cd-date,
  .cd-details {
    display: block;
    padding-left: 20px !important;
    padding-top: 0 !important; }

  .cd-details {
    margin-bottom: 20px; }

  .cd-timeline-content p {
    margin-bottom: 0; }

  .clock-widget .basic-alarm {
    display: none; }

  .weather-card-2 .weather-card-temp {
    padding: 0; }

  .weather-card-2 .card-footer {
    padding-top: 20px;
    padding-bottom: 20px; }

  .user-activity-card .card-block-big {
    padding-top: 15px;
    padding-bottom: 15px; } }
@media only screen and (max-width: 992px) {
  .navbar,
  .navbar .navbar-logo {
    width: 100% !important; }

  .card-block [class*="col-"]:last-child .basic-list,
  .card-block [class*="col-"]:nth-last-child(-n+3) .card.user-card {
    margin-bottom: 0; }

  #dt-live-dom .form-control,
  #footer-search tfoot .form-control,
  #footer-select tfoot .form-control,
  #form-input-table .form-control,
  .search-api .form-control {
    width: 80%; }

  .pcoded-main-container {
    margin-top: 56px !important; }

  .card-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px; }

  .header-navbar {
    position: fixed !important;
    z-index: 1209; }
    .header-navbar[pcoded-header-position="relative"] {
      position: fixed !important;
      top: 0; }
    .header-navbar .navbar-wrapper .navbar-container {
      margin-left: 0; }
    .header-navbar .navbar-wrapper .navbar-logo {
      width: 100% !important; }
      .header-navbar .navbar-wrapper .navbar-logo a img {
        display: block !important; }
      .header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
        right: auto;
        left: 0;
        top: 0;
        width: 60px; }
      .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
        display: block;
        position: absolute;
        left: 70px;
        top: 0; }
      .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        line-height: 3.5;
        width: 60px; }
    .header-navbar .navbar-container .nav-left, .header-navbar .navbar-wrapper .navbar-container .nav-right {
      display: none; }

  .btn-blog {
    text-align: center; }

  .animation-image img {
    margin: 20px auto 0; }

  .animation-type {
    text-align: center; }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 16px; }

  .widget-card-user .card-user {
    text-align: center; } }
@media only screen and (min-width: 992px) {
  .btn-blog {
    text-align: right; }

  .basic-list,
  .card-block [class*="col-"]:nth-last-child(-n+3) .card.user-card {
    margin-bottom: 0; }

  .menu-rtl .breadcrumb-item + .breadcrumb-item::after {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #868e96;
    content: "/"; }
  .menu-rtl .breadcrumb-item + .breadcrumb-item::before {
    content: none; } }
@media only screen and (max-width: 991px) {
  .main-body .page-wrapper .align-items-end .page-header-title {
    text-align: center;
    display: block; }
    .main-body .page-wrapper .align-items-end .page-header-title i {
      float: none;
      margin-bottom: 10px;
      margin-right: 0; }
  .main-body .page-wrapper .align-items-end body .pcoded[vertical-placement="right"] .main-body {
    direction: ltr; }
  .main-body .page-wrapper .align-items-end .page-header-breadcrumb {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: block; }

  .page-header-breadcrumb .breadcrumb-item {
    display: inline-block;
    float: none; }

  .messages-content {
    border-right: none; } }
@media only screen and (min-width: 801px) and (max-width: 915px) {
  .grid figure {
    min-width: auto; } }
@media only screen and (min-width: 768px) {
  #draggableWithoutImg [class*="col-"]:last-child .m-t-20 {
    margin-top: 0; } }
@media only screen and (min-width: 768px) and (max-width: 872px) {
  .author-details .header-right li {
    margin-right: 40px; }

  .recent-candidate-card .recent-contain {
    margin-left: 0; }

  .author-details img ~ .dot1,
  .author-details img ~ .dot2 {
    top: 30%; } }
@media only screen and (max-width: 768px) {
  .card-block.list-tag ul li:last-child {
    margin-bottom: 20px; }

  .inline-order-list {
    margin-top: 0; }

  h4 {
    font-size: 1rem;
    font-weight: 600; }

  .recent-candidate-card .recent-contain {
    margin-left: 0; }

  .mail-body-content .form-group:nth-child(2) .form-control {
    margin-bottom: 20px; }
  .mail-body-content .form-group:nth-child(2) .col-md-6:last-child .form-control {
    margin-bottom: 0; }

  .card h5 {
    font-size: 16px; }

  .header-navbar .navbar-wrapper .navbar-logo {
    display: inherit; }
    .header-navbar .navbar-wrapper .navbar-logo a {
      display: inline-block; }

  body.header-fixed .main-body .page-wrapper {
    padding-bottom: 0;
    padding-top: 15px; }

  .card-header-right i:nth-child(n+2) {
    display: none; }

  .dataTables_paginate .pagination {
    float: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important; }

  div.dataTables_wrapper div.dataTables_info {
    display: inherit; }

  .wizard > .steps > ul > li {
    float: inherit;
    width: 100%; }

  .gallery-page .grid {
    padding: 0; }

  figure.effect-steve h2 {
    margin-top: 0; }

  .cover-profile .profile-bg-img {
    margin-bottom: 0; }

  .card-block.user-info {
    position: inherit;
    text-align: center;
    background-color: #d6d6d6;
    margin-bottom: 20px; }

  .user-info .media-body,
  .user-info .media-left {
    display: block; }

  .cover-btn {
    position: inherit;
    float: none; }
    .cover-btn .btn:first-child {
      margin-right: 10px; }

  .user-info .user-title {
    position: initial;
    margin-bottom: 10px;
    margin-top: 10px; }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 14px; }

  .user-content h4 {
    font-size: 1rem; }
  .user-content h5 {
    margin-right: 0;
    font-size: 14px; }

  .top-cap-text p {
    padding: 0; }
    .top-cap-text p:last-child {
      margin-bottom: 0; }

  .user-card .btn span {
    font-size: 13px; }

  .toolbar-page [class*="col-"] {
    margin-bottom: 30px; }
    .toolbar-page [class*="col-"]:last-child {
      margin-bottom: 0; }

  .offline-404 h1 {
    font-size: 80px; }
  .offline-404 h2 {
    font-size: 25px; }

  .invoice-contact {
    margin-top: 20px;
    margin-bottom: 0; }

  .lng-dropdown {
    display: none; }

  .group-widget .card-block-big {
    height: 110px; }

  .whether-card .card-footer {
    text-align: center; }

  .client-blocks ul li {
    font-size: 30px; }

  .widget-profile-card-1 ~ .card-footer [class*=col-] {
    border: none;
    margin: 0 auto; }

  .large-widget-card i {
    top: 15px; }

  .user-activity-card .user-number {
    padding: 15px 0; }

  .user-activity-card .m-b-40 {
    margin-bottom: 10px; }

  .blur-user-card .blur-footer [class*="col-"] {
    margin: 0 auto;
    border: none; }

  .switchery {
    margin-bottom: 20px; }

  .switchery-large,
  .switchery-large ~ .switchery-default,
  .switchery-small {
    margin-bottom: 0; }

  .js-dynamic-disable,
  .js-dynamic-enable {
    display: block;
    width: 100%; }

  .js-dynamic-disable {
    margin-bottom: 20px; }

  .border-checkbox-section .border-checkbox-group,
  .checkbox-color,
  .radio-inline {
    display: block; }

  .checkbox-fade {
    display: inline-block; }

  body.horizontal-icon .page-header, body.horizontal-icon-fixed .page-header {
    margin-top: 0; }
  body.horizontal-icon .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon, body.horizontal-icon-fixed .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    display: inline-block !important;
    margin-right: 20px !important; } }
@media only screen and (max-width: 767px) {
  .signup-card {
    position: inherit; }

  .similar-job .media {
    display: inline-block; }
    .similar-job .media .media-body {
      margin-top: 20px; }

  .cal-event .inverse-card p:after {
    width: 100%; }

  .recent-candidate-card .recent-contain {
    margin-left: 10px; }

  .author-details .header-right {
    text-align: left;
    margin-top: 20px; }

  .post-new-contain textarea:focus {
    border: none; }

  .wizard.vertical > .content,
  .wizard.vertical > .steps {
    width: 100%; }

  .wizard {
    overflow: visible; }

  .comment-input .input-group {
    display: block; }
    .comment-input .input-group i {
      text-align: center;
      width: 100%; }
    .comment-input .input-group input {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px; } }
@media only screen and (max-width: 640px) {
  .dt-button {
    margin-bottom: 5px; }

  .email-read .card-header h6 {
    float: none;
    margin-top: 10px; }
  .email-read .media {
    display: inline-block; }
  .email-read .media.m-b-20 {
    margin-bottom: 0; }
  .email-read .mail-img [class*="col-"] {
    margin-bottom: 30px; }
    .email-read .mail-img [class*="col-"]:last-child {
      margin-bottom: 0; } }
@media only screen and (max-width: 991px) {
  .main-body .page-wrapper .align-items-end .page-header-title {
    text-align: center;
    display: block; }
    .main-body .page-wrapper .align-items-end .page-header-title i {
      float: none;
      margin-bottom: 10px; }
  .main-body .page-wrapper .align-items-end body .pcoded[vertical-placement="right"] .main-body {
    direction: ltr; }
  .main-body .page-wrapper .align-items-end .page-header-breadcrumb {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: block; }

  .page-header-breadcrumb .breadcrumb-item {
    display: inline-block;
    float: none; }

  .messages-content {
    border-right: none; } }
@media only screen and (max-width: 993px) {
  .navbar,
  .navbar .navbar-logo {
    width: 100% !important; } }
@media only screen and (max-width: 573px) {
  .author-details .header-right li {
    margin-right: 20px;
    display: block;
    margin-top: 20px; }

  .md-tabs {
    display: initial; }
    .md-tabs .nav-item {
      width: calc(100% / 1);
      position: relative; }

  .toolbar-page [class*="col-"] .sub-title {
    text-align: center; }

  #task-container li {
    width: 100%; }

  .nav-tabs.tabs {
    display: inherit; }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none; }

  .nav-tabs .nav-link {
    text-align: center; }

  .email-card .nav-tabs .nav-link {
    text-align: left; }

  .search-content {
    display: block;
    text-align: center; }
    .search-content img {
      margin-top: 20px; }

  .seacrh-header {
    margin-top: 0; }
    .seacrh-header .input-group {
      margin-bottom: 0; }

  .payment-card .text-right {
    text-align: left !important; }

  .payment-tabs .md-tabs .nav-item,
  .payment-tabs .md-tabs .nav-item .nav-item,
  .payment-tabs .md-tabs .nav-item + .nav-item {
    width: calc(100% / 1) !important; }

  .jp-card {
    min-width: 225px !important; }

  .payment-form {
    padding: 20px 0 0; }

  .modal-mob-btn [class*="col-"]:first-child {
    margin-bottom: 20px; }

  .location-mob-btn .btn {
    margin-bottom: 10px;
    margin-right: 0; }

  .job-cards .media {
    display: inline-block;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px; }
    .job-cards .media p {
      margin-bottom: 0; }

  .invoice-btn-group .m-r-20 {
    margin-right: 0; }

  .table-card .row-table {
    display: block;
    text-align: center; }
    .table-card .row-table i {
      font-size: 28px;
      margin-bottom: 10px;
      display: inline-block; }

  .table-card .row-table .br {
    border: none;
    border-bottom: 1px solid #ccc; }

  .table-card .row-table > [class*=col-] {
    display: block; }

  .card-block-big {
    padding: 1em; }
    .card-block-big #barchart,
    .card-block-big #barchart2 {
      display: inline-block;
      margin-bottom: 10px; }

  .widget-primary-card [class*="col-"] {
    padding: 1em; }
    .widget-primary-card [class*="col-"] h6 {
      margin-bottom: 0; }

  .cd-horizontal-timeline .events-content em {
    text-align: left; }

  .events-content h2 {
    font-size: 20px; }

  .cd-horizontal-timeline {
    margin: 0 auto; }

  .social-widget-card i {
    top: 25px;
    font-size: 38px; }

  .widget-chat-box .card-header i {
    display: none; }

  .mobile-inputs [class*="col-"]:first-child {
    margin-bottom: 20px; }

  .pagination-lg .page-link {
    padding: 0.75rem 0.65rem; }

  .mobtn {
    padding: 10px; }

  .task-sale-card {
    text-align: center; } }
@media only screen and (max-width: 460px) {
  .fc-toolbar .fc-right .fc-button-group {
    margin-right: 0; }

  .social-msg a {
    margin-bottom: 10px; }
    .social-msg a:last-child {
      margin-bottom: 0; }

  .bd-example-modal .modal-footer {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .bd-example-modal .modal-footer button {
      margin-right: 0; }

  .invoice-total tbody {
    float: none; }

  .timeline-right .card {
    margin-left: 20px; }

  .user-box .media-left {
    padding-right: 10px; }

  figure.effect-moses h2 {
    width: 60%;
    height: 70%;
    padding: 10px;
    font-size: 25px; }

  figure.effect-moses p {
    padding: 5px; } }
@media only screen and (max-width: 480px) {
  .j-pro .j-checkbox-toggle {
    padding-bottom: 30px; }

  .main-body .page-wrapper {
    padding: 10px; }

  .blog-page hr {
    margin-top: 20px;
    margin-bottom: 20px; }

  .blog-big-user {
    text-align: center; }
    .blog-big-user img {
      margin-bottom: 20px; }

  .blog-s-reply .media .media-left {
    padding-right: 10px; }

  .j-forms .divider-text span {
    white-space: pre-line;
    display: block;
    line-height: 1.8; }

  .j-forms .divider-text {
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px; }

  .j-forms .content {
    padding: 0 10px; }

  .comment-block .media-left {
    padding-right: 10px; }

  .breadcrumb-page .card {
    text-align: center; }

  div.dataTables_wrapper div.dataTables_filter input {
    width: 150px; }

  #dt-live-dom .form-control,
  #footer-search tfoot .form-control,
  #footer-select tfoot .form-control,
  #form-input-table .form-control,
  .search-api .form-control {
    width: 65%;
    padding: 0.5rem 6px; }

  .grid figure {
    min-width: auto; }

  .mytooltip .tooltip-content {
    width: 310px;
    margin: 0 0 20px -90px; }
    .mytooltip .tooltip-content:after {
      margin-left: -90px; }

  .cd-timeline-content .media {
    display: inherit !important;
    padding-left: 20px; }
    .cd-timeline-content .media .media-left {
      text-align: center;
      margin: 10px auto; }

  .summernote-page .modal-footer {
    display: inline-block;
    text-align: center; }

  .summernote-page .modal-footer > :not(:first-child) {
    margin-top: 10px;
    margin-left: 0; }

  .product-detail .txt-muted.d-inline-block {
    margin-top: 10px; }
    .product-detail .txt-muted.d-inline-block ~ .f-right {
      float: none;
      display: block;
      margin-top: 10px; }

  .mob-product-btn {
    text-align: center; }
    .mob-product-btn .btn-primary, .mob-product-btn .sweet-alert button.confirm, .sweet-alert .mob-product-btn button.confirm, .mob-product-btn .wizard > .actions a, .wizard > .actions .mob-product-btn a {
      margin-right: 0;
      display: block;
      margin: 10px auto; } }
@media only screen and (max-width: 420px) {
  #msform {
    width: 320px;
    margin: 20px auto; }

  .ms-container {
    width: 240px; }
    .ms-container .ms-selectable,
    .ms-container .ms-selection {
      width: 40%; }

  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }
@media only screen and (min-width: 576px) and (max-width: 1366px) {
  .job-card.card-columns {
    -webkit-column-count: 2;
            column-count: 2; } }
@media only screen and (max-width: 576px) {
  .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    min-width: 280px;
    left: 0;
    right: auto;
    width: auto; }

  .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    left: auto;
    right: 0; }

  .nav-tabs .nav-link.active {
    margin-bottom: 0; }

  .md-tabs .nav-item a {
    /* box-shadow: 0 0 0 1px #d2cece; */
    margin-bottom: 5px; }

  .add-task-card .to-do-list h6 span {
    display: inline-block; } }
.pcoded[layout-type="dark"],
body.dark {
  color: #fff; }
  .pcoded[layout-type="dark"] a,
  body.dark a {
    color: #fff; }
  .pcoded[layout-type="dark"] text,
  body.dark text {
    fill: #fff; }
  .pcoded[layout-type="dark"] .card,
  body.dark .card {
    background-color: #41555e; }
    .pcoded[layout-type="dark"] .card code,
    body.dark .card code {
      background-color: #4c626d; }
    .pcoded[layout-type="dark"] .card .card-header h5,
    body.dark .card .card-header h5 {
      color: #fff; }
    .pcoded[layout-type="dark"] .card .card-header .card-header-right i,
    body.dark .card .card-header .card-header-right i {
      color: #fff; }
    .pcoded[layout-type="dark"] .card .card-block code,
    body.dark .card .card-block code {
      background-color: #4c626d; }
    .pcoded[layout-type="dark"] .card .card-block .dropdown-menu,
    body.dark .card .card-block .dropdown-menu {
      background-color: #465a64; }
    .pcoded[layout-type="dark"] .card:hover,
    body.dark .card:hover {
      -webkit-box-shadow: 0 0 25px -5px #37474f;
              box-shadow: 0 0 25px -5px #37474f; }
  .pcoded[layout-type="dark"] .card-footer,
  body.dark .card-footer {
    background-color: #3d4f58; }
  .pcoded[layout-type="dark"] .dropdown-menu,
  body.dark .dropdown-menu {
    background-color: #465a64; }
    .pcoded[layout-type="dark"] .dropdown-menu .dropdown-item .dropdown-divider,
    body.dark .dropdown-menu .dropdown-item .dropdown-divider {
      border-top: 1px solid #37474f; }
    .pcoded[layout-type="dark"] .dropdown-menu .dropdown-item:focus, .pcoded[layout-type="dark"] .dropdown-menu .dropdown-item:hover,
    body.dark .dropdown-menu .dropdown-item:focus,
    body.dark .dropdown-menu .dropdown-item:hover {
      background-color: #37474f; }
  .pcoded[layout-type="dark"] .sub-title,
  body.dark .sub-title {
    color: #617d8b; }
  .pcoded[layout-type="dark"] .table td,
  .pcoded[layout-type="dark"] .table th,
  body.dark .table td,
  body.dark .table th {
    border-top: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .table thead th,
  body.dark .table thead th {
    border-bottom: 2px solid #4c626d; }
  .pcoded[layout-type="dark"] .thumb-block,
  body.dark .thumb-block {
    border: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .b-t-default,
  .pcoded[layout-type="dark"] .b-t-theme,
  .pcoded[layout-type="dark"] .post-new-footer,
  .pcoded[layout-type="dark"] .version .nav li:first-child,
  body.dark .b-t-default,
  body.dark .b-t-theme,
  body.dark .post-new-footer,
  body.dark .version .nav li:first-child {
    border-top: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .b-b-default,
  .pcoded[layout-type="dark"] .b-b-theme,
  .pcoded[layout-type="dark"] .job-cards .media,
  .pcoded[layout-type="dark"] .search-content,
  body.dark .b-b-default,
  body.dark .b-b-theme,
  body.dark .job-cards .media,
  body.dark .search-content {
    border-bottom: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .b-l-default,
  .pcoded[layout-type="dark"] .b-l-theme,
  body.dark .b-l-default,
  body.dark .b-l-theme {
    border-left: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .b-r-default,
  .pcoded[layout-type="dark"] .b-r-theme,
  .pcoded[layout-type="dark"] .messages-content,
  body.dark .b-r-default,
  body.dark .b-r-theme,
  body.dark .messages-content {
    border-right: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .progress,
  body.dark .progress {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .btn-default,
  body.dark .btn-default {
    background-color: #4c626d;
    color: #fff; }
  .pcoded[layout-type="dark"] .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
  body.dark .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #4c626d !important; }
  .pcoded[layout-type="dark"] .pcoded-main-container,
  body.dark .pcoded-main-container {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .show-notification li:first-child:hover, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .profile-notification li:first-child:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .show-notification li:first-child:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .profile-notification li:first-child:hover {
    background-color: #37474f !important; }
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .profile-notification,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .profile-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .profile-notification,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .profile-notification {
    background-color: #37474f; }
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .profile-notification a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .profile-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .profile-notification a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .profile-notification a {
      color: #fff; }
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .row.profile-notification li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .row.profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .row.profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .row.profile-notification li:hover {
    background-color: transparent; }
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover .mega-menu-links > li:hover a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover .mega-menu-links > li:hover a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a,
    .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a,
    body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a {
      color: #448aff; }
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover,
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .header-navbar .morphsearch.open,
  body.dark .header-navbar .morphsearch.open {
    background-color: #37474f; }
    .pcoded[layout-type="dark"] .header-navbar .morphsearch.open .morphsearch-form,
    body.dark .header-navbar .morphsearch.open .morphsearch-form {
      background-color: #4c626d; }
    .pcoded[layout-type="dark"] .header-navbar .morphsearch.open .morphsearch-content,
    body.dark .header-navbar .morphsearch.open .morphsearch-content {
      background-color: #37474f; }
      .pcoded[layout-type="dark"] .header-navbar .morphsearch.open .morphsearch-content .dummy-media-object,
      body.dark .header-navbar .morphsearch.open .morphsearch-content .dummy-media-object {
        background-color: #4c626d; }
  .pcoded[layout-type="dark"] .p-chat-user,
  body.dark .p-chat-user {
    border-left: 1px solid #4c626d; }
    .pcoded[layout-type="dark"] .p-chat-user .userlist-box,
    body.dark .p-chat-user .userlist-box {
      border-bottom: 1px solid #4c626d; }
      .pcoded[layout-type="dark"] .p-chat-user .userlist-box .chat-header,
      body.dark .p-chat-user .userlist-box .chat-header {
        color: #fff; }
  .pcoded[layout-type="dark"] .showChat_inner,
  body.dark .showChat_inner {
    background-color: #4c626d;
    border-left: 1px solid #222c31; }
    .pcoded[layout-type="dark"] .showChat_inner .chat-menu-content,
    body.dark .showChat_inner .chat-menu-content {
      background-color: #37474f; }
      .pcoded[layout-type="dark"] .showChat_inner .chat-menu-content:before,
      body.dark .showChat_inner .chat-menu-content:before {
        border-right-color: #37474f; }
    .pcoded[layout-type="dark"] .showChat_inner .chat-inner-header,
    body.dark .showChat_inner .chat-inner-header {
      border-bottom: 1px solid #37474f; }
    .pcoded[layout-type="dark"] .showChat_inner .chat-menu-reply,
    body.dark .showChat_inner .chat-menu-reply {
      background-color: #222c31; }
      .pcoded[layout-type="dark"] .showChat_inner .chat-menu-reply:before,
      body.dark .showChat_inner .chat-menu-reply:before {
        border-left-color: #222c31; }
    .pcoded[layout-type="dark"] .showChat_inner .chat-reply-box,
    body.dark .showChat_inner .chat-reply-box {
      background-color: #4c626d;
      border-top: 1px solid #37474f; }
  .pcoded[layout-type="dark"] .form-control,
  body.dark .form-control {
    background-color: transparent;
    color: #fff; }
  .pcoded[layout-type="dark"] .main-body .page-wrapper .page-header-title h4,
  body.dark .main-body .page-wrapper .page-header-title h4 {
    color: #fff; }
  .pcoded[layout-type="dark"] .profile-comp-block input.dial,
  .pcoded[layout-type="dark"] .view-card input.dial,
  body.dark .profile-comp-block input.dial,
  body.dark .view-card input.dial {
    color: #fff !important; }
  .pcoded[layout-type="dark"] .line-example,
  body.dark .line-example {
    border-right: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .marketing-card .table tbody .marketing-header,
  body.dark .marketing-card .table tbody .marketing-header {
    background: #222c31; }
  .pcoded[layout-type="dark"] .unique-visitor-card .footer-card,
  body.dark .unique-visitor-card .footer-card {
    background-color: #222c31; }
  .pcoded[layout-type="dark"] .user-detail-card .user-detail > div,
  body.dark .user-detail-card .user-detail > div {
    border-bottom: 1px solid #4c626d; }
    .pcoded[layout-type="dark"] .user-detail-card .user-detail > div:last-child,
    body.dark .user-detail-card .user-detail > div:last-child {
      border-bottom: none; }
  .pcoded[layout-type="dark"] .client-map .client-contain h5,
  body.dark .client-map .client-contain h5 {
    color: #fff; }
  .pcoded[layout-type="dark"] .client-map .client-card-box,
  body.dark .client-map .client-card-box {
    border: 1px solid #4c626d; }
    .pcoded[layout-type="dark"] .client-map .client-card-box .client-border,
    body.dark .client-map .client-card-box .client-border {
      border-right: 1px solid #4c626d; }
    .pcoded[layout-type="dark"] .client-map .client-card-box .client-border-card,
    body.dark .client-map .client-card-box .client-border-card {
      border-top: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .widget-card-user .card-footer,
  body.dark .widget-card-user .card-footer {
    background-color: #222c31; }
  .pcoded[layout-type="dark"] .widget-card-user .card-footer .footer-menu + .footer-menu,
  body.dark .widget-card-user .card-footer .footer-menu + .footer-menu {
    border-left: 2px solid #4c626d; }
  .pcoded[layout-type="dark"] .card-contact-box .f-btn:hover,
  body.dark .card-contact-box .f-btn:hover {
    background-color: #41555e; }
  .pcoded[layout-type="dark"] .to-do-list,
  body.dark .to-do-list {
    border-bottom: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .widget-chat-box .media .send-chat,
  body.dark .widget-chat-box .media .send-chat {
    background-color: #4c626d; }
    .pcoded[layout-type="dark"] .widget-chat-box .media .send-chat:before,
    body.dark .widget-chat-box .media .send-chat:before {
      border-right: 15px solid #4c626d; }
  .pcoded[layout-type="dark"] .widget-chat-box .media .receive-chat,
  body.dark .widget-chat-box .media .receive-chat {
    background-color: #222c31; }
    .pcoded[layout-type="dark"] .widget-chat-box .media .receive-chat:before,
    body.dark .widget-chat-box .media .receive-chat:before {
      border-left: 15px solid #222c31; }
  .pcoded[layout-type="dark"] .card-comment .card-block-small,
  body.dark .card-comment .card-block-small {
    border-bottom: 1px solid #41555e; }
    .pcoded[layout-type="dark"] .card-comment .card-block-small:hover,
    body.dark .card-comment .card-block-small:hover {
      background-color: #41555e; }
  .pcoded[layout-type="dark"] .alert.alert-danger, .pcoded[layout-type="dark"] .alert.alert-default, .pcoded[layout-type="dark"] .alert.alert-info, .pcoded[layout-type="dark"] .alert.alert-primary, .pcoded[layout-type="dark"] .alert.alert-success, .pcoded[layout-type="dark"] .alert.alert-warning,
  body.dark .alert.alert-danger,
  body.dark .alert.alert-default,
  body.dark .alert.alert-info,
  body.dark .alert.alert-primary,
  body.dark .alert.alert-success,
  body.dark .alert.alert-warning {
    background-color: #37474f; }
  .pcoded[layout-type="dark"] .alert.background-default,
  body.dark .alert.background-default {
    background-color: #d6d6d6;
    color: #fff; }
  .pcoded[layout-type="dark"] .alert.background-primary,
  body.dark .alert.background-primary {
    background-color: #448aff;
    color: #fff; }
  .pcoded[layout-type="dark"] .alert.background-success,
  body.dark .alert.background-success {
    background-color: #11c15b;
    color: #fff; }
  .pcoded[layout-type="dark"] .alert.background-info,
  body.dark .alert.background-info {
    background-color: #00bcd4;
    color: #fff; }
  .pcoded[layout-type="dark"] .alert.background-warning,
  body.dark .alert.background-warning {
    background-color: #ffe100;
    color: #fff; }
  .pcoded[layout-type="dark"] .alert.background-danger,
  body.dark .alert.background-danger {
    background-color: #ff5252;
    color: #fff; }
  .pcoded[layout-type="dark"] .accordion-msg,
  body.dark .accordion-msg {
    color: #fff;
    border-top: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .accordion-desc,
  body.dark .accordion-desc {
    color: #fff; }
  .pcoded[layout-type="dark"] .md-tabs .nav-item a,
  body.dark .md-tabs .nav-item a {
    color: #fff; }
    .pcoded[layout-type="dark"] .md-tabs .nav-item a.active, .pcoded[layout-type="dark"] .md-tabs .nav-item a:focus, .pcoded[layout-type="dark"] .md-tabs .nav-item a:hover,
    body.dark .md-tabs .nav-item a.active,
    body.dark .md-tabs .nav-item a:focus,
    body.dark .md-tabs .nav-item a:hover {
      color: #448aff; }
    .pcoded[layout-type="dark"] .md-tabs .nav-item a.active:focus, .pcoded[layout-type="dark"] .md-tabs .nav-item a.active:hover,
    body.dark .md-tabs .nav-item a.active:focus,
    body.dark .md-tabs .nav-item a.active:hover {
      color: #448aff; }
  .pcoded[layout-type="dark"] .nav-tabs,
  body.dark .nav-tabs {
    border-bottom: 1px solid #4c626d; }
    .pcoded[layout-type="dark"] .nav-tabs .nav-link:focus, .pcoded[layout-type="dark"] .nav-tabs .nav-link:hover,
    body.dark .nav-tabs .nav-link:focus,
    body.dark .nav-tabs .nav-link:hover {
      border-color: #4c626d; }
  .pcoded[layout-type="dark"] .tab-below,
  body.dark .tab-below {
    border-top: 1px solid #4c626d;
    border-bottom: none; }
    .pcoded[layout-type="dark"] .tab-below .nav-link,
    body.dark .tab-below .nav-link {
      margin-top: 1px; }
  .pcoded[layout-type="dark"] .scroll-list li,
  body.dark .scroll-list li {
    background-color: #465a64;
    color: #fff; }
    .pcoded[layout-type="dark"] .scroll-list li:nth-child(even),
    body.dark .scroll-list li:nth-child(even) {
      background-color: #506773; }
  .pcoded[layout-type="dark"] .panel,
  body.dark .panel {
    background-color: #4c626d; }
    .pcoded[layout-type="dark"] .panel .panel-footer,
    body.dark .panel .panel-footer {
      background-color: #465a64;
      border-top: 1px solid #28343a; }
  .pcoded[layout-type="dark"] .well,
  body.dark .well {
    background-color: #28343a;
    border: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .page-link,
  body.dark .page-link {
    background-color: #4c626d;
    border-color: #28343a; }
  .pcoded[layout-type="dark"] .jqpagination input,
  body.dark .jqpagination input {
    margin: 0;
    height: 30px; }
  .pcoded[layout-type="dark"] .nav-tabs .nav-item.show .nav-link, .pcoded[layout-type="dark"] .nav-tabs .nav-link.active,
  body.dark .nav-tabs .nav-item.show .nav-link,
  body.dark .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #41555e;
    border-color: #ddd #ddd #41555e; }
  .pcoded[layout-type="dark"] .nav-tabs.tab-below .nav-item.show .nav-link, .pcoded[layout-type="dark"] .nav-tabs.tab-below .nav-link.active,
  body.dark .nav-tabs.tab-below .nav-item.show .nav-link,
  body.dark .nav-tabs.tab-below .nav-link.active {
    border-color: #41555e #ddd #ddd; }
  .pcoded[layout-type="dark"] .modal-content,
  body.dark .modal-content {
    background-color: #465a64; }
    .pcoded[layout-type="dark"] .modal-content .modal-header,
    body.dark .modal-content .modal-header {
      border-bottom: 1px solid #37474f; }
    .pcoded[layout-type="dark"] .modal-content .modal-footer,
    body.dark .modal-content .modal-footer {
      border-top: 1px solid #37474f; }
  .pcoded[layout-type="dark"] .br-theme-bars-square .br-widget a,
  body.dark .br-theme-bars-square .br-widget a {
    background-color: #465a64; }
  .pcoded[layout-type="dark"] .jstree-anchor.jstree-hovered,
  body.dark .jstree-anchor.jstree-hovered {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .dd-handle,
  body.dark .dd-handle {
    color: #fff; }
  .pcoded[layout-type="dark"] .dd3-content,
  body.dark .dd3-content {
    color: #fff;
    border: 1px solid #37474f;
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .img-thumbnail,
  body.dark .img-thumbnail {
    background-color: #37474f;
    border: 1px solid #28343a; }
  .pcoded[layout-type="dark"] .dotted-line-theme .no_edit:hover,
  body.dark .dotted-line-theme .no_edit:hover {
    background-color: #4c626d;
    border-bottom: 1px solid #37474f; }
  .pcoded[layout-type="dark"] .icon-list-demo i,
  body.dark .icon-list-demo i {
    color: #fff; }
  .pcoded[layout-type="dark"] .form-radio label,
  body.dark .form-radio label {
    color: #fff; }
  .pcoded[layout-type="dark"] .bootstrap-tagsinput,
  body.dark .bootstrap-tagsinput {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] #reportrange,
  body.dark #reportrange {
    background-color: #4c626d !important; }
  .pcoded[layout-type="dark"] .select2-container--default .select2-selection--multiple,
  .pcoded[layout-type="dark"] .select2-dropdown,
  body.dark .select2-container--default .select2-selection--multiple,
  body.dark .select2-dropdown {
    background-color: #465a64; }
  .pcoded[layout-type="dark"] .ms-container .ms-selectable,
  .pcoded[layout-type="dark"] .ms-container .ms-selection,
  body.dark .ms-container .ms-selectable,
  body.dark .ms-container .ms-selection {
    background-color: #465a64; }
    .pcoded[layout-type="dark"] .ms-container .ms-selectable li.ms-elem-selectable,
    .pcoded[layout-type="dark"] .ms-container .ms-selection li.ms-elem-selectable,
    body.dark .ms-container .ms-selectable li.ms-elem-selectable,
    body.dark .ms-container .ms-selection li.ms-elem-selectable {
      color: #fff; }
    .pcoded[layout-type="dark"] .ms-container .ms-selectable li.disabled,
    .pcoded[layout-type="dark"] .ms-container .ms-selection li.disabled,
    body.dark .ms-container .ms-selectable li.disabled,
    body.dark .ms-container .ms-selection li.disabled {
      background-color: #465a64;
      opacity: 0.5; }
  .pcoded[layout-type="dark"] .wizard > .content,
  body.dark .wizard > .content {
    background-color: #41555e; }
  .pcoded[layout-type="dark"] .wizard > .steps .disabled a,
  .pcoded[layout-type="dark"] .wizard > .steps .disabled a:active,
  .pcoded[layout-type="dark"] .wizard > .steps .disabled a:hover,
  body.dark .wizard > .steps .disabled a,
  body.dark .wizard > .steps .disabled a:active,
  body.dark .wizard > .steps .disabled a:hover {
    background-color: #465a64; }
  .pcoded[layout-type="dark"] .wizard > .steps .done a,
  body.dark .wizard > .steps .done a {
    background-color: #37474f; }
  .pcoded[layout-type="dark"] .table-bordered,
  body.dark .table-bordered {
    border: 1px solid #4c626d; }
    .pcoded[layout-type="dark"] .table-bordered td,
    .pcoded[layout-type="dark"] .table-bordered th,
    body.dark .table-bordered td,
    body.dark .table-bordered th {
      border: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] a.dt-button.disabled,
  .pcoded[layout-type="dark"] button.dt-button.disabled,
  .pcoded[layout-type="dark"] div.dt-button.disabled,
  body.dark a.dt-button.disabled,
  body.dark button.dt-button.disabled,
  body.dark div.dt-button.disabled {
    background-image: none;
    opacity: 0.5; }
  .pcoded[layout-type="dark"] .fixedHeader-floating,
  body.dark .fixedHeader-floating {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] table.DTFC_Cloned tfoot,
  .pcoded[layout-type="dark"] table.DTFC_Cloned thead,
  body.dark table.DTFC_Cloned tfoot,
  body.dark table.DTFC_Cloned thead {
    background-color: #465a64; }
  .pcoded[layout-type="dark"] .dt-responsive input,
  body.dark .dt-responsive input {
    background-color: #4c626d;
    border: 1px solid #41555e; }
  .pcoded[layout-type="dark"] .footable-details.table-striped > tbody > tr:nth-child(odd),
  .pcoded[layout-type="dark"] .footable.table-striped > tbody > tr:nth-child(odd),
  body.dark .footable-details.table-striped > tbody > tr:nth-child(odd),
  body.dark .footable.table-striped > tbody > tr:nth-child(odd) {
    background: #41555e; }
  .pcoded[layout-type="dark"] .footable-details.table > thead > tr > td,
  .pcoded[layout-type="dark"] .footable-details.table > thead > tr > th,
  .pcoded[layout-type="dark"] .footable.table > thead > tr > td,
  .pcoded[layout-type="dark"] .footable.table > thead > tr > th,
  body.dark .footable-details.table > thead > tr > td,
  body.dark .footable-details.table > thead > tr > th,
  body.dark .footable.table > thead > tr > td,
  body.dark .footable.table > thead > tr > th {
    border-bottom: 2px solid #37474f; }
  .pcoded[layout-type="dark"] .c3-tooltip td,
  .pcoded[layout-type="dark"] .morris-hover,
  body.dark .c3-tooltip td,
  body.dark .morris-hover {
    background-color: #37474f; }
  .pcoded[layout-type="dark"] #chart_Candlestick rect,
  .pcoded[layout-type="dark"] #chart_Combo rect,
  .pcoded[layout-type="dark"] #chart_Donut rect,
  .pcoded[layout-type="dark"] #chart_Exploading rect,
  .pcoded[layout-type="dark"] #chart_Threshold rect,
  .pcoded[layout-type="dark"] #chart_Waterfall rect,
  .pcoded[layout-type="dark"] #chart_area rect,
  .pcoded[layout-type="dark"] #chart_bar rect,
  .pcoded[layout-type="dark"] #chart_bubble rect,
  .pcoded[layout-type="dark"] #chart_stacking rect,
  body.dark #chart_Candlestick rect,
  body.dark #chart_Combo rect,
  body.dark #chart_Donut rect,
  body.dark #chart_Exploading rect,
  body.dark #chart_Threshold rect,
  body.dark #chart_Waterfall rect,
  body.dark #chart_area rect,
  body.dark #chart_bar rect,
  body.dark #chart_bubble rect,
  body.dark #chart_stacking rect {
    fill: #41555e !important; }
  .pcoded[layout-type="dark"] .auth-box .form-control,
  body.dark .auth-box .form-control {
    background-color: #fff; }
  .pcoded[layout-type="dark"] .chat-header,
  body.dark .chat-header {
    color: #fff; }
  .pcoded[layout-type="dark"] .social-msg a span,
  body.dark .social-msg a span {
    color: #f2f2f2; }
  .pcoded[layout-type="dark"] .bg-white,
  body.dark .bg-white {
    background: #41555e !important; }
  .pcoded[layout-type="dark"] .list-group-item,
  body.dark .list-group-item {
    background: #41555e; }
  .pcoded[layout-type="dark"] ul.list-contacts a,
  body.dark ul.list-contacts a {
    color: #fff; }
  .pcoded[layout-type="dark"] .card-header-img h5,
  .pcoded[layout-type="dark"] .card-header-img h6,
  body.dark .card-header-img h5,
  body.dark .card-header-img h6 {
    color: #fff; }
  .pcoded[layout-type="dark"] .prod-item .prod-info,
  body.dark .prod-item .prod-info {
    background: #41555e; }
  .pcoded[layout-type="dark"] .email-card .user-body,
  body.dark .email-card .user-body {
    background: #41555e; }
    .pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a,
    body.dark .email-card .user-body .page-list li.mail-section a {
      color: #fff; }
      .pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a.active, .pcoded[layout-type="dark"] .email-card .user-body .page-list li.mail-section a:hover,
      body.dark .email-card .user-body .page-list li.mail-section a.active,
      body.dark .email-card .user-body .page-list li.mail-section a:hover {
        background: rgba(255, 255, 255, 0.2); }
    .pcoded[layout-type="dark"] .email-card .user-body .label-list a,
    body.dark .email-card .user-body .label-list a {
      color: #fff; }
  .pcoded[layout-type="dark"] .email-card .mail-body-content tr:hover,
  body.dark .email-card .mail-body-content tr:hover {
    background: rgba(255, 255, 255, 0.2); }
  .pcoded[layout-type="dark"] .email-card .mail-body-content .unread a,
  body.dark .email-card .mail-body-content .unread a {
    color: #fff; }
  .pcoded[layout-type="dark"] .email-card .mail-body-content .read a,
  body.dark .email-card .mail-body-content .read a {
    color: #e6e6e6; }
  .pcoded[layout-type="dark"] .email-card .user-mail h6,
  body.dark .email-card .user-mail h6 {
    color: #fff; }
  .pcoded[layout-type="dark"] .wall-img-preview .wall-item,
  body.dark .wall-img-preview .wall-item {
    border: 2px solid #41555e; }
  .pcoded[layout-type="dark"] .msg-send,
  body.dark .msg-send {
    background: #485d67; }
  .pcoded[layout-type="dark"] .filter-bar > .navbar,
  body.dark .filter-bar > .navbar {
    background: #41555e; }
  .pcoded[layout-type="dark"] .navbar-light .navbar-nav .active > .nav-link,
  .pcoded[layout-type="dark"] .navbar-light .navbar-nav .nav-link,
  .pcoded[layout-type="dark"] .navbar-light .navbar-nav .nav-link.active,
  .pcoded[layout-type="dark"] .navbar-light .navbar-nav .nav-link.show,
  .pcoded[layout-type="dark"] .navbar-light .navbar-nav .show > .nav-link,
  body.dark .navbar-light .navbar-nav .active > .nav-link,
  body.dark .navbar-light .navbar-nav .nav-link,
  body.dark .navbar-light .navbar-nav .nav-link.active,
  body.dark .navbar-light .navbar-nav .nav-link.show,
  body.dark .navbar-light .navbar-nav .show > .nav-link {
    color: #fff; }
  .pcoded[layout-type="dark"] #task-container li,
  body.dark #task-container li {
    background: #4c626d;
    color: #fff; }
  .pcoded[layout-type="dark"] .text-inverse,
  body.dark .text-inverse {
    color: #fff !important; }
  .pcoded[layout-type="dark"] .note-card .note-write,
  body.dark .note-card .note-write {
    background: -webkit-linear-gradient(top, #617d8b 0%, #7c96a3 5%) 0 0;
    background-size: 100% 35px; }
  .pcoded[layout-type="dark"] .note-card .note-box-aside,
  body.dark .note-card .note-box-aside {
    border-right: 1px solid #4c626d; }
  .pcoded[layout-type="dark"] .invoice-table,
  body.dark .invoice-table {
    border-left: none; }
  .pcoded[layout-type="dark"] .invoice-total,
  .pcoded[layout-type="dark"] .thead-default th,
  body.dark .invoice-total,
  body.dark .thead-default th {
    background: #37474f; }
  .pcoded[layout-type="dark"] #external-events .fc-event,
  .pcoded[layout-type="dark"] .danger-nav,
  .pcoded[layout-type="dark"] .fc-state-default,
  .pcoded[layout-type="dark"] .info-nav,
  .pcoded[layout-type="dark"] .light-nav-border,
  .pcoded[layout-type="dark"] .primary-nav,
  .pcoded[layout-type="dark"] .success-nav,
  .pcoded[layout-type="dark"] .warning-nav,
  body.dark #external-events .fc-event,
  body.dark .danger-nav,
  body.dark .fc-state-default,
  body.dark .info-nav,
  body.dark .light-nav-border,
  body.dark .primary-nav,
  body.dark .success-nav,
  body.dark .warning-nav {
    background-color: #4c626d;
    color: #fff; }
  .pcoded[layout-type="dark"] .jFiler-input-dragDrop,
  .pcoded[layout-type="dark"] .jFiler-theme-default .jFiler-input,
  body.dark .jFiler-input-dragDrop,
  body.dark .jFiler-theme-default .jFiler-input {
    background-color: #3d4f58; }
  .pcoded[layout-type="dark"] .danger-nav .navbar-varient-submenu,
  .pcoded[layout-type="dark"] .info-nav .navbar-varient-submenu,
  .pcoded[layout-type="dark"] .light-nav-border .navbar-varient-submenu,
  .pcoded[layout-type="dark"] .primary-nav .navbar-varient-submenu,
  .pcoded[layout-type="dark"] .success-nav .navbar-varient-submenu,
  .pcoded[layout-type="dark"] .warning-nav .navbar-varient-submenu,
  body.dark .danger-nav .navbar-varient-submenu,
  body.dark .info-nav .navbar-varient-submenu,
  body.dark .light-nav-border .navbar-varient-submenu,
  body.dark .primary-nav .navbar-varient-submenu,
  body.dark .success-nav .navbar-varient-submenu,
  body.dark .warning-nav .navbar-varient-submenu {
    background-color: #465a64;
    color: #fff; }
  .pcoded[layout-type="dark"] .danger-nav .navbar-varient-submenu a:hover,
  .pcoded[layout-type="dark"] .info-nav .navbar-varient-submenu a:hover,
  .pcoded[layout-type="dark"] .light-nav-border .navbar-varient-submenu a:hover,
  .pcoded[layout-type="dark"] .primary-nav .navbar-varient-submenu a:hover,
  .pcoded[layout-type="dark"] .success-nav .navbar-varient-submenu a:hover,
  .pcoded[layout-type="dark"] .warning-nav .navbar-varient-submenu a:hover,
  body.dark .danger-nav .navbar-varient-submenu a:hover,
  body.dark .info-nav .navbar-varient-submenu a:hover,
  body.dark .light-nav-border .navbar-varient-submenu a:hover,
  body.dark .primary-nav .navbar-varient-submenu a:hover,
  body.dark .success-nav .navbar-varient-submenu a:hover,
  body.dark .warning-nav .navbar-varient-submenu a:hover {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .card.version .card-header .card-header-right,
  body.dark .card.version .card-header .card-header-right {
    background-color: #41555e;
    color: #fff; }
  .pcoded[layout-type="dark"] .version .nav li:first-child,
  body.dark .version .nav li:first-child {
    color: #fff; }
  .pcoded[layout-type="dark"] .version .nav li:hover,
  body.dark .version .nav li:hover {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .version .nav li a,
  body.dark .version .nav li a {
    color: #fff; }
  .pcoded[layout-type="dark"] .main-timeline,
  body.dark .main-timeline {
    background-color: #41555e; }
    .pcoded[layout-type="dark"] .main-timeline .bg-white,
    body.dark .main-timeline .bg-white {
      background-color: #4c626d !important; }
    .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content,
    body.dark .main-timeline .cd-timeline-content {
      background-color: #4c626d; }
      .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content h6,
      .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content i,
      .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content p,
      .pcoded[layout-type="dark"] .main-timeline .cd-timeline-content span,
      body.dark .main-timeline .cd-timeline-content h6,
      body.dark .main-timeline .cd-timeline-content i,
      body.dark .main-timeline .cd-timeline-content p,
      body.dark .main-timeline .cd-timeline-content span {
        color: #fff; }
  .pcoded[layout-type="dark"] #styleSelector,
  body.dark #styleSelector {
    color: #37474f; }
    .pcoded[layout-type="dark"] #styleSelector .form-control,
    body.dark #styleSelector .form-control {
      color: #37474f; }
  .pcoded[layout-type="dark"] .chat-card .received-chat .msg,
  body.dark .chat-card .received-chat .msg {
    background-color: #4c626d; }
  .pcoded[layout-type="dark"] .chat-card .send-chat .msg,
  body.dark .chat-card .send-chat .msg {
    background-color: #41555e; }
  .pcoded[layout-type="dark"] .chat-card .chat-saprator span,
  body.dark .chat-card .chat-saprator span {
    background: #41555e;
    color: #fff; }
  .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification.active:before,
  body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification.active:before {
    border-left-color: #37474f;
    border-top-color: #37474f; }
  .pcoded[layout-type="dark"] .chat-card .chat-control .chat-form .form-control,
  body.dark .chat-card .chat-control .chat-form .form-control {
    background-color: transparent; }
    .pcoded[layout-type="dark"] .chat-card .chat-control .chat-form .form-control:focus, .pcoded[layout-type="dark"] .chat-card .chat-control .chat-form .form-control:active,
    body.dark .chat-card .chat-control .chat-form .form-control:focus,
    body.dark .chat-card .chat-control .chat-form .form-control:active {
      -webkit-box-shadow: none;
              box-shadow: none; }
  .pcoded[layout-type="dark"] .main-menu .main-menu-header:before,
  .pcoded[layout-type="dark"] .page-header:before,
  body.dark .main-menu .main-menu-header:before,
  body.dark .page-header:before {
    background-color: rgba(76, 98, 109, 0.8); }

.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  -webkit-box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.bottom{
  line-height: initial;
  border-bottom:2px solid #2b5b84;
  margin-bottom:20px;
}
.fixed-button.active {
  bottom: 50px;
  opacity: 1;
}

.footer-wrapper{
background-image: linear-gradient(to right, #edeef0,#edeef0,#7e7e7e,#7e7e7e,#edeef0, #edeef0);
border:solid 0px;
padding-top:5px;
padding-bottom:4px;
color: white;
}
.footer-content{
  text-align: center;
  padding-top: 4px;
  padding-bottom: 2px;
}

.home-tabs{
display: flex;
flex-direction: row;
}
.home-tabs {
 color: #0d70d4;
}
.home-container{
  padding-left: 5%;
  padding-right: 5%;
}

.login{
  border-style:solid; 
  border-width:0.1px; 
  border-color:#00b39d;-moz-box-shadow:2px 2px 2px 2px #0985a3;-webkit-box-shadow: 2px 2px 2px 2px #0985a3;box-shadow:2px 2px 2px 2px #0985a3; padding-top: 10px;
             padding-bottom:10px;margin-top:40px;
             padding-right: 10px;
             padding-left: 10px;

}
.paragaraph-justification{
text-align: justify;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.li-style{
  text-align: justify;
  padding-bottom: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}
.insideheader{
  padding-bottom: 20px;
}
.registerlink{
  font-size: 12px;
  font-style: italic;
  color:#0e789d;
}
.backbutton{
  font-size: 11px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15; /* 1 */
  border: 0px;
  padding-left:5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  background-color: #e7e7e7; color:#000;
   display: flex;
  justify-content: center;
  border-radius: 3.5rem / 60%;
}
.card-header-title{
  font-style: italic;
  color:#0e789d;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}
.resetpasswordcontent{
  padding-left: 10px;
}
.btn-info:hover{
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.placeholder{
  font-style: italic;
   font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',

}
.p-error{
  color: red;
}
.message{
  color: rgb(172,50,50);
  font-size: 16px;
}
.label-comment{
  color: red;
}
.account-content{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}
.selection-box{
    /* display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    height: 55%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da; */
    /* border-bottom-left-radius: 4.2px;
    border-bottom-right-radius: 4.2px;
      border-top-left-radius: 4.2px;
    border-top-right-radius: 4.2px */

    display: block;
width: 100%;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.progress-bar-content{
  margin-bottom: 10px;
}
.label-class{
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}
.verification-button{
  margin-left: 30%;
}
.color{
  background-color: rgb(172,50,50);
}

.input-placeholder::placeholder{
  font-size: 12px;
  font-style: italic;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
}
.applicant-necta-detail{
  background:rgba(34, 146, 167, 0.59);
  color: #fff;
}
.applicant-necta-detail>.student-detail>.form-control-label{
  color: #2c304d;
  font-weight: 500;
}
.sname{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}

.errorMsg{
  color: rgb(172,50,50);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}
.justify-content-end{
  padding-left:50px;
  padding-top: 2px;
  padding-bottom: 5px;
  font-weight: bold;
}
.justify-content-end a{ 
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-style: italic;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}
.justify-content-end>a:hover{ 
  text-decoration: none;
  color: #fff;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}

