fieldset.scheduler-border {
  border: 1px groove #0297ab !important;
  padding: 0 1.9em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #0297ab;
  box-shadow: 0px 0px 0px 0px #0297ab;
  margin-top: 30px !important;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: initial !important;
  text-align: left !important;
  font-style: italic;
  width: auto;
  padding: 5 10px;
  border-bottom: none;
  margin-top: -15px;
  background-color: white;
  color: black;
}
.accordinHeader {
  width: 100%;
  font-size: 14px;
  border-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #DCDCDC;
}
.accordion-button{
 border-style: none;
 background-color:#DCDCDC;
  padding-top: 5px;
  color:#0297ab;
  padding-bottom: 5px;
}
.accordion-button:hover{
  text-decoration: underline;
}
.accordionBody{
 padding-top: 2px; 
 padding-bottom: 5px;
}


